import PropTypes from 'prop-types';

import * as Yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import documentTypeClient from '../../clients/documentTypeClient';
import GridItemFormControl from '../GridItem';
import NumberField from '../Fields/NumberField';
import ButtonAdd from '../Button';
import EmployeeRegisterClient from '../../clients/employeeRegisterClient';
import FileInput from '../Fields/FileInput';
import { useEmployContext } from '../Employees/EmployContext';
import {
  documentTypeCategories,
  documentTypeCategoriesToAPIType,
} from '../../helpers/constants';
import InputSearch from './InputSearch';
import Modal from '../Modal';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    maxHeight: '830px !important',
    padding: '20px',
  },
  labelModal: {
    color: '#00A4E1',
    fontSize: '24px',
    fontFamily: 'Montserrat',
  },
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  fileField: {
    borderColor: '#000 !important',
    color: '#000000de !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    height: '72px',
    borderRadius: '10px',
    textTransform: 'capitalize !important',
    fontSize: '16px !important',
    fontWeight: '400',
  },
  grid: {
    '& .MuiDataGrid-toolbarContainer': {
      '& button:first-child': {
        display: 'none !important',
      },
      '& .MuiFormControl-root': {
        width: '100% !important',
        margin: '5px 10px !important',
      },
    },
  },
}));

function DocumentTypeSelectOptions(options, documentCategoryType) {
  const mappedOptions = options?.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));
  return mappedOptions.length
    ? mappedOptions
    : [
        <MenuItem key="no-doc-type">
          Nenhum tipo de documento cadastrado{' '}
          {documentCategoryType && 'para essa categoria'} ainda.
        </MenuItem>,
      ];
}

export default function DigitalDocumentForm({
  initialValues = {},
  onSubmit,
  documentCategoryType,
  isLoading,
  searchVisible,
  getEmployeeDependentId,
}) {
  const classes = useStyles();

  const documentType = documentTypeClient();
  const employeeClient = EmployeeRegisterClient();
  const { employId } = useEmployContext();

  const context = useContext(AppContext);
  const { selectedRows, setSelectedRows } = context;

  const [options, setDocumentTypeOptions] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [mappedDocumentTypes, setMappedOptions] = useState([]);
  const [status, setStatus] = useState(false);

  const REQUIRED_FIELD_MESSAGE = 'Esse campo é obrigtório.';
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      description: initialValues?.description ?? '',
    },
    validationSchema: Yup.object({
      documentName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
      documentTypeCategory: Yup.string().required(REQUIRED_FIELD_MESSAGE),
      documentTypeId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
      documentDate: Yup.date().required(REQUIRED_FIELD_MESSAGE),
      documentYear: Yup.number().required(REQUIRED_FIELD_MESSAGE),
      file: Yup.object()
        .shape({
          FileName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
          fileBase64Payload: Yup.string().required(REQUIRED_FIELD_MESSAGE),
        })
        .required(REQUIRED_FIELD_MESSAGE),
      description: Yup.string(),
      documentNumber: Yup.string()
        .matches(/^\S*$/, 'Número sem espaço')
        .required(REQUIRED_FIELD_MESSAGE),
      employeesList: Yup.array(),
    }),
    onSubmit: async ({ file, documentTypeCategory, ...data }) => {
      const storageTenantId = localStorage.getItem('tenantId');
      const tenantId = storageTenantId.startsWith(null)
        ? null
        : storageTenantId.substring(1, storageTenantId.length - 1);

      let payload;

      if (getEmployeeDependentId !== '') {
        payload = {
          tenantId,
          ...data,
          ...file,
          employeeDependentsList: getEmployeeDependentId
            ? [...selectedRows, getEmployeeDependentId]
            : [...selectedRows],
        };
      } else {
        payload = {
          tenantId,
          ...data,
          ...file,
          employeesList: employId
            ? [...selectedRows, employId]
            : [...selectedRows],
        };
      }

      formik.resetForm();
      onSubmit(payload);
    },
  });

  function getOptionsByCategoryType(optionsList, category) {
    optionsList.sort((a, b) => (a.name > b.name ? 1 : -1)); // sorts it by name.
    return options
      .filter((opt) => opt.categoryDocumentType === category)
      .map(({ name, id }) => ({ label: name, value: id }));
  }

  useEffect(() => {
    if (employId) {
      formik.setFieldValue('employeesList', [employId]);
      formik.setFieldValue(`file.FileName`, formik?.values?.documentName);
    }

    if (!options.length) {
      documentType()
        .getDocumentType()
        .then((response) => {
          setDocumentTypeOptions(response);

          if (documentCategoryType) {
            formik.setFieldValue(
              'documentTypeCategory',
              documentTypeCategoriesToAPIType[documentCategoryType]
            );
          }
        });
    }

    setSelectedRows(initialValues?.employeesList ?? []);
  }, [
    employId,
    employees,
    formik?.values?.documentName,
    formik?.values?.file?.FileName,
  ]);

  useEffect(() => {
    if (!employees.length && !employId) {
      employeeClient()
        .getEmployee()
        .then((response) => {
          setEmployees(response);
        });
    }
  }, []);

  useEffect(() => {
    setMappedOptions(
      getOptionsByCategoryType(options, formik.values.documentTypeCategory)
    );
  }, [formik.values]);

  const getFieldProps = (fieldName) => ({
    ...formik.getFieldProps(fieldName),
    error: !!(formik.touched?.[fieldName] && formik.errors?.[fieldName]),
  });

  const handleSubmit = async () => {
    await formik.submitForm();
  };

  const handleFileChange = (fileObject) => {
    formik.setFieldValue('file', fileObject, true);
  };

  const handleSearch = () => {
    setStatus(!status);
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return (
    <Box marginTop="15px">
      <Grid container item direction="row" spacing={2}>
        {!documentCategoryType && !employId ? (
          <GridItemFormControl sm={6} md={6}>
            <InputLabel id="categoryType" required>
              Categoria do documento
            </InputLabel>
            <Select
              required
              labelId="categoryType"
              label="Categoria do documento"
              name="documentTypeCategory"
              {...getFieldProps('documentTypeCategory')}
              value={formik.values.documentTypeCategory}
            >
              {alphabetOrder(Object.entries(documentTypeCategories), 0)?.map(
                ([value, label]) => (
                  <MenuItem
                    key={value}
                    value={documentTypeCategoriesToAPIType[value] ?? value}
                  >
                    {label}
                  </MenuItem>
                )
              ) ?? []}
            </Select>

            <FormHelperText
              hidden={!getFieldProps('documentTypeCategory').error}
              error={getFieldProps('documentTypeCategory').error}
            >
              {formik.errors.documentTypeCategory}
            </FormHelperText>
          </GridItemFormControl>
        ) : null}

        <GridItemFormControl sm={6} md={6}>
          <InputLabel id="document-type" required>
            Tipo de documentos
          </InputLabel>
          <Select
            required
            id="document-type"
            label="Tipo do documento"
            name="documentTypeId"
            disabled={
              documentCategoryType
                ? false
                : !formik.values.documentTypeCategory?.length
            }
            {...getFieldProps('documentTypeId')}
          >
            {DocumentTypeSelectOptions(
              mappedDocumentTypes,
              documentCategoryType ?? formik.values.documentTypeCategory
            )}
          </Select>

          <FormHelperText
            hidden={!getFieldProps('documentTypeId').error}
            error={getFieldProps('documentTypeId').error}
          >
            {formik.errors.documentTypeId}
          </FormHelperText>
        </GridItemFormControl>

        <GridItemFormControl sm={6} md={6}>
          <TextField
            label="Número do Documento"
            maxLength={100}
            placeholder="Ex: Nº 0000000"
            name="documentNumber"
            {...getFieldProps('documentNumber')}
          />
          <FormHelperText
            hidden={!getFieldProps('documentNumber').error}
            error={getFieldProps('documentNumber').error}
          >
            {formik.errors.documentNumber}
          </FormHelperText>
        </GridItemFormControl>

        <GridItemFormControl sm={6} md={6}>
          <NumberField
            required
            label="Ano do Documento"
            maxLength={4}
            placeholder="Ex: 2022"
            name="number"
            {...getFieldProps('documentYear')}
          />
          <FormHelperText
            hidden={!getFieldProps('documentYear').error}
            error={getFieldProps('documentYear').error}
          >
            {formik.errors.documentYear}
          </FormHelperText>
        </GridItemFormControl>

        <GridItemFormControl sm={6} md={6}>
          <TextField
            type="date"
            placeholder="Data"
            name="documentDate"
            inputProps={{ max: '9999-01-01' }}
            {...getFieldProps('documentDate')}
          />
          <FormHelperText
            hidden={!getFieldProps('documentDate').error}
            error={getFieldProps('documentDate').error}
          >
            {formik.errors.documentDate}
          </FormHelperText>
        </GridItemFormControl>

        <GridItemFormControl sm={6} md={6}>
          <TextField
            required
            inputProps={{ maxLength: 100 }}
            placeholder="Assunto"
            label="Assunto"
            className={classes.muiField}
            {...getFieldProps('documentName')}
          />
          <FormHelperText
            hidden={!getFieldProps('documentName').error}
            error={getFieldProps('documentName').error}
          >
            {formik.errors.documentName}
          </FormHelperText>
        </GridItemFormControl>

        <GridItemFormControl sm={12} md={12}>
          <FileInput
            label="Anexo"
            accept="application/pdf"
            onChange={handleFileChange}
            initialValue={initialValues.file}
            required
          />
        </GridItemFormControl>

        <GridItemFormControl sm={12} md={12}>
          <TextField
            type="textarea"
            placeholder="Descrição do documento"
            inputProps={{ resize: 'both' }}
            label="Descrição do Documento"
            multiline
            aria-multiline
            {...getFieldProps('description')}
          />
          <FormHelperText
            hidden={!getFieldProps('description').error}
            error={getFieldProps('description').error}
          >
            {formik.errors.description}
          </FormHelperText>
        </GridItemFormControl>

        {!employId && searchVisible && (
          <GridItemFormControl md={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item md={12}>
                <Divider
                  sx={{
                    '&::before, &::after': {
                      borderTop: 'thin solid rgba( 0, 0, 0, 1 )',
                    },
                  }}
                >
                  <Typography>Opicional</Typography>
                </Divider>
              </Grid>
              <Grid item md={12}>
                <ButtonAdd
                  text="Clique aqui para veicular esses documentos (colaboradores, todos, etc.)"
                  type="send"
                  width="100%"
                  height="72px"
                  whiteButton
                  onClick={handleSearch}
                />
              </Grid>
            </Grid>
          </GridItemFormControl>
        )}
        {status && (
          <Modal
            title="Veicular Documento"
            setStatus={handleSearch}
            status={status}
          >
            <Box
              style={{
                padding: '60px 20px',
                width: '700px',
                marginTop: '50px',
              }}
            >
              <InputSearch
                employees={employees}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                close={setStatus}
              />
            </Box>
          </Modal>
        )}

        <Grid mt={2.5} item sm={12}>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            container
          >
            <ButtonAdd
              isLoginButton={false}
              isLoginText={false}
              text="Salvar"
              width="200px"
              height="50px"
              onClick={handleSubmit}
              disabled={
                !Object.values(formik.touched).every(Boolean) ||
                !formik.isValid ||
                !formik.values?.file?.fileBase64Payload ||
                isLoading
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

DigitalDocumentForm.propTypes = {
  initialValues: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  documentCategoryType: PropTypes.string,
  isLoading: PropTypes.bool,
  searchVisible: PropTypes.bool,
  getEmployeeDependentId: PropTypes.string,
};

DigitalDocumentForm.defaultProps = {
  initialValues: {
    employeesList: [],
  },
  isEdit: false,
  documentCategoryType: undefined,
  isLoading: false,
  searchVisible: true,
  getEmployeeDependentId: '',
};
