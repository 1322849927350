export const Enviroment = {
  LAW_TEXT_FIRST:
    'Li e estou de acordo com o Termo de Responsabilidade pelo Tratamento de Dados Pessoais, Sigilos e de Exclusividade, de acordo com a Lei nº. 13.709 e com os termos contratuais firmados com está instituição pública.',
  LAW_TEXT_SECOND:
    'Declaro, para fins de direito, sob as penas do art. 299 do Código Penal Brasileiro, que as informações e os documentos inseridos no sistema e-Pessoal, conforme apresentados, são verdadeiros e autênticos.',

  PHONE_WHATSAPP_HELP: 5567984673348,

  EMAIL_HELP: 'agiapro.epessoal@gmail.com',
};
