/* eslint-disable react/no-unknown-property */
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Enviroment } from '../../environments';
import manual from './manual-epessoal.pdf';

const useStyles = makeStyles(() => ({
  subTitle: {
    color: '#727272',
    fontWeight: '400 !Important',
    fontSize: '18px !important',
    lineHeight: '22px',
  },
  label: {
    color: '#727272',
    fontWeight: '400 !Important',
    fontSize: '18px !important',
    lineHeight: '22px',
    marginTop: '30px !important',
  },
  title: {
    fontSize: '32px !important',
    color: '#107faa',
    fontWeight: '700 !important',
    lineHeight: '40px',
  },
  cardContainer: {
    minHeight: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  cardList: {
    '@media (max-width: 1146px)': {
      flexDirection: 'column !important',
    },
  },
  card: {
    boxShadow: 'none !important',
    width: '100%',
    minWidth: '480x !important',
    justifyContent: 'center',
  },
  button: {
    marginBottom: 3,
    textTransform: 'none !important',
    fontSize: '18px !important',
    lineHeight: '22px !important',
    fontWeight: '600 !important',
    height: '72px',
    // backgroundColor: '#29E23C !important',
    backgroundColor: '#107faa !important',
    borderRadius: '10px',

    '&:hover': {
      // backgroundColor: '#29E23C',
      backgroundColor: '#107faa',
      opacity: '0.8',
    },
  },
  cardItemContainer: {
    '@media (max-width: 1146px)': {
      maxWidth: '100% !important',
    },
  },
}));

export default function Help() {
  const classes = useStyles();
  const cards = [
    {
      id: 1,
      buttonText: 'Envie um e-mail',
      buttonAction: `mailto:${Enviroment.EMAIL_HELP}`,
      bottomText: `Ou, copie nosso email:`,
      extraText: Enviroment.EMAIL_HELP,
    },
    {
      id: 2,
      buttonText: 'Converse pelo whatsapp',
      buttonAction: `https://api.whatsapp.com/send?phone=${Enviroment.PHONE_WHATSAPP_HELP}&text=Olá! `,
      bottomText: 'Fale com um atendente ao vivo',
    },
  ];

  return (
    <Box
      padding={2}
      style={{ width: '100%', textAlign: 'center', backgroundColor: '#fff' }}
    >
      <Typography variant="h5" marginBottom={2} className={classes.title}>
        Seja bem-vindo à <br />
        nossa Central de Ajuda!
      </Typography>
      <Typography marginBottom={2} className={classes.subTitle}>
        Atualmente temos as seguintes formas de atendê-lo
      </Typography>

      <Box className={classes.cardContainer}>
        <Grid
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          container
          spacing={4}
          padding={6}
          className={classes.cardList}
        >
          {cards.map((card) => (
            <Grid
              item
              container
              xs
              key={card.id}
              sm
              md={6}
              lg={6}
              xl={6}
              className={classes.cardItemContainer}
            >
              <Card className={classes.card}>
                <CardContent sx={{ padding: '30px 40px !important' }}>
                  <Button
                    variant="contained"
                    disableElevation
                    component={Link}
                    className={classes.button}
                    href={card.buttonAction}
                    download={card.download}
                    fullWidth
                  >
                    {card.buttonText}
                  </Button>
                  {card.bottomText ? (
                    <Typography className={classes.label}>
                      {card.bottomText}
                    </Typography>
                  ) : null}
                  {card.extraText ? <span>{card.extraText}</span> : null}
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid
            item
            container
            xs
            sm
            md={6}
            lg={6}
            xl={6}
            className={classes.cardItemContainer}
          >
            <Card className={classes.card}>
              <CardContent sx={{ padding: '30px 40px !important' }}>
                <Button variant="contained" className={classes.button}>
                  <a
                    href={manual}
                    style={{ textDecoration: 'none', color: '#FFFFFF' }}
                    download="Manual ePessoal.pdf"
                  >
                    {' '}
                    Baixe o manual do sistema{' '}
                  </a>
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
