import { useCallback } from 'react'
import { api } from '../services/api'

export default function userClient() {
  const getClients = useCallback(() => api.get('/Tenant').then((response) => response.data), []);

  const newClients = useCallback((data) => api.post('/Tenant', data).then((response) => response), []);

  const editByIdClients = useCallback(
    (id, data) => api.put(`/Tenant?tenantId=${id}`, data).then((response) => response),
    []
  );

  const deleteClients = useCallback(
    (id) =>
      api.delete(`/Tenant?tenantId=${id}`).then((response) => response),
    []
  );

  return useCallback(() => ({
    getClients,
    newClients,
    editByIdClients,
    deleteClients
  }), [
    getClients,
    newClients,
    editByIdClients,
    deleteClients
  ]
  )
}