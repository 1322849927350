import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Button from '../Button';
import * as Styled from './styled';

import { forgotPassword } from '../../services/api';
import { AppContext } from '../../contexts/sidebarContext';

export default function ChangePassword() {
  const navigate = useNavigate();

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const DEFAULT = {
    email: '',
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Campo obrigatório')
        .email('Email deve ser válido'),
    }),
    onSubmit: (data) => {
      forgotPassword(data.email).then(
        () => {
          msgAlert({
            status: 200,
            message:
              'E-mail enviado com sucesso! Por favor, acesse o link no email',
          });
          navigate('/login');
        },
        (response) => {
          msgAlert({
            response,
            noReload: true,
          });
        }
      );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <Styled.Container>
      <Styled.TextArea>
        <Styled.LoginBox>
          <Styled.InfoArea>
            <Styled.Title>Esqueci a Senha</Styled.Title>
            <Styled.CheckboxArea>
              <Styled.CheckboxLabel>
                Você receberá em seu e-mail um link para atualizar sua senha!
              </Styled.CheckboxLabel>
            </Styled.CheckboxArea>
            <Styled.CheckboxArea>
              <Styled.CheckboxLabelAlert>
                Forneça o e-mail utilizado no login.
              </Styled.CheckboxLabelAlert>
            </Styled.CheckboxArea>
            <Styled.InputAreaWrapper>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.email && formik.errors.email}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="email"
                    placeholder="Ex: maria@gmail.com"
                    name="email"
                    {...formik.getFieldProps('email')}
                    error={formik.touched.email && formik.errors.email}
                  />
                  <FormHelperText
                    hidden={!formik.touched.email || !formik.errors.email}
                    error={formik.touched.email && !!formik.errors.email}
                  >
                    {formik.errors.email}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Styled.InputAreaWrapper>

            <Styled.ButtonArea>
              <Button
                text="Enviar"
                type="send"
                isLoginText
                isLoginButton
                onClick={handleSubmit}
              />
            </Styled.ButtonArea>
          </Styled.InfoArea>
        </Styled.LoginBox>
      </Styled.TextArea>
    </Styled.Container>
  );
}
