import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import Sidebar from '../../components/Sidebar';

import Help from '../../components/Help';

const useStyles = makeStyles(() => ({
  pageArea: {
    '@media (max-width: 1280px)': {
      padding: '30px 15px 0 15px',
    },
    height: '100%',
    padding: '15px 110px 0 110px',
    width: '100%',
    background: '#fff',
    marginTop: '120px',
  },

  defaultArea: {
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    background: '#fff',
  },
  sidebar: {
    display: 'flex',
  },
}));

function HelpPage() {
  const classes = useStyles();

  return (
    <Grid className={classes.defaultArea}>
      <Grid className={classes.sidebar}>
        <Sidebar />
      </Grid>
      <Box width="100%">
        <Grid>
          <Grid className={classes.pageArea}>
            <Help />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default HelpPage;
