import React, { useState, useEffect, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Box from '@mui/material/Box';
import {
  Grid,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'styled-components';
import * as Styled from './styled';

import Modal from '../Modal';
import NewSectors from '../SectorManagement';
import EditModalSector from './EditModalSector';
import DeleteModalConfirmation from './DeleteModalConfirmation';
import Button from '../Button';

import sectorTypeClient from '../../clients/sectorTypeClient';
import { AppContext } from '../../contexts/sidebarContext';
import SearchInput from '../SearchInput';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  TableBody: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1366px)': {
      height: '60px',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

function ListSectors() {
  const theme = useTheme();
  const classes = useStyles();
  const sectorClient = sectorTypeClient();

  const [sectors, setSectors] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [getId, setGetId] = useState('');
  const [getSectorEdit, setGetSectorEdit] = useState({});
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [showInactiveItens, setShowInactiveItens] = useState(false);

  const context = useContext(AppContext);

  const { filteredList, filteringList, msgAlert } = context;

  useEffect(() => {
    setLoadingProgress(true);
    sectorClient()
      .getSector()
      .then((response) => {
        setSectors(response);
        filteringList(response);

        setLoadingProgress(false);
      });
  }, []);

  const handleDelete = (sectorData, userId) => {
    setGetId(userId);
    setGetSectorEdit(sectorData);
    setOpenModalConfirmation(true);
  };

  const enableOrDisableUser = (data) => {
    const updateUser = () => {
      const payload = [data].map((sectorData) => ({
        name: sectorData.name,
        description: sectorData.description,
        enabled: !sectorData.enabled,
        tenantId: sectorData.tenantId,
        superiorSectorId: sectorData?.superiorSectorId,
        sectorType: sectorData?.sectorType,
      }));

      return payload[0];
    };

    const msgResponse = () => {
      if (data?.enabled) {
        return 'Estrutura Desativada';
      }
      return 'Estrutura Ativada';
    };

    sectorClient()
      .editSectorById(data.id, updateUser())
      .then(
        (response) => {
          msgAlert({
            response,
            message: msgResponse(),
          });
        },
        (resp) => {
          msgAlert({
            response: resp,
          });
        }
      );
  };

  const handleEdit = (data, userId) => {
    setGetId(userId);
    setGetSectorEdit(data);
    setOpenModal(true);
  };

  const handleModalClick = () => {
    setModalStatus(!modalStatus);
  };

  const handleText = (text) => {
    if (text?.length > 0) {
      if (text?.length > 30) {
        const docTypeEnd = text.slice(0, 30);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const showInactiveItensValueFunction = (enabled) => {
    if (showInactiveItens) {
      return true;
    }
    return enabled;
  };

  const colorPowerFunction = (enabled) => {
    if (enabled) {
      return '#18FF59';
    }
    return 'red';
  };

  return (
    <>
      <Styled.Container>
        <Grid spacing={4} container>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={8}
          >
            <SearchInput
              list={sectors}
              searchFieldName={['name', 'description']}
            />
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={4}
          >
            <Button
              onClick={handleModalClick}
              text="+ Adicionar"
              width="300px"
              height="45px"
            />
          </Grid>
        </Grid>
      </Styled.Container>

      <Box display="flex" justifyContent="start" ml={5}>
        <FormControlLabel
          sx={{
            '& .MuiTypography-root': {
              display: 'flex',
              fontSize: '15px !important',
              fontStyle: 'normal !important',
              alignItems: 'center !important',
              fontFamily: 'Montserrat !important',
              fontWeight: 600,
              color: '#107faa !important',
              '@media (max-width: 1366px)': {
                fontSize: '12px !important',
              },
            },
          }}
          label="Mostrar inativos"
          control={
            <Checkbox
              checked={showInactiveItens}
              onChange={() => {
                setShowInactiveItens(!showInactiveItens);
              }}
              sx={{
                '&.Mui-checked': {
                  color: theme.background.blue,
                },
              }}
            />
          }
        />
      </Box>

      <Box
        mt={2}
        sx={{ padding: '20px' }}
        display="flex"
        justifyContent="flex-start"
      >
        <Styled.Title>Estrutura Organizacional</Styled.Title>
      </Box>
      <Styled.Container>
        <Grid
          display="flex"
          justifyContent="center"
          container
          mb={3}
          columns={40}
          className={classes.TableHead}
        >
          <Grid className={classes.HeadCell} item xl={14} xs={13}>
            Nome
          </Grid>
          <Grid className={classes.HeadCell} item xl={9} xs={7}>
            Superior Imediato
          </Grid>
          <Grid className={classes.HeadCellCenter} item xl={11} xs={10}>
            Estrutura Organizacional
          </Grid>
          <Grid className={classes.HeadCellCenter} item xl={4} xs={7}>
            Opções
          </Grid>
        </Grid>

        <Box
          sx={{
            width: '100%',
          }}
        >
          {alphabetOrder(filteredList, 'name').map((sectorData) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {showInactiveItensValueFunction(sectorData.enabled) && (
                <Paper className={classes.Paper} key={sectorData.id}>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    container
                    columns={40}
                    className={classes.TableBody}
                  >
                    <Grid className={classes.BodyCellLeft} item xl={14} xs={13}>
                      {handleText(sectorData?.name)}
                    </Grid>
                    <Grid className={classes.BodyCellLeft} item xl={9} xs={7}>
                      {sectorData?.superiorSectorName
                        ? sectorData?.superiorSectorName
                        : '-'}
                    </Grid>
                    <Grid
                      className={classes.BodyCellCenter}
                      item
                      xl={11}
                      xs={10}
                    >
                      {sectorData?.sectorType
                        ?.replace('ao', 'ão')
                        ?.replace('Org', 'Órg')}
                    </Grid>
                    <Grid className={classes.BodyCellCenter} item xl={4} xs={7}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          item
                        >
                          <EditIcon
                            onClick={() => {
                              handleEdit(sectorData, sectorData.id);
                            }}
                            sx={{ cursor: 'pointer', color: '#107FAA' }}
                          />
                        </Grid>
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          item
                        >
                          <DeleteForeverIcon
                            onClick={() => {
                              handleDelete(sectorData, sectorData.id);
                            }}
                            sx={{ cursor: 'pointer', color: 'red' }}
                          />
                        </Grid>
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          title="Ativar/desativar estrutura"
                          item
                        >
                          <PowerSettingsNewIcon
                            onClick={() => {
                              enableOrDisableUser(sectorData);
                            }}
                            sx={{
                              cursor: 'pointer',
                              color: colorPowerFunction(sectorData.enabled),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </>
          ))}

          {loadingProgress && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
        </Box>
      </Styled.Container>

      <Modal status={modalStatus} setStatus={setModalStatus}>
        <NewSectors />
      </Modal>

      {openModal && (
        <EditModalSector
          getId={getId}
          getSectorEdit={getSectorEdit}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}

      {openModalConfirmation && (
        <DeleteModalConfirmation
          getId={getId}
          getSectorEdit={getSectorEdit}
          open={openModalConfirmation}
          setOpen={setOpenModalConfirmation}
        />
      )}
    </>
  );
}

export default ListSectors;
