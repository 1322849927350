/* eslint-disable */
import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VictoryPie, VictoryLegend } from 'victory';
import Calendar from 'react-calendar';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import reportClient from '../../clients/reportClient';
import './Calendar.css';

const useStyles = makeStyles(() => ({
  pageArea: {
    '@media (max-width: 1366px)': {
      padding: '30px 15px 0 15px',
    },
    height: '100%',
    padding: '15px 110px 0 110px',
    width: '100%',
    background: '#fff',
    marginTop: '10px',
  },

  defaultArea: {
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    background: '#fff',
  },

  boxChart: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  boxCalendar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  boxChart2: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function HomePage() {
  const classes = useStyles();
  const report = reportClient();
  const [data, setData] = useState({});

  useEffect(() => {
    report()
      .getDataReport()
      .then((rep) => setData(rep));
  }, []);

  const CustomLabel = () => null;

  return (
    <Grid className={classes.defaultArea}>
      <Grid>
        <Sidebar />
      </Grid>
      <Box height="100%" width="100%">
        <Grid>
          <Header />
        </Grid>
        <Grid>
          {/* <Grid container columns={13} xs={12} className={classes.pageArea}>
             <Grid xs={6}>
              <Box className={classes.boxChart}>
                <Typography
                  variant="caption"
                  sx={{
                    marginBottom: '20px',
                    fontWeight: '600',
                    fontSize: '1.3rem',
                  }}
                  color="primary"
                >
                  Alocação por Tipo de Admissão
                </Typography>
                <VictoryPie
                  width={750}
                  padding={{ bottom: 100, right: 50, left: 50 }}
                  data={data?.employeesAdmissionType}
                  labelComponent={<CustomLabel />}
                  style={{
                    text: { color: '#fff' },
                  }}
                  // data accessor for x values
                  x="admissionType"
                  // data accessor for y values
                  y="count"
                  colorScale="qualitative"
                />
                <Box
                  sx={{
                    marginTop: '-322px',
                    marginLeft: '360px',
                    width: '50%',
                    '@media (max-width: 1366px)': {
                      marginTop: '-262px',
                      marginLeft: '300px',
                    },
                  }}
                  ml={45}
                >
                  <VictoryLegend
                    x={200}
                    y={0}
                    height={data?.employeesAdmissionType?.length * 45}
                    title="Legenda"
                    centerTitle
                    orientation="horizontal"
                    colorScale="qualitative"
                    itemsPerRow={1}
                    gutter={35}
                    style={{
                      border: { stroke: 'black' },
                      title: { fontSize: 15 },
                      labels: { fontSize: 15 },
                    }}
                    data={data?.employeesAdmissionType?.map((result) => ({
                      name: result.admissionType,
                    }))}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box className={classes.boxChart2}>
                <Typography
                  variant="caption"
                  sx={{
                    marginBottom: '20px',
                    fontWeight: '600',
                    fontSize: '1.3rem',
                  }}
                  color="primary"
                >
                  Alocação por Setor
                </Typography>
                <VictoryPie
                  data={data?.employeesBySector}
                  labelComponent={<CustomLabel />}
                  width={750}
                  padding={{ bottom: 100, right: 50, left: 50 }}
                  // data accessor for x values
                  x="sector"
                  // data accessor for y values
                  y="count"
                  colorScale="qualitative"
                />
                <Box
                  sx={{
                    marginTop: '-322px',
                    marginLeft: '540px',
                    width: '48%',
                    '@media (max-width: 1366px)': {
                      marginTop: '-262px',
                      marginLeft: '410px',
                    },
                  }}
                  ml={45}
                >
                  <VictoryLegend
                    x={80}
                    y={0}
                    height={data?.employeesBySector?.length * 45}
                    title="Legenda"
                    centerTitle
                    orientation="horizontal"
                    colorScale="qualitative"
                    itemsPerRow={1}
                    gutter={60}
                    style={{
                      border: { stroke: 'black' },
                      title: { fontSize: 15 },
                      labels: { fontSize: 15 },
                    }}
                    data={data?.employeesBySector?.map((result) => ({
                      name: result.sector,
                    }))}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              mt={40}
              ml={20}
              display="flex"
              justifyContent="left"
              mb={5}
              xs={13}
            > */}
          <Box className={classes.boxCalendar}>
            <Typography
              variant="caption"
              sx={{
                marginBottom: '20px',
                fontWeight: '600',
                fontSize: '1.3rem',
              }}
              color="primary"
            >
              Calendario
            </Typography>
            <Calendar />
          </Box>
          {/* </Grid>
          </Grid> */}
        </Grid>
      </Box>
    </Grid>
  );
}

export default HomePage;
