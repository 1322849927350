import { useCallback } from 'react'
import { api } from '../services/api'

export default function sectorTypeClient() {
    const getSector = useCallback(() => api.get('/Sector').then((response) => response.data), [])

    const newSector = useCallback((data) => api.post('/Sector', data).then((response) => response), [])

    const deleteSector = useCallback((id) => api.delete('/Sector', { params: { id } }).then((response) => response), [])

    const getSectorById = useCallback((id) => api.get(`/Sector/${id}`).then((response) => response.data), [])

    const editSectorById = useCallback((id, data) => api.put(`/Sector/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getSector,
        newSector,
        deleteSector,
        getSectorById,
        editSectorById,
    }), [
        getSector,
        newSector,
        deleteSector,
        getSectorById,
        editSectorById,
    ]
    )
}