import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(39.1deg, #13263d 6.52%, #00a4e1 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextArea = styled.span`
  color: #fff;
  font-size: 24px;
  padding: 20px;
`;

export const LoginBox = styled.div`
  background: #f6f6f6;
  border: 2px solid #00a4e1;
  box-shadow: 0px 4px 33px rgba(0, 164, 225, 0.1);
  border-radius: 10px;
  max-height: 706px;
  max-width: 534px;
  height: 100%;
  width: 100%;

  @media (max-width: 425px) {
    border-radius: 0;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 72px 62px;

  @media (max-width: 425px) {
    padding: 30px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #438eb8;
  padding-bottom: 30px;
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CheckboxArea = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 26px;
  padding-bottom: 30px;
`;

export const Checkbox = styled.input``;

export const CheckboxLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #438eb8;
`;
export const CheckboxLabelAlert = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #438eb8;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  gap: 16px;
`;

export const ForgotPasswordArea = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

export const ForgotPassword = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #00a4e1;
`;
