import { useCallback } from 'react';
import { api } from '../services/api';

export default function officeClient() {
  const getOffice = useCallback(
    () => api.get('/JobTitle').then((response) => response),
    []
  );

  const newOffice = useCallback(
    (data) => api.post('/JobTitle', data).then((response) => response),
    []
  );

  const deleteOffice = useCallback(
    (id) =>
      api.delete('/JobTitle', { params: { id } }).then((response) => response),
    []
  );

  const getByIdOffice = useCallback(
    (id) => api.get(`/JobTitle/${id}`).then((response) => response),
    []
  );

  const editByIdOffice = useCallback(
    (id, data) => api.put(`/JobTitle/${id}`, data).then((response) => response),
    []
  );

  return useCallback(
    () => ({
      getOffice,
      newOffice,
      deleteOffice,
      getByIdOffice,
      editByIdOffice,
    }),
    [getOffice, newOffice, deleteOffice, getByIdOffice, editByIdOffice]
  );
}
