import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types';

import moment from 'moment';
import { useEmployContext } from './EmployContext';

const useStyles = makeStyles(() => ({
  PaperModal: {
    background: '#FFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '16px',
  },
}));

function EditGeneralDocModal({
  open,
  setOpen,
  editEndpoint,
  documentEdit,
  generalDocumentTypes,
}) {
  const classes = useStyles();

  const { employId } = useEmployContext();

  const formatDate = useCallback(
    (date) => date && moment(date).format('YYYY-MM-DD'),
    []
  );

  const [uploadName, setUploadName] = useState(documentEdit?.fileName ?? '');
  const [uploadDate, setUploadDate] = useState(
    formatDate(documentEdit?.date) ?? ''
  );
  const [uploadDocument, setUploadDocument] = useState('');
  const [uploadLabel, setUploadLabel] = useState(
    documentEdit?.description ?? ''
  );
  const [uploadDocumentYear, setUploadDocumentYear] = useState(
    documentEdit?.documentYear
  );
  const [uploadDocumentNumber, setUploadDocumentNumber] = useState(
    documentEdit?.documentNumber
  );

  const handleClose = () => setOpen(false);

  const VerifyPayloadDocument = () => {
    const getTenantId = localStorage.getItem('tenantId');
    const useTenantId = getTenantId.startsWith(null)
      ? null
      : getTenantId.substring(1, getTenantId.length - 1);

    const payloadComplete = {
      generalDocumentType: generalDocumentTypes,
      fileName: uploadName,
      description: uploadLabel,
      date: uploadDate,
      employeeId: documentEdit?.employeeId,
      tenantId: useTenantId,
      fileBase64Payload: uploadDocument,
      documentNumber: uploadDocumentNumber,
      documentYear: parseInt(uploadDocumentYear, 10),
    };

    const payloadIncomplete = {
      generalDocumentType: generalDocumentTypes,
      fileName: uploadName,
      description: uploadLabel,
      date: uploadDate,
      employeeId: documentEdit?.employeeId,
      tenantId: useTenantId,
      documentNumber: uploadDocumentNumber,
      documentYear: parseInt(uploadDocumentYear, 10),
    };

    const payloadCompleteDependent = {
      fileName: uploadName,
      description: uploadLabel,
      date: uploadDate,
      tenantId: useTenantId,
      fileBase64Payload: uploadDocument,
      employeeDependentId: documentEdit?.employeeDependentId,
      employeeId: employId,
    };

    const payloadIncompleteDependent = {
      fileName: uploadName,
      description: uploadLabel,
      date: uploadDate,
      employeeDependentId: documentEdit?.employeeDependentId,
      employeeId: employId,
      tenantId: useTenantId,
    };

    if (uploadDocument !== '' && generalDocumentTypes !== 'Dependent') {
      return payloadComplete;
    }
    if (uploadDocument !== '' && generalDocumentTypes === 'Dependent') {
      return payloadCompleteDependent;
    }
    if (generalDocumentTypes === 'Dependent') {
      return payloadIncompleteDependent;
    }
    return payloadIncomplete;
  };

  const handleConfirmation = () => {
    const payload = VerifyPayloadDocument();
    editEndpoint(documentEdit?.id, payload);
  };

  const handleUpLoadDescription = (data) => {
    setUploadLabel(data);
  };

  const handleUpLoadName = (data) => {
    setUploadName(data);
  };

  const handleUpLoadDateDoc = (data) => {
    setUploadDate(data);
  };

  const handleUpLoadUploadDocumentYear = (data) => {
    setUploadDocumentYear(data);
  };
  const handleUpLoadUploadDocumentNumber = (data) => {
    setUploadDocumentNumber(data);
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const uploadDoc = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    const base64Payload = base64.substring(base64.indexOf('base64,') + 7);
    setUploadDocument(base64Payload);
  };

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      closeAfterTransition
    >
      <Box>
        <Paper className={classes.PaperModal}>
          <Grid
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            container
            item
          >
            <Grid item>
              <Typography mt={3} mb={1} variant="h6">
                Atualização do arquivo abaixo
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
          <Grid
            mb={2}
            display="flex"
            justifyContent="start"
            alignItems="center"
            spacing={1}
            container
          >
            <Grid item xs={4}>
              <InputLabel>Nº do Documento</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={uploadDocumentNumber}
                  height="40px"
                  width="350px"
                  type="text"
                  inputProps={{ maxLength: 100 }}
                  placeholder="Ex: Nº 0000000"
                  name="electorIdSeries"
                  onChange={(e) => {
                    handleUpLoadUploadDocumentNumber(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Nome do Documento</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={uploadName}
                  height="40px"
                  width="350px"
                  type="text"
                  name="DocName"
                  onChange={(e) => {
                    handleUpLoadName(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Observação</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={uploadLabel}
                  height="40px"
                  width="350px"
                  type="text"
                  name="DocDescription"
                  onChange={(e) => {
                    handleUpLoadDescription(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Ano de Expedição</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={uploadDocumentYear}
                  height="40px"
                  width="350px"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value, 10))
                      .toString()
                      .slice(0, 4);
                  }}
                  placeholder="Ex: 2022"
                  name="electorIdSeries"
                  sx={{
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                  }}
                  onChange={(e) => {
                    handleUpLoadUploadDocumentYear(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Arquivo</InputLabel>
              <FormControl fullWidth>
                <TextField
                  height="40px"
                  width="350px"
                  type="file"
                  onChange={(e) => {
                    uploadDoc(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Data do Documento</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={uploadDate}
                  height="40px"
                  width="350px"
                  type="date"
                  inputProps={{ max: '9999-01-01' }}
                  name="DocDate"
                  onChange={(e) => {
                    handleUpLoadDateDoc(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid my={3} item>
              <Button
                onClick={handleConfirmation}
                variant="contained"
                color="primary"
              >
                Atualizar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained" color="error">
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

EditGeneralDocModal.propTypes = {
  open: PropTypes.bool,
  generalDocumentTypes: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  editEndpoint: PropTypes.func.isRequired,
  documentEdit: PropTypes.oneOfType([PropTypes.object]),
};

EditGeneralDocModal.defaultProps = {
  open: false,
  generalDocumentTypes: '',
  documentEdit: {},
};

export default EditGeneralDocModal;
