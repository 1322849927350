import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.status ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 900;
`;

export const Title = styled.h2`
  position: absolute;
  left: 0;
  padding-top: 20px;
  padding-left: 30px;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  padding-top: 20px;
  padding-right: 30px;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const ModalBody = styled.div`
  background: #fff;
  border-radius: 10px;
  max-width: 100vw;
  max-height: 95vh;
  position: relative;
  @media (max-height: 800px) {
    overflow-y: scroll;
  },
`;
