import { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AppContext } from '../../contexts/sidebarContext';
import Button from '../Button';

const useStyles = makeStyles(() => ({
  grid: {
    '& .MuiDataGrid-toolbarContainer': {
      '& button:first-child': {
        display: 'none !important',
      },
      '& .MuiFormControl-root': {
        width: '100% !important',
        margin: '5px 10px !important',
      },
    },
  },
}));

const columns = [
  {
    field: 'name',
    headerName: 'Nome',
    width: 295,
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    width: 295,
  },
];

export default function InputSearch({ employees, close }) {
  const classes = useStyles();

  const [employeesRows, setRows] = useState([]);

  const context = useContext(AppContext);
  const { selectedRows, setSelectedRows } = context;

  useEffect(() => {
    setSelectedRows(selectedRows);
    if (!employeesRows.length) {
      setRows(
        employees?.map((user) => ({
          id: user.id,
          cpf: user.cpf,
          name: user.name,
        }))
      );
    }
  }, [employees]);

  return (
    <Box
      sx={{
        width: '100%',
        height:
          employees?.length > 5 ? '300px' : `${130 + employees.length * 52}px`,
      }}
    >
      <DataGrid
        className={classes.grid}
        columns={columns}
        rows={employeesRows}
        rowsPerPageOptions={[employees?.length]}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              debounceMs: 500,
            },
          },
        }}
        checkboxSelection
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        disableColumnMenu
        disableExtendRowFullWidth
        disableIgnoreModificationsIfProcessingProps
        disableVirtualization
        onSelectionModelChange={setSelectedRows}
        selectionModel={selectedRows}
        hideFooter
      />
      <Box mt={1} display="flex" justifyContent="center" alignItems="center">
        <Button
          isLoginButton={false}
          isLoginText={false}
          onClick={() => close(false)}
          text="Ok"
          width="150px"
          height="45px"
        />
      </Box>
    </Box>
  );
}

InputSearch.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  close: PropTypes.func.isRequired,
};
