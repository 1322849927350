import React from 'react';
import * as Styled from './styled';

export default function NotFound() {
  return (
    <Styled.Container>
      <Styled.TextArea>
        Para acessar esta rota, você precisa estar autenticado. <br />
        Por favor, faça login novamente!
      </Styled.TextArea>
    </Styled.Container>
  );
}
