import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormHelperText, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import officeClient from '../../clients/officeClient';
import Button from '../Button';
import * as Styled from './styled';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  textFieldMultipleLine: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  selectField: {
    height: '72px',
    borderRadius: '10px !important',
    '& fieldset': {
      border: '1px solid #000',
    },
  },
  placeholder: {
    color: 'rgb(118, 118, 118)',
  },
}));

export default function NewOffice({ updateValues }) {
  const classes = useStyles();
  const useOffice = officeClient();

  const context = useContext(AppContext);
  const { setModalStatus, msgAlert, setModalClose } = context;
  const [disabeButton, setDisabeButton] = useState(false);

  const DEFAULT_VALUES = {
    office: updateValues.name ?? '',
    officeDescription: updateValues.description ?? '',
    functions: updateValues.function ?? '',
  };

  const handleStatus = () => {
    setModalStatus(false);
    setModalClose(true);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: Yup.object({
      office: Yup.string().required('Por favor, preencha este campo.'),
      officeDescription: Yup.string(),
      functions: Yup.string().required('Por favor, preencha este campo.'),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        name: data.office,
        description: data.officeDescription,
        tenantId: useTenantId,
        function: data.functions,
      };

      if (updateValues.id) {
        setModalClose(false);
        useOffice()
          .editByIdOffice(updateValues.id, payload)
          .then(
            (response) => {
              msgAlert({
                response,
                message: 'Registro atualizado com sucesso!',
              });
              handleStatus();
            },
            (resp) => {
              msgAlert({
                response: resp,
              });
              handleStatus();
            }
          );
      } else {
        setDisabeButton(true);
        setModalClose(false);
        useOffice()
          .newOffice(payload)
          .then(
            (response) => {
              msgAlert({
                response,
                message: 'Novo Cargo criada com sucesso!',
              });
              handleStatus();
            },
            (resp) => {
              msgAlert({
                response: resp,
              });
              handleStatus();
            }
          );
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const buttonSelect = () =>
    !updateValues.id ? (
      <Button
        disabled={disabeButton}
        text="Cadastrar"
        type="send"
        onClick={handleSubmit}
        width="100%"
        height="72px"
      />
    ) : (
      <Button
        text="Salvar"
        type="send"
        onClick={handleSubmit}
        width="100%"
        height="72px"
      />
    );

  return (
    <Styled.Container>
      <Styled.BoxTitle>
        <Styled.TitleModal>Cargos</Styled.TitleModal>
      </Styled.BoxTitle>

      <Styled.InputAreaWrapper>
        <Styled.FirstRow>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.office && formik.errors.office}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: Eng. Mecânico"
                    name="office"
                    label="Cargo"
                    className={classes.textField}
                    {...formik.getFieldProps('office')}
                    error={formik.touched.office && formik.errors.office}
                  />
                  <FormHelperText
                    hidden={!formik.touched.office || !formik.errors.office}
                    error={formik.touched.office && !!formik.errors.office}
                  >
                    {formik.errors.office}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.officeDescription &&
                    formik.errors.officeDescription
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: Bacharel em Engenharia Mecânica"
                    name="officeDescription"
                    label="Descrição"
                    className={classes.textField}
                    {...formik.getFieldProps('officeDescription')}
                    error={
                      formik.touched.officeDescription &&
                      formik.errors.officeDescription
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.officeDescription ||
                      !formik.errors.officeDescription
                    }
                    error={
                      formik.touched.officeDescription &&
                      !!formik.errors.officeDescription
                    }
                  >
                    {formik.errors.officeDescription}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={4}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={formik.touched.functions && !!formik.errors.functions}
                >
                  <TextField
                    height="100%"
                    width="350px"
                    type="text"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 1024 }}
                    name="functions"
                    label="Atribuições"
                    placeholder="Ex: Bacharel em Engenharia Mecânica responsável pela manutenção de tratores"
                    className={classes.textFieldMultipleLine}
                    {...formik.getFieldProps('functions')}
                    error={formik.touched.functions && formik.errors.functions}
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.functions || !formik.errors.functions
                    }
                    error={
                      formik.touched.functions && !!formik.errors.functions
                    }
                  >
                    {formik.errors.functions}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Styled.FirstRow>
      </Styled.InputAreaWrapper>
      <Styled.ButtonArea>
        <Grid container mb={3} spacing={3}>
          <Grid
            container
            item
            spacing={2}
            flex={1}
            display="flex"
            justifyContent="center"
          >
            <Grid item xs={6} sm={4} md={4} lg={6}>
              {!updateValues.id ? (
                <Button
                  text="Limpar campos"
                  type="send"
                  onClick={resetForm}
                  width="100%"
                  height="72px"
                  whiteButton
                />
              ) : (
                <Button
                  text="Cancelar"
                  type="send"
                  onClick={handleStatus}
                  width="100%"
                  height="72px"
                  whiteButton
                />
              )}
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={6}>
              {buttonSelect()}
            </Grid>
          </Grid>
        </Grid>
      </Styled.ButtonArea>
    </Styled.Container>
  );
}
NewOffice.propTypes = {
  updateValues: PropTypes.oneOfType([PropTypes.object]),
};

NewOffice.defaultProps = {
  updateValues: {},
};
