/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React, { useContext } from 'react';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import SignupClients from './pages/SignupClients';
import SectorManagement from './pages/SectorPage';
import DocumentTypePage from './pages/DocumentTypePage';
import AdmissionTypePage from './pages/AdmissionTypePage';
import OfficeTypePage from './pages/OfficePage';
import NewUserPage from './pages/NewUserPage';
import EmployeesPage from './pages/EmployeesPage';

import { AuthProvider, AuthContext } from './contexts/auth';
import NotFound from './pages/NotFound';
import { AppContext } from './contexts/sidebarContext';
import ChangePasswordPage from './pages/ChangePasswordPage';
import RecoverPage from './pages/RecoverPage';
import HelpPage from './pages/HelpPage';
import DigitalDocumensPage from './pages/DigitalDocuments';

function AppRoutes() {
  function Private({ children }) {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return (
        <div>
          <span>Carregando...</span>
        </div>
      );
    }

    if (!authenticated) {
      return <Navigate to="/not-found" />;
    }

    return children;
  }

  function PrivateAdminSistemAndTenant({ children }) {
    const { roleAdm } = useContext(AuthContext);
    const { msgAlert } = useContext(AppContext);

    if (
      !(roleAdm === 'AdmnistradorTenant' || roleAdm === 'AdministradorSistema')
    ) {
      msgAlert({ status: 401, message: 'Usuário sem premissão!' });
      return <Navigate to="/home" />;
    }

    return children;
  }

  function PrivateAdminSistem({ children }) {
    const { roleAdm } = useContext(AuthContext);
    const { msgAlert } = useContext(AppContext);

    if (roleAdm !== 'AdministradorSistema') {
      msgAlert({ status: 401, message: 'Usuário sem premissão!' });
      return <Navigate to="/home" />;
    }

    return children;
  }

  function PrivateUserSistemAndTenant({ children }) {
    const { roleAdm } = useContext(AuthContext);
    const { msgAlert } = useContext(AppContext);

    if (
      !(
        roleAdm === 'UsuarioSistema' ||
        roleAdm === 'UsuarioTenant' ||
        roleAdm === 'AdmnistradorTenant'
      )
    ) {
      msgAlert({ status: 401, message: 'Usuário sem premissão!' });
      return <Navigate to="/home" />;
    }

    return children;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<LoginPage />}>
            <Route path=":tenantNameUrl" element={<LoginPage />} />
          </Route>
          <Route
            exact
            path="/home"
            element={
              <Private>
                <HomePage />
              </Private>
            }
          />
          <Route
            exact
            path="/help"
            element={
              <Private>
                <HelpPage />
              </Private>
            }
          />
          <Route
            path="/new-clients"
            element={
              <Private>
                <PrivateAdminSistem>
                  <SignupClients />
                </PrivateAdminSistem>
              </Private>
            }
          />
          <Route
            path="/sector-management"
            element={
              <Private>
                <PrivateUserSistemAndTenant>
                  <SectorManagement />
                </PrivateUserSistemAndTenant>
              </Private>
            }
          />
          <Route
            path="/document-type"
            element={
              <Private>
                <PrivateUserSistemAndTenant>
                  <DocumentTypePage />
                </PrivateUserSistemAndTenant>
              </Private>
            }
          />
          <Route
            exact
            path="/digital-documents"
            element={
              <Private>
                <DigitalDocumensPage />
              </Private>
            }
          />
          <Route
            path="/admission-type"
            element={
              <Private>
                <PrivateUserSistemAndTenant>
                  <AdmissionTypePage />
                </PrivateUserSistemAndTenant>
              </Private>
            }
          />
          <Route
            path="/office-type"
            element={
              <Private>
                <PrivateUserSistemAndTenant>
                  <OfficeTypePage />
                </PrivateUserSistemAndTenant>
              </Private>
            }
          />
          <Route
            path="/new-user"
            element={
              <Private>
                <PrivateAdminSistemAndTenant>
                  <NewUserPage />
                </PrivateAdminSistemAndTenant>
              </Private>
            }
          />
          <Route
            path="/employees-management"
            element={
              <Private>
                <PrivateUserSistemAndTenant>
                  <EmployeesPage />
                </PrivateUserSistemAndTenant>
              </Private>
            }
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/recover" element={<RecoverPage />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default AppRoutes;
