import styled from "styled-components";

export const BoxSearch = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;

export const BoxSignUp = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  border: 1px solid #ccc;
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 30px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;