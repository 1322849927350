import { useCallback } from 'react'
import { api } from '../services/api'

export default function PdfExportClient() {
    const getPdfExport = useCallback((id) => api.get(`/Employee/${id}/HistoryReport`, { responseType: 'blob' }).then((response) => response), [])

    return useCallback(() => ({
        getPdfExport,
    }), [
        getPdfExport,
    ]
    )
}