import { PropTypes } from 'prop-types';

import TextField from "@mui/material/TextField";

export default function NumberField({ maxLength, ...props }) {
  return (
    <TextField
      type="number"
      onInput={
        (e) => {
          e.target.value = Math.max(0, parseInt(e.target.value, 10))
            .toString()
            .slice(0, maxLength ?? 100);
        }
      }
      sx={{
        '& input[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      }}
      {...props}
    />
  );
}


NumberField.propTypes = {
  maxLength: PropTypes.number.isRequired,
  ...TextField.PropTypes
}