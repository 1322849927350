import React from 'react';
import { Box, Grid, Modal, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

import Button from '../Button';

const useStyles = makeStyles(() => ({
  modalBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(100% - 50px)`,
    width: `calc(100% - 10px)`,
  },
  PaperModal: {
    width: '66%',
    maxHeight: '100%',
    position: 'relative',
    padding: '10px',
    maxWidth: '580px',
  },
}));

function DeleteEmployeeModal({ getId, open, setOpen, deleteEndpoint }) {
  const classes = useStyles();

  const handleClose = () => setOpen(false);

  const handleConfirmation = () => {
    deleteEndpoint(getId);
    handleClose();
  };

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      closeAfterTransition
    >
      <Box className={classes.modalBox}>
        <Paper className={classes.PaperModal}>
          <Box>
            <Box mt={2} mb={7}>
              <Typography align="center" variant="h4">
                Deletar?
              </Typography>
            </Box>

            <Box mb={7}>
              <Typography align="center">
                Tem certeza que deseja deletar o registro?
              </Typography>
            </Box>
            <Grid
              px={5}
              container
              spacing={2}
              align="center"
              justifyContent="center"
              mb={4}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpen(false)}
                  text="Cancelar"
                  type="send"
                  width="100%"
                  height="72px"
                  isLoginButton={false}
                  isLoginText={false}
                  whiteButton
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={handleConfirmation}
                  text="Confirmar"
                  type="send"
                  width="100%"
                  height="72px"
                  isLoginButton={false}
                  isLoginText={false}
                  deleteButton
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}

DeleteEmployeeModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  deleteEndpoint: PropTypes.func.isRequired,
  getId: PropTypes.string,
};

DeleteEmployeeModal.defaultProps = {
  open: false,
  getId: '',
};

export default DeleteEmployeeModal;
