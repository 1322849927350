/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api, createSession } from '../services/api';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [roleAdm, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorLogin, setErrorLogin] = useState(false);

  useEffect(() => {
    const recoveredUser = localStorage.getItem('user');
    const admRole = localStorage.getItem('role');

    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
      setRole(admRole);
    }

    setLoading(false);
  }, []);

  const login = async (email, password, tenant, enabled) => {
    createSession(email, password, tenant).then(
      () => {
        setErrorLogin(false);
      },
      () => {
        setErrorLogin(true);
      }
    );
    const response = await createSession(email, password, tenant);

    const {
      data: { userEmail, token, userName, tenantName, tenantId, role },
    } = response;

    localStorage.setItem('user', JSON.stringify(userEmail));
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('userName', JSON.stringify(userName));
    localStorage.setItem('tenantName', JSON.stringify(tenantName));
    localStorage.setItem('tenantId', JSON.stringify(tenantId));
    localStorage.setItem('enabled', JSON.stringify(enabled));
    localStorage.setItem('role', JSON.stringify(role).replaceAll('"', ''));

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setRole(role);

    setUser(userEmail);
    navigate('/home');
  };

  const logout = () => {
    const getTenantName = localStorage.getItem('tenantName');
    const getEnabled = localStorage.getItem('enabled');
    const useTenantName = getTenantName.startsWith(null)
      ? null
      : getTenantName.substring(1, getTenantName.length - 1);

    if (getEnabled === 'false') {
      localStorage.removeItem('user');
      localStorage.removeItem('enabled');
    }
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = null;
    setUser(null);
    navigate(`/${useTenantName ?? ''}`);
  };

  return (
    <AuthContext.Provider
      value={{
        roleAdm,
        authenticated: !!user,
        user,
        loading,
        login,
        logout,
        errorLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
