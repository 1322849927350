import { useCallback } from 'react'
import { api } from '../services/api'

export default function EmployeeAdmissionDocumentClient() {
    const getDocumentByEmployeeId = useCallback((id) => api.get(`/EmployeeAdmissionDocument/Employee/${id}`).then((response) => response.data), [])

    const getEmployeeAdmissionDocument = useCallback(() => api.get('/EmployeeAdmissionDocument').then((response) => response.data), [])

    const newEmployeeAdmissionDocument = useCallback((data) => api.post('/EmployeeAdmissionDocument', data).then((response) => response), [])

    const deleteDocumentType = useCallback((id) => api.delete('/EmployeeAdmissionDocument', { params: { id } }).then((response) => response), [])

    const getByIdEmployeeAdmissionDocument = useCallback((id) => api.get(`/EmployeeAdmissionDocument/${id}`).then((response) => response.data), [])

    const editByIdEmployeeAdmissionDocument = useCallback((id, data) => api.put(`/EmployeeAdmissionDocument/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getDocumentByEmployeeId,
        getEmployeeAdmissionDocument,
        newEmployeeAdmissionDocument,
        deleteDocumentType,
        getByIdEmployeeAdmissionDocument,
        editByIdEmployeeAdmissionDocument
    }), [
        getDocumentByEmployeeId,
        getEmployeeAdmissionDocument,
        newEmployeeAdmissionDocument,
        deleteDocumentType,
        getByIdEmployeeAdmissionDocument,
        editByIdEmployeeAdmissionDocument
    ]
    )
}