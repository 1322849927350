import React, { useContext, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import { Help } from '@mui/icons-material';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as Styled from './styled';
import Button from '../Button';
import documentTypeClient from '../../clients/documentTypeClient';
import { AppContext } from '../../contexts/sidebarContext';
import {
  documentTypeCategories,
  documentTypeCategoriesToAPIType,
} from '../../helpers/constants';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

export default function NewDocumentType() {
  const classes = useStyles();
  const userDocument = documentTypeClient();
  const [disabeButton, setDisabeButton] = useState(false);

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const DEFAULT = {
    name: '',
    description: '',
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obrigatório'),
      description: Yup.string(),
      categoryDocumentType: Yup.string().required('Campo obrigatório'),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        tenantId: useTenantId,
        ...data,
      };
      setDisabeButton(true);
      userDocument()
        .newDocumentType(payload)
        .then(
          (response) => {
            msgAlert({
              response,
              message: 'Documento cadastrado com sucesso!',
            });
          },
          (resp) => {
            msgAlert({
              response: resp,
            });
          }
        );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return (
    <Styled.Container>
      <Styled.BoxTitle>
        <Styled.TitleModal>Documentos</Styled.TitleModal>
      </Styled.BoxTitle>

      <Styled.InputAreaWrapper>
        <Styled.FirstRow>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.name && formik.errors.name}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: CNH"
                    name="name"
                    label="Nome do documento"
                    className={classes.textField}
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && formik.errors.name}
                  />
                  <FormHelperText
                    hidden={!formik.touched.name || !formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                  >
                    {formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.description && formik.errors.description
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: Carteira de motorista"
                    name="description"
                    label="Descrição do documento"
                    className={classes.textField}
                    {...formik.getFieldProps('description')}
                    icon={
                      <Tooltip title="Usado para login" placement="right">
                        <Help fontSize="15px" />
                      </Tooltip>
                    }
                    error={
                      formik.touched.description && formik.errors.description
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.description || !formik.errors.description
                    }
                    error={
                      formik.touched.description && !!formik.errors.description
                    }
                  >
                    {formik.errors.description}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.textField}
                  error={
                    formik.touched.categoryDocumentType &&
                    formik.errors.categoryDocumentType
                  }
                >
                  <InputLabel id="document-category-type" required>
                    Categoria do Documento
                  </InputLabel>
                  <Select
                    required
                    labelId="document-category-type"
                    label="Categoria do documento"
                    name="documentTypeId"
                    {...formik.getFieldProps('categoryDocumentType')}
                  >
                    {alphabetOrder(Object.keys(documentTypeCategories), 0)?.map(
                      (key) => (
                        <MenuItem
                          key={key}
                          value={documentTypeCategoriesToAPIType[key]}
                        >
                          {documentTypeCategories[key]}
                        </MenuItem>
                      )
                    ) ?? []}
                  </Select>

                  <FormHelperText
                    hidden={
                      !formik.touched.categoryDocumentType ||
                      !formik.errors.categoryDocumentType
                    }
                    error={
                      formik.touched.categoryDocumentType &&
                      !!formik.errors.categoryDocumentType
                    }
                  >
                    {formik.errors.categoryDocumentType}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Styled.FirstRow>
      </Styled.InputAreaWrapper>
      <Styled.ButtonArea>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={6}>
            <Button
              text="Limpar campos"
              type="send"
              onClick={resetForm}
              width="100%"
              height="72px"
              whiteButton
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disabeButton}
              text="Cadastrar"
              type="send"
              onClick={handleSubmit}
              width="100%"
              height="72px"
            />
          </Grid>
        </Grid>
      </Styled.ButtonArea>
    </Styled.Container>
  );
}
