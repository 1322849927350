import React, { useContext, useState } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

import ModalMensage from '../ModalMensage';
import Button from '../Button';
import admissionTypeClient from '../../clients/admissionTypeClient';
import { AppContext } from '../../contexts/sidebarContext';

function DeleteModalConfirmation({ getId, open, setOpen, deleteValue }) {
  const useAdmissionType = admissionTypeClient();

  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const handleConfirmation = () => {
    setIsLoading(true);
    useAdmissionType()
      .deleteAdmissionType(getId)
      .then(
        (response) => {
          msgAlert({
            response,
            message: 'Registro deletado com sucesso!',
          });
          setIsLoading(false);
          setOpen(false);
        },
        (response) => {
          msgAlert({
            response,
          });
          setIsLoading(false);
          setOpen(false);
        }
      );
  };

  return (
    <ModalMensage close={!isLoading} open={open} onClose={() => setOpen(false)}>
      <Box p={2} alignItems="center" justifyContent="center">
        <Box>
          <Box mt={6} mb={2}>
            <Typography
              mt={3}
              mb={2}
              align="center"
              sx={{
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '30px',
              }}
            >
              Deseja deletar esta admissão?
            </Typography>
          </Box>

          <Box mb={7}>
            <Typography
              align="center"
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '28px',
              }}
            >
              {`"${deleteValue.name}"`}
            </Typography>
          </Box>
          <Grid
            px={5}
            mb={4}
            container
            spacing={2}
            align="center"
            justifyContent="center"
          >
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton width={130} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                <Button
                  onClick={() => setOpen(false)}
                  text="Cancelar"
                  type="send"
                  width="100%"
                  height="72px"
                  whiteButton
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton width={130} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                <Button
                  onClick={handleConfirmation}
                  text="Confirmar"
                  type="send"
                  width="100%"
                  height="72px"
                  deleteButton
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalMensage>
  );
}

DeleteModalConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  getId: PropTypes.string,
  deleteValue: PropTypes.oneOfType([PropTypes.object]),
};

DeleteModalConfirmation.defaultProps = {
  open: false,
  getId: '',
  deleteValue: {},
};

export default DeleteModalConfirmation;
