import React, { useContext, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Modal from '../Modal';
import Button from '../Button';

import NewAdmissionType from '../AdmissionType';
import admissionTypeClient from '../../clients/admissionTypeClient';
import SearchInput from '../SearchInput';

import * as Styled from './styled';
import { AppContext } from '../../contexts/sidebarContext';
import DeleteModalConfirmation from './DeleteModalConfirmation';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Readex Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  TableBody: {
    fontFamily: 'Readex Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '80px',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1366px)': {
      minHeight: '60px',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

function ListAdmissionTypes() {
  const classes = useStyles();
  const useAdmissionType = admissionTypeClient();

  const [admissionTypeList, setAdmissionTypeList] = useState([]);
  const [upadateValue, setUpadateValue] = useState({});
  const [idAdmissionType, setIdAdmissionType] = useState('');
  const [open, setOpen] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);

  const context = useContext(AppContext);

  const {
    modalStatus,
    setModalStatus,
    msgAlert,
    modalClose,
    filteredList,
    filteringList,
  } = context;

  const handleModalClick = () => {
    setUpadateValue();
    setModalStatus(!modalStatus);
  };

  const handleEdit = (objEdit) => {
    setModalStatus(true);
    setUpadateValue(objEdit);
  };

  const handleDelete = (id, objDelete) => {
    setUpadateValue(objDelete);
    setIdAdmissionType(id);
    setOpen(true);
  };

  useEffect(() => {
    setLoadingProgress(true);
    useAdmissionType()
      .getAdmissionType()
      .then(
        (response) => {
          if (response) {
            setAdmissionTypeList(response.data);
            filteringList(response.data);
          } else {
            setAdmissionTypeList([]);
          }
          setLoadingProgress(false);
        },
        (response) => {
          msgAlert({
            response,
          });
          setLoadingProgress(false);
        }
      );
  }, []);

  const handleDocType = (docType) => {
    if (docType?.length > 0) {
      if (docType?.length > 2) {
        const docTypeEnd = docType
          ?.map((doc) => doc.name)
          .slice(0, 3)
          .join(', ');
        return `${docTypeEnd} ...`;
      }
      return docType
        ?.map((doc) => doc.name)
        .slice(0, 3)
        .join(', ');
    }
    return '-';
  };

  const handleText = (text, max = 30) => {
    if (text?.length > 0) {
      if (text?.length > max) {
        const docTypeEnd = text.slice(0, max);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return (
    <>
      <Styled.Container>
        <Grid spacing={4} container>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={8}
          >
            <SearchInput
              list={admissionTypeList}
              searchFieldName={['name', 'description']}
            />
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={4}
          >
            <Button
              onClick={handleModalClick}
              text="+ Adicionar"
              width="300px"
              height="45px"
            />
          </Grid>
        </Grid>
      </Styled.Container>

      <Box
        mt={2}
        sx={{ padding: '20px' }}
        display="flex"
        justifyContent="flex-start"
      >
        <Styled.Title>Listagem de Admissões</Styled.Title>
      </Box>
      <Styled.Container>
        <Grid
          display="flex"
          justifyContent="center"
          container
          mb={3}
          columns={40}
          className={classes.TableHead}
        >
          <Grid className={classes.HeadCell} xl={10} xs={9} item>
            Tipo de Admissão
          </Grid>
          <Grid className={classes.HeadCell} xl={10} xs={10} item>
            Documentos
          </Grid>
          <Grid className={classes.HeadCell} xl={15} xs={15} item>
            Descrição
          </Grid>
          <Grid className={classes.HeadCellCenter} xl={3} xs={3} item>
            Opções
          </Grid>
        </Grid>

        <Box
          sx={{
            width: '100%',
          }}
        >
          {alphabetOrder(filteredList, 'name')?.map((admissionData) => (
            <Paper className={classes.Paper} key={admissionData.id}>
              <Grid
                display="flex"
                justifyContent="center"
                container
                columns={40}
                className={classes.TableBody}
              >
                <Grid className={classes.BodyCellLeft} item xl={10} xs={9}>
                  {handleText(admissionData.name)}
                </Grid>
                <Grid className={classes.BodyCellLeft} item xl={11} xs={11}>
                  <Grid
                    sx={{
                      maxHeight: '70px',
                      overflowY: 'auto',
                    }}
                  >
                    {handleText(handleDocType(admissionData.documentTypes))}
                  </Grid>
                </Grid>
                <Grid className={classes.BodyCellLeft} item xl={15} xs={15}>
                  {handleText(admissionData.description, 40)}
                </Grid>
                <Grid className={classes.BodyCellCenter} item xl={3} xs={3}>
                  <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      item
                    >
                      <EditIcon
                        onClick={() => {
                          handleEdit(admissionData);
                        }}
                        sx={{ cursor: 'pointer', color: '#107FAA' }}
                      />
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      item
                    >
                      <DeleteForeverIcon
                        onClick={() =>
                          handleDelete(admissionData.id, admissionData)
                        }
                        sx={{ cursor: 'pointer', color: 'red' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
          {loadingProgress && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
        </Box>
      </Styled.Container>

      <Modal status={modalStatus} setStatus={setModalStatus} close={modalClose}>
        <Box sx={{ width: '1050px' }}>
          <NewAdmissionType
            updateValues={upadateValue}
            listAdm={filteredList}
          />
        </Box>
      </Modal>

      <DeleteModalConfirmation
        deleteValue={upadateValue}
        getId={idAdmissionType}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default ListAdmissionTypes;
