import { useCallback } from 'react'
import { api } from '../services/api'

export default function userClient() {
    const getUsers = useCallback(() => api.get('/User').then((response) => response.data), [])

    const newUser = useCallback((data) => api.post('/User', data).then((response) => response), [])

    const deleteUser = useCallback((id) => api.delete(`/User`, { params: { id } }).then((response) => response), [])

    const getByIdUser = useCallback((id) => api.get(`/User/${id}`).then((response) => response.data), [])

    const editUserById = useCallback((id, data) => api.put(`/User/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getUsers,
        newUser,
        deleteUser,
        getByIdUser,
        editUserById
    }), [
        getUsers,
        deleteUser,
        getByIdUser,
        editUserById
    ]
    )
}