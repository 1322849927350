/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import * as Styled from './styled';

function Input({
  height,
  width,
  type,
  placeholder,
  name,
  label,
  register,
  error,
  value,
  icon,
  ...rest
}) {
  return (
    <Styled.InputArea height={height} width={width}>
      <label htmlFor={name}>
        <Grid container display="flex" direction="row">
          <Grid item>{label}</Grid>
          <Grid item display="flex">
            {icon}
          </Grid>
        </Grid>
      </label>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        {...register(name)}
        {...rest}
      />
      <span style={{ fontSize: '13px' }}>{error}</span>
    </Styled.InputArea>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  error: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.string,
};

Input.defaultProps = {
  error: '',
  height: '56px',
  width: '100%',
  icon: '',
};

export default Input;
