import { useCallback } from 'react'
import { api } from '../services/api'

export default function S3DownloadClient() {
    const downloadDocument = useCallback((data) => api.post(`/S3/Download/`, { key: data }).then((response) => response.data), [])


    return useCallback(() => ({
        downloadDocument,
    }), [
        downloadDocument,
    ]
    )
}