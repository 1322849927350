import React, { useContext, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from '@mui/styles';
import { LinearProgress, Box, Paper, Grid } from '@mui/material';

import officeClient from '../../clients/officeClient';

// import ModeEditIcon from '@mui/icons-material/ModeEdit';

import * as Styled from './styled';
import Button from '../Button';
import { AppContext } from '../../contexts/sidebarContext';
import Modal from '../Modal';
import NewOffice from '../OfficeType';
import DeleteModalConfirmation from './DeleteModalConfirmation';
import SearchInput from '../SearchInput';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Readex Pro',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  TableBody: {
    fontFamily: 'Readex Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1366px)': {
      height: '60px',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

function ListOfficeTypes() {
  const classes = useStyles();
  const useOffice = officeClient();

  const [officeList, setOfficeList] = useState([]);
  const [upadateValue, setUpadateValue] = useState({});
  const [idOffice, setIdOffice] = useState('');
  const [open, setOpen] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);

  const context = useContext(AppContext);

  const {
    modalStatus,
    setModalStatus,
    msgAlert,
    modalClose,
    filteredList,
    filteringList,
  } = context;

  const handleModalClick = () => {
    setUpadateValue();
    setModalStatus(!modalStatus);
  };

  const handleEdit = (objEdit) => {
    setModalStatus(true);
    setUpadateValue(objEdit);
  };

  const handleDelete = (id, officeData) => {
    setIdOffice(id);
    setOpen(true);
    setUpadateValue(officeData);
  };

  useEffect(() => {
    setLoadingProgress(true);
    useOffice()
      .getOffice()
      .then(
        (response) => {
          if (response) {
            setOfficeList(response.data);
            filteringList(response.data);
          } else {
            setOfficeList([]);
          }
          setLoadingProgress(false);
        },
        (response) => {
          msgAlert({
            response,
          });
          setLoadingProgress(false);
        }
      );
  }, []);

  const handleText = (text) => {
    if (text?.length > 0) {
      if (text?.length > 20) {
        const docTypeEnd = text.slice(0, 20);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return (
    <>
      <Styled.Container>
        <Grid spacing={4} container>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={8}
          >
            <SearchInput
              list={officeList}
              searchFieldName={['name', 'description']}
            />
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={4}
          >
            <Button
              onClick={handleModalClick}
              text="+ Adicionar"
              width="300px"
              height="45px"
            />
          </Grid>
        </Grid>
      </Styled.Container>
      <Box
        mt={2}
        sx={{ padding: '20px' }}
        display="flex"
        justifyContent="flex-start"
      >
        <Styled.Title>Listagem de Cargos</Styled.Title>
      </Box>
      <Styled.Container>
        <Grid
          display="flex"
          justifyContent="center"
          container
          mb={3}
          columns={40}
          className={classes.TableHead}
        >
          <Grid className={classes.HeadCell} item xl={12} xs={12}>
            Nome Cargo
          </Grid>
          <Grid className={classes.HeadCell} item xl={10} xs={10}>
            Atribuições
          </Grid>
          <Grid className={classes.HeadCell} item xl={13} xs={13}>
            Descrição do Cargo
          </Grid>
          <Grid className={classes.HeadCellCenter} item xl={4} xs={4}>
            Opções
          </Grid>
        </Grid>

        <Box
          sx={{
            width: '100%',
          }}
        >
          {alphabetOrder(filteredList, 'name').map((officeData) => (
            <Paper className={classes.Paper} key={officeData.id}>
              <Grid
                display="flex"
                justifyContent="center"
                container
                columns={40}
                className={classes.TableBody}
              >
                <Grid className={classes.BodyCellLeft} item xl={12} xs={12}>
                  {handleText(officeData.name)}
                </Grid>
                <Grid className={classes.BodyCellLeft} item xl={10} xs={10}>
                  {handleText(officeData.function)}
                </Grid>
                <Grid className={classes.BodyCellLeft} item xl={13} xs={13}>
                  {handleText(officeData.description)}
                </Grid>
                <Grid className={classes.BodyCellCenter} item xl={4} xs={4}>
                  <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      item
                    >
                      <EditIcon
                        onClick={() => handleEdit(officeData)}
                        sx={{ cursor: 'pointer', color: '#107FAA' }}
                      />
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      item
                    >
                      <DeleteForeverIcon
                        onClick={() => handleDelete(officeData.id, officeData)}
                        sx={{ cursor: 'pointer', color: 'red' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
          {loadingProgress && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                className={classes.LinearProgress}
                variant="indeterminate"
              />
            </Box>
          )}
        </Box>
      </Styled.Container>

      <Modal status={modalStatus} setStatus={setModalStatus} close={modalClose}>
        <NewOffice updateValues={upadateValue} />
      </Modal>

      <DeleteModalConfirmation
        deleteValues={upadateValue}
        getId={idOffice}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default ListOfficeTypes;
