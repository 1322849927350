import React, { useState, useEffect, useCallback } from 'react';

import { Box, Grid, Modal, Paper, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import moment from 'moment';

import DependetEmployeeDataRegister from './DependetEmployeeDataRegister';
import { useEmployContext } from './EmployContext';

import * as Styled from './styled';

import EmployeeDependentClient from '../../clients/employeeDependentClient';

import DependentDocModal from './DependentDocModal';

import ButtonS from '../Button';
import DeleteEmployeeModal from './DeleteEmployeeModal';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
  },
  modalBox: {
    width: '100%',
    maxWidth: '85vw',
    maxHeight: '90%',
    top: '50%',
    left: '0',
    overflowY: 'auto',
    margin: 'auto',
  },
  PaperModal: {
    background: '#FFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '64px',
    '@media (max-width: 1366px)': {
      padding: '32px',
    },
  },
  labelModal: {
    fontSize: '32px',
    fontWeight: 700,
  },
  TableHead: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  TableBody: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '10px 0 10px 0',
    margin: '8px 0',
    '@media (max-width: 1366px)': {
      height: '100%',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

export default function DependentArea() {
  const {
    tabValue,
    employId,
    setTabValue,
    employeeMsgAlert,
    showRegisterDependent,
    getEmployeeDependentId,
    setShowRegisterDependent,
    setGetEmployeeDependentId,
  } = useEmployContext();

  const classes = useStyles();

  const useEmployeeDepClient = EmployeeDependentClient();

  const [getId, setGetId] = useState('');
  const [dependents, setDependents] = useState([]);
  const [onRefresh, setOnRefresh] = useState(false);
  const [dependentName, setDependentName] = useState('');
  const [showDocuments, setShowDocuments] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dependentCurrentValue, setDependentCurrentValue] = useState({});

  const deleteEndpointClient = (id) => {
    useEmployeeDepClient()
      .deleteDocumentType(id)
      .then(
        (response) => {
          employeeMsgAlert({
            response,
            message: 'Documento deletado com sucesso!',
          });
          setOnRefresh(true);
        },
        (response) => {
          employeeMsgAlert({
            response,
          });
        }
      );
  };

  const showRegisterEmployeeDependent = () => {
    setGetEmployeeDependentId('');
    setShowRegisterDependent(true);
    setDependentCurrentValue({});
  };

  useEffect(() => {
    if (employId) {
      useEmployeeDepClient()
        .getDependentByEmployeeId(employId)
        .then((response) => {
          setDependents(response);
        });
    }
    setOnRefresh(false);
  }, [setDependents, getEmployeeDependentId, onRefresh, employId]);

  const formatDate = useCallback(
    (date) => date && moment(date).format('DD/MM/YYYY'),
    []
  );

  const handleEdit = (data) => {
    setGetEmployeeDependentId(data.id);
    setDependentCurrentValue(data);
    setShowRegisterDependent(true);
  };

  const handleDelete = (depId) => {
    setOpenDeleteModal(true);
    setGetId(depId);
  };

  const handleShowDocuments = (id, name) => {
    setGetId(id);
    setDependentName(name);
    setShowDocuments(true);
  };

  const handleNextStage = () => {
    setTabValue((parseInt(tabValue, 10) + 1).toString());
  };

  return (
    <Grid m={1} item xs={12}>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        closeAfterTransition
        open={showRegisterDependent ?? false}
      >
        <Box className={classes.modalBox}>
          <Paper className={classes.PaperModal}>
            <DependetEmployeeDataRegister
              dependentCurrentValue={dependentCurrentValue}
              setOnRefresh={setOnRefresh}
            />
          </Paper>
        </Box>
      </Modal>
      <Box
        mt={2}
        sx={{ padding: '20px' }}
        display="flex"
        justifyContent="flex-start"
      >
        <Styled.Title>Dependentes</Styled.Title>
      </Box>
      <Grid sx={{ width: '100%' }} mb={2} item xs={12}>
        <Styled.Container>
          <Styled.Container>
            {dependents.length > 0 && (
              <Grid
                display="flex"
                justifyContent="center"
                container
                mb={3}
                columns={40}
                className={classes.TableHead}
              >
                <Grid className={classes.HeadCell} item xl={11} xs={11}>
                  Nome
                </Grid>
                <Grid className={classes.HeadCell} item xl={9} xs={8}>
                  Grau de parentesco
                </Grid>
                <Grid className={classes.HeadCell} item xl={8} xs={8}>
                  Data de nascimento
                </Grid>
                <Grid className={classes.HeadCellCenter} item xl={5} xs={5}>
                  Documentos
                </Grid>
                <Grid className={classes.HeadCellCenter} item xl={4} xs={6}>
                  Opções
                </Grid>

                <Box
                  sx={{
                    maxHeight: '600px',
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {dependents.map((dependent) => (
                    <Paper className={classes.Paper} key={dependent?.id}>
                      <Grid
                        display="flex"
                        justifyContent="center"
                        container
                        columns={40}
                        className={classes.TableBody}
                      >
                        <Grid
                          className={classes.BodyCellLeft}
                          item
                          xl={11}
                          xs={11}
                        >
                          {dependent?.name}
                        </Grid>
                        <Grid
                          className={classes.BodyCellLeft}
                          item
                          xl={9}
                          xs={8}
                        >
                          {dependent?.relationshipType}
                        </Grid>
                        <Grid
                          className={classes.BodyCellLeft}
                          item
                          xl={8}
                          xs={8}
                        >
                          {formatDate(dependent?.birthDate)}
                        </Grid>
                        <Grid
                          className={classes.BodyCellCenter}
                          item
                          xl={5}
                          xs={5}
                        >
                          <Box>
                            <PlagiarismIcon
                              onClick={() => {
                                handleShowDocuments(
                                  dependent.id,
                                  dependent?.name
                                );
                              }}
                              sx={{ cursor: 'pointer', fontSize: '30px' }}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          className={classes.BodyCellCenter}
                          item
                          xl={4}
                          xs={6}
                        >
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                            >
                              <EditIcon
                                onClick={() => {
                                  handleEdit(dependent);
                                }}
                                sx={{ cursor: 'pointer', color: '#107FAA' }}
                              />
                            </Grid>
                            <Grid
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                            >
                              <DeleteForeverIcon
                                onClick={() => {
                                  handleDelete(dependent.id);
                                }}
                                sx={{ cursor: 'pointer', color: 'red' }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Box>
              </Grid>
            )}
          </Styled.Container>
          {dependents.length === 0 && (
            <Grid
              container
              display="flex"
              justifyContent="center"
              textAlign="center"
            >
              <Typography>Nenhum Dependente Cadastrado</Typography>
            </Grid>
          )}
        </Styled.Container>
      </Grid>

      <Grid display="flex" justifyContent="flex-end" mt={4} mb={2} item xs={12}>
        <Grid container item spacing={2} display="flex" justifyContent="end">
          <Grid item xs={8} sm={4} md={3} lg={3}>
            <ButtonS
              isLoginButton={false}
              isLoginText={false}
              onClick={showRegisterEmployeeDependent}
              text="+ Adicionar"
              width="300px"
              height="50px"
            />
          </Grid>
          <Grid item xs={8} sm={4} md={3} lg={3}>
            <ButtonS
              isLoginButton={false}
              isLoginText={false}
              onClick={handleNextStage}
              text="Avançar"
              width="150px"
              height="50px"
              whiteButton
            />
          </Grid>
        </Grid>
      </Grid>

      {showDocuments && (
        <DependentDocModal
          getId={getId}
          openModalDoc={showDocuments}
          setOpenModalDoc={setShowDocuments}
          dependentName={dependentName}
        />
      )}

      {openDeleteModal && (
        <DeleteEmployeeModal
          open={openDeleteModal}
          getId={getId}
          setOpen={setOpenDeleteModal}
          deleteEndpoint={deleteEndpointClient}
        />
      )}
    </Grid>
  );
}
