import PropTypes from 'prop-types';

import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, LinearProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import digitalDocumentClient from '../../clients/digitalDocumentClient';
import DigitalDocumentForm from '../DigitalDocumentForm';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    maxHeight: '820px',
    maxWidth: 'calc(100vw - 20px) !important',
    padding: '20px',
  },
  labelModal: {
    color: '#00A4E1',
    fontSize: '24px',
    fontFamily: 'Montserrat',
  },
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

function EditDigitalDocument({
  documentId,
  setDocumentId,
  isOpen,
  setOpen,
  search,
  title,
}) {
  const classes = useStyles();
  const digitalDocuments = digitalDocumentClient();

  const [isLoading, setLoader] = useState(false);
  const [document, setDocument] = useState(undefined);

  const { msgAlert } = useContext(AppContext);

  useEffect(() => {
    if (!documentId) {
      setDocument(undefined);
      return;
    }

    setLoader(true);
    digitalDocuments()
      .getDigitalDocumentById(documentId)
      .then(
        ({
          documentType: {
            id: documentTypeId,
            categoryDocumentType: documentTypeCategory,
          },
          documentNumber,
          enabled,
          employees,
          employeeDependents,
          documentDate,
          storageFilePath,
          fileName,
          ...props
        }) => {
          setDocument({
            ...props,
            file: {
              path: storageFilePath,
              name: fileName,
            },
            documentTypeId,
            documentTypeCategory,
            documentNumber,
            documentDate: moment(documentDate).format('yyyy-MM-DD'),
            employeesList: employees.map(({ id }) => id),
            employeeDependentsList: employeeDependents.map(({ id }) => id),
          });
          setLoader(false);
        }
      );
  }, [documentId]);

  const handleClose = () => {
    setDocumentId(undefined);
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setLoader(true);

    digitalDocuments()
      .updateDigitalDocument(documentId, data)
      .then((resp) => {
        msgAlert({
          resp,
          status: 204,
          message: 'Documento atualizado com sucesso!',
          noReload: true,
        });
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        msgAlert({
          err,
          noReload: true,
        });
        setLoader(false);
        setOpen(false);
      });
  };

  return (
    <Modal
      style={{ overflowY: 'auto' }}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
    >
      <Box className={classes.modal}>
        <Paper sx={{ padding: '30px', width: '700px' }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{
                  width: '100%',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '30px',
                  paddingTop: '20px',
                  marginBottom: '40px',
                }}
              >
                {title ?? 'Editar Documento Digital'}
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>

          {/* loader::begin */}
          {isLoading && (
            <Box sx={{ width: '100%', margin: '0 0 30px' }}>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
          {/* loader::end */}

          {document && (
            <DigitalDocumentForm
              onSubmit={handleSubmit}
              initialValues={document}
              isLoading={isLoading}
              isEdit
              searchVisible={search}
            />
          )}
        </Paper>
      </Box>
    </Modal>
  );
}

EditDigitalDocument.propTypes = {
  documentId: PropTypes.string.isRequired,
  setDocumentId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  search: PropTypes.bool,
  title: PropTypes.string,
};

EditDigitalDocument.defaultProps = {
  search: true,
  title: null,
};

export default EditDigitalDocument;
