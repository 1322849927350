import PropTypes from 'prop-types';
import * as Styled from './styled';

function Button({
  width,
  height,
  type,
  text,
  isLoginButton,
  isLoginText,
  onClick,
  deleteButton,
  whiteButton,
  disabled
}) {
  return (
    <Styled.Button
      type={type}
      isLoginButton={isLoginButton}
      width={width}
      height={height}
      onClick={onClick}
      deleteButton={deleteButton}
      whiteButton={whiteButton}
      disabled={disabled}
    >
      <Styled.ButtonText whiteButton={whiteButton} isLoginText={isLoginText}>
        {text}
      </Styled.ButtonText>
    </Styled.Button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  isLoginButton: PropTypes.bool,
  isLoginText: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  deleteButton: PropTypes.bool,
  whiteButton: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: '',
  width: '410px',
  height: '56px',
  isLoginButton: false,
  isLoginText: false,
  deleteButton: false,
  whiteButton: false,
  disabled: false
};

export default Button;
