import styled from 'styled-components';

export const LoginBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 164, 225, 0.1);
  border-radius: 10px;
  max-height: 574px;
  max-width: 534px;
  min-height: 574px;
  height: 100%;
  width: 100%;

  @media (max-width: 425px) {
    border-radius: 0;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 72px 62px;

  @media (max-width: 425px) {
    padding: 30px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30.4px;
  line-height: 39px;
  color: ##000000;
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
`;

export const InputAreaWrapper = styled.div` 
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CheckboxArea = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 26px;
`;

export const Checkbox = styled.input``;

export const CheckboxLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #438eb8;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  gap: 16px;
`;

export const ForgotPasswordArea = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

export const ForgotPassword = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #00a4e1;
`;
