import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;

export const BoxSearch = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxTitle = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  color: #107faa;
  font-size: 16px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #fffafa;
  max-width: 900px;

  @media (max-width: 425px) {
    display: block;
    width: 100%;
  }
`;

export const TableHead = styled.thead`
  @media (max-width: 425px) {
    display: none;
  }
  position: sticky;
  top: 0;
`;

export const TableBody = styled.tbody`
  @media (max-width: 425px) {
    display: block;
    width: 100%;
  }
`;

export const TableRow = styled.tr`
  border: 1px solid #ccc;
  padding: 15px 15px;

  @media (max-width: 425px) {
    display: block;
    margin-bottom: 15px;
  }
`;

export const TableDataCells = styled.td`
  border: 1px solid #ccc;
  padding: 12px 15px;
  text-align: center;
  color: ${(props) => props.theme.text.darkGray};

  @media (max-width: 425px) {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;

    ::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
`;

export const HeadCells = styled.th`
  background: ${(props) => props.theme.background.blue};
  color: #fff;
  border: 1px solid #ccc;
  padding: 15px 15px;
`;

export const ContainerModal = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const BoxSignUp = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  border: 1px solid #ccc;
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  gap: 30px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 845px;
  gap: 10px;

  @media (max-width: 425px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const BoxFooter = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
