import PropTypes from 'prop-types';

import { Box, Grid, Paper } from '@mui/material';
import {
  FileDownload as FileOpenIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import * as Styled from '../ListDocumentsType/styled';
import DeleteItemModal from '../Employees/DeleteEmployeeModal';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '11px !important',
    },
  },

  TableBody: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    marginLeft: '-14px',
    '@media (max-width: 1366px)': {
      fontSize: '11px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    justifyContent: 'left',
    alignContent: 'center',
    paddingLeft: '10px !important',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1366px)': {
      height: '60px',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

const transformValueLabel = [
  { value: 'DocumentosPessoais', label: 'Documentos Pessoais' },
  { value: 'Exames', label: 'Exames' },
  { value: 'Certidões', label: 'Certidões' },
  { value: 'Declarações', label: 'Declarações' },
  { value: 'Requerimentos', label: 'Requerimentos' },
  { value: 'ConcursoPublico', label: 'Concurso Público' },
  {
    value: 'ProcessoSeletivoSimplificado',
    label: 'Processo Seletivo Simplificado',
  },
  { value: 'AtosAdministrativos', label: 'Atos Administrativos' },
  { value: 'Contratos', label: 'Contratos' },
  { value: 'Atestados', label: 'Atestados' },
  { value: 'EstagioProbatorio', label: 'Estágio Probatório' },
  { value: 'Processos', label: 'Processos' },
  { value: 'DocumentosGovernamentais', label: 'Documentos Governamentais' },
  { value: 'Legislacao', label: 'Legislação' },
  { value: 'AvaliacaoDeProdutividade', label: 'Avaliaçao De Produtividade' },
  { value: 'Capacitacoes', label: 'Capacitações' },
  { value: 'Diversos', label: 'Diversos' },
  { value: 'TceMs', label: 'TceMs' },
];

export function DataTable({
  rows,
  data,
  onOpenFile,
  onDelete,
  onEdit,
  tableType,
}) {
  const classes = useStyles();
  const [isDeleteModalOpen, setDeleteModalStatus] = useState(false);
  const [currentSelectedItem, setCurrentSelectedItem] = useState(undefined);

  const indiceCategoryDataType = () => {
    if (tableType === 'digitalDocumentsPage') {
      return 3;
    }
    return 5;
  };

  const responsiveDataType = () => {
    if (tableType === 'digitalDocumentsPage') {
      return 40;
    }
    return 47;
  };

  const formatDate = useCallback(
    (date) => date && moment(date).format('DD/MM/YYYY'),
    []
  );

  const dateOrder = (array, prop) =>
    array
      ?.sort((a, b) => {
        const dateA = new Date(a[prop]);
        const dateB = new Date(b[prop]);

        if (!Number.isNaN(dateA) && !Number.isNaN(dateB)) {
          return dateB - dateA;
        }
        if (Number.isNaN(dateA) && !Number.isNaN(dateB)) {
          return -1;
        }
        if (!Number.isNaN(dateA) && Number.isNaN(dateB)) {
          return 1;
        }
        return 0;
      })
      .map((result) => ({
        ...result,
        documentDate: formatDate(result?.documentDate),
      }));

  const handleOpenModal = (item) => {
    setCurrentSelectedItem(item.id);
    setDeleteModalStatus(true);
  };

  const handleDelete = (itemId) => {
    setDeleteModalStatus(false);
    onDelete(itemId);
    setCurrentSelectedItem(undefined);
  };

  const handleText = (text) => {
    if (String(text)?.length > 0) {
      if (text?.length > 18) {
        const docTypeEnd = text.slice(0, 18);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  const handleTextCategory = (text) => {
    if (String(text)?.length > 0) {
      if (text?.length > 20) {
        const docTypeEnd = text.slice(0, 20);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  const transformValueLabelFunction = (category) => {
    /* eslint-disable-next consistent-return */

    let categoryTransform;
    transformValueLabel.forEach((values) => {
      if (values.value === category) {
        categoryTransform = values.label;
      }
    });
    return categoryTransform;
  };

  return (
    <Styled.Container style={{ minWidth: '100% !important' }}>
      <Grid
        display="flex"
        justifyContent="center"
        marginRight="42px"
        container
        mb={3}
        columns={responsiveDataType()}
        className={classes.TableHead}
      >
        {rows.map((row) => {
          if (
            row === 'Tipo de Documento' &&
            tableType === 'digitalDocumentsEmployee'
          ) {
            return (
              <Grid key={row} item xs={12}>
                {row}
              </Grid>
            );
          }
          if (row === 'Tipo de Documento') {
            return (
              <Grid key={row} className={classes.HeadCellLeft} item xs={8}>
                <Box ml={tableType === 'digitalDocumentsEmployee' ? 1.4 : 0}>
                  {row}
                </Box>
              </Grid>
            );
          }
          if (row === 'N°') {
            return (
              <Grid
                key={row}
                display="flex"
                justifyContent="center"
                item
                xs={7}
              >
                <Box ml={tableType === 'digitalDocumentsEmployee' ? 2 : 0}>
                  {row}
                </Box>
              </Grid>
            );
          }
          if (row === 'Tipo de Categoria') {
            return (
              <Grid key={row} className={classes.HeadCellLeft} item xs={7}>
                <Box ml={2}>{row}</Box>
              </Grid>
            );
          }
          return (
            <Grid key={row} className={classes.HeadCellLeft} item xs={6}>
              <Box ml={tableType === 'digitalDocumentsEmployee' ? 1.5 : 0}>
                {row}
              </Box>
            </Grid>
          );
        })}
        <Grid className={classes.HeadCellCenter} item xs={6}>
          Opções
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '100%',
        }}
      >
        {dateOrder(data, 'documentDate').map((item) => (
          <Paper className={classes.Paper} key={item.id}>
            <Grid
              display="flex"
              justifyContent="center"
              container
              columns={responsiveDataType()}
              className={classes.TableBody}
            >
              {Object.values(item)
                .slice(0, rows.length)
                .map((v, index) => {
                  if (index === 0) {
                    return (
                      <Grid
                        key={v}
                        display="flex"
                        justifyContent="left"
                        item
                        title={v}
                        xs={tableType === 'digitalDocumentsPage' ? 8 : 12}
                      >
                        {v}
                      </Grid>
                    );
                  }
                  if (index === 1) {
                    return (
                      <Grid
                        key={v}
                        display="flex"
                        justifyContent={
                          tableType === 'digitalDocumentsPage'
                            ? 'center'
                            : 'left'
                        }
                        item
                        title={v}
                        xs={tableType === 'digitalDocumentsPage' ? 7 : 8}
                      >
                        {v}
                      </Grid>
                    );
                  }
                  if (index === 2) {
                    return (
                      <Grid
                        key={v}
                        display="flex"
                        justifyContent={
                          tableType === 'digitalDocumentsPage'
                            ? 'left'
                            : 'center'
                        }
                        item
                        title={v}
                        xs={7}
                      >
                        <Box>{v}</Box>
                      </Grid>
                    );
                  }
                  if (index === indiceCategoryDataType()) {
                    return (
                      <Grid
                        display="flex"
                        justifyContent="left"
                        key={v}
                        item
                        title={v}
                        xs={7}
                      >
                        {handleTextCategory(transformValueLabelFunction(v))}
                      </Grid>
                    );
                  }
                  return (
                    <Grid key={v} item title={v} xs={6}>
                      {handleText(v)}
                    </Grid>
                  );
                })}
              <Grid className={classes.BodyCellCenter} item xs={6}>
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {/* see-file-button::begin */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <FileOpenIcon
                      sx={{ cursor: 'pointer', color: '#107FAA' }}
                      onClick={() => onOpenFile(item)}
                      title="Baixar arquivo"
                    />
                  </Grid>
                  {/* see-file-button::end */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <EditIcon
                      sx={{ cursor: 'pointer', color: '#107FAA' }}
                      onClick={() => onEdit(item.id)}
                      title="Editar"
                    />
                  </Grid>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <DeleteForeverIcon
                      sx={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        handleOpenModal(item);
                      }}
                      title="Deletar"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}

        {data.length === 0 && (
          <p
            style={{ marginTop: '40px', textAlign: 'center', color: '#000000' }}
          >
            Pesquise ou adicione um documento.
          </p>
        )}
      </Box>

      {/* delete-modal::begin */}
      <DeleteItemModal
        setOpen={setDeleteModalStatus}
        open={isDeleteModalOpen}
        deleteEndpoint={handleDelete}
        getId={currentSelectedItem}
      />
      {/* delete-modal::end */}
    </Styled.Container>
  );
}

DataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    .isRequired,
  onOpenFile: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  tableType: PropTypes.string.isRequired,
};
