import React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ListDocumentsType from '../../components/ListDocumentsType';
import Sidebar from '../../components/Sidebar';
import NewDocumentType from '../../components/DocumentType';
import Modal from '../../components/Modal';
import Header from '../../components/Header';

const useStyles = makeStyles(() => ({
  pageArea: {
    '@media (max-width: 1366px)': {
      padding: '30px 15px 0 15px',
    },
    height: '100%',
    padding: '15px 110px 0 110px',
    width: '100%',
    background: '#fff',
    marginTop: '10px',
  },

  defaultArea: {
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    background: '#fff',
  },
  sidebar: {
    display: 'flex',
  },
}));

function DocumentTypePage() {
  const classes = useStyles();

  return (
    <Grid className={classes.defaultArea}>
      <Grid className={classes.sidebar}>
        <Sidebar />
      </Grid>
      <Box width="100%">
        <Grid>
          <Header />
        </Grid>
        <Grid>
          <Grid className={classes.pageArea}>
            <Modal>
              <NewDocumentType />
            </Modal>
            <ListDocumentsType />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default DocumentTypePage;
