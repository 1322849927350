import { ThemeProvider } from 'styled-components';
import theme from './helpers/theme';
import { SharedSidebarContext } from './contexts/sidebarContext';
import AppRoutes from './routes';

function App() {
  return (
    <SharedSidebarContext>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </SharedSidebarContext>
  );
}

export default App;
