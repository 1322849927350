import React, { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTheme } from 'styled-components';

import * as Styled from './styled';
import Input from '../Fields/Input';
import Button from '../Button';
import { AuthContext } from '../../contexts/auth';

function NewLogin() {
  const { login, errorLogin } = useContext(AuthContext);

  const [tenantName, setTenantName] = useState();
  const [user, setUser] = useState('');
  const [check, setCheck] = useState(false);
  const { tenantNameUrl } = useParams();

  const getEnabled = localStorage.getItem('enabled');

  useEffect(() => {
    setTenantName(tenantNameUrl);
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Por favor, preencha este campo.'),
    password: Yup.string().required('Por favor, preencha este campo.'),
    enabled: Yup.bool(),
  });

  useEffect(() => {
    if (getEnabled === 'true') {
      setCheck(true);
    }
  }, []);

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;

  const onSubmit = (data) => {
    if (check) {
      // eslint-disable-next-line no-param-reassign
      data.enabled = getEnabled === 'true' ? true : check;
    } else {
      // eslint-disable-next-line no-param-reassign
      data.enabled = check;
    }
    login(data.email, data.password, tenantName, data.enabled); // integrated with context and API
  };

  const theme = useTheme();

  return (
    <Styled.LoginBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.InfoArea>
          <Styled.Title>Acesse</Styled.Title>

          {errorLogin && (
            <Box display="flex" justifyContent="center" my={2}>
              <Typography color="error" sx={{ fontSize: 12 }}>
                Usuário ou senha inválido
              </Typography>
            </Box>
          )}

          <Styled.InputAreaWrapper>
            <Input
              value={user}
              type="email"
              placeholder="E-mail"
              name="email"
              register={register}
              error={errors.email?.message}
              onChange={(ev) => {
                setUser(ev.target.value);
              }}
            />

            <Input
              type="password"
              placeholder="Senha"
              name="password"
              register={register}
              error={errors.password?.message}
            />
            <FormControlLabel
              label="Lembrar-me"
              control={
                <Checkbox
                  register={register}
                  defaultChecked={getEnabled === 'true'}
                  name="enabled"
                  onChange={(event) => {
                    setCheck(event.target.checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: theme.background.dark,
                    },
                  }}
                />
              }
            />
          </Styled.InputAreaWrapper>

          <Styled.ButtonArea>
            <Button text="Login" type="send" isLoginButton isLoginText />
          </Styled.ButtonArea>

          <Styled.ForgotPasswordArea>
            <Styled.ForgotPassword>
              <Link to="/change-password">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 16,
                    textDecorationLine: 'underline',
                    color: '#000000',
                  }}
                >
                  Esqueci a senha
                </Typography>
              </Link>
            </Styled.ForgotPassword>
          </Styled.ForgotPasswordArea>
        </Styled.InfoArea>
      </form>
    </Styled.LoginBox>
  );
}

export default NewLogin;
