import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';

import { useTheme } from 'styled-components';

import * as Styled from './styled';
import Button from '../Button';
import { AppContext } from '../../contexts/sidebarContext';
import signUpClients from '../../clients/signUpClients';

const inputLabel = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  marginBottom: '10px',
  color: '#727272',
  fontFamily: 'Montserrat',
};

export default function NewClients({ updateValues }) {
  const useClient = signUpClients();

  const theme = useTheme();

  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasLogo, setHasLogo] = useState(false);

  const context = useContext(AppContext);
  const { setModalStatus, msgAlert } = context;

  const formatDate = useCallback(
    (date) => date && moment(date).format('YYYY-MM-DD'),
    []
  );

  const DEFAULT_VALUES = {
    tenantName: updateValues.tenantName ? updateValues.tenantName : '',
    companyName: updateValues.companyName ? updateValues.companyName : '',
    logo: updateValues.logo ?? '',
    cnpj: updateValues.cnpj ? updateValues.cnpj : '',
    dueDate: updateValues.dueDate ? formatDate(updateValues.dueDate) : '',
    enabled: updateValues?.enabled,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: Yup.object({
      tenantName: Yup.string().required('Campo obrigatório.'),
      companyName: Yup.string().required('Campo obrigatório.'),
      cnpj: Yup.string().required('Campo obrigatório.'),
      dueDate: Yup.string().required('Campo obrigatório'),
      enabled: Yup.boolean(),
    }),
    onSubmit: (data) => {
      const payload = {
        tenantName: data.tenantName,
        companyName: data.companyName,
        cnpj: data.cnpj,
        dueDate: data.dueDate,
        logo: data.logo,
        enabled: true,
      };

      let payloadEdit;

      if (data?.logo === null) {
        payloadEdit = {
          tenantName: data.tenantName,
          companyName: data.companyName,
          cnpj: data.cnpj,
          dueDate: data.dueDate,
          logo: null,
          enabled: data.enabled,
        };
      } else {
        payloadEdit = {
          tenantName: data.tenantName,
          companyName: data.companyName,
          cnpj: data.cnpj,
          dueDate: data.dueDate,
          logo: data.logo === '' ? undefined : data.logo,
          enabled: data.enabled,
        };
      }

      if (updateValues.id) {
        setIsLoading(true);
        useClient()
          .editByIdClients(updateValues.id, payloadEdit)
          .then(
            (response) => {
              msgAlert({
                response,
                message: 'Registro atualizado com sucesso!',
              });
              setModalStatus(false);
              setIsLoading(false);
            },
            (resp) => {
              msgAlert({
                response: resp,
              });
              setModalStatus(false);
              setIsLoading(false);
            }
          );
      } else {
        setIsLoading(true);
        useClient()
          .newClients(payload)
          .then(
            (response) => {
              msgAlert({
                response,
                message: 'Novo cliente criado com sucesso!',
              });
              setModalStatus(false);
              setIsLoading(false);
            },
            (resp) => {
              msgAlert({
                response: resp,
              });
              setModalStatus(false);
              setIsLoading(false);
            }
          );
      }
    },
  });

  useEffect(() => {
    if (updateValues?.logo && !hasLogo) {
      setHasLogo(true);
    }
  }, [updateValues?.logo]);

  const handleSubmit = () => {
    formik.submitForm();
  };

  const buttonSelect = () =>
    !updateValues.id ? (
      <Button
        text="Cadastrar"
        type="send"
        onClick={handleSubmit}
        width="150px"
        height="45px"
      />
    ) : (
      <Button
        text="Atualizar"
        type="send"
        onClick={handleSubmit}
        width="150px"
        height="45px"
      />
    );

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const uploadDoc = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    const base64Payload = base64.substring(base64.indexOf('base64,') + 7);
    if (typeof base64Payload === 'string') {
      formik.setFieldValue('logo', base64Payload);
      setHasLogo(true);
    }
    e.target.value = null;
  };

  const handleButtonClick = () => {
    if (!hasLogo) {
      fileInputRef.current.click();
    }
    if (hasLogo && updateValues?.logo && updateValues?.logo !== '') {
      formik.setFieldValue('logo', null);
    } else {
      formik.setFieldValue('logo', '');
    }
  };

  return (
    <Styled.Container>
      <Styled.BoxTitle>
        <Styled.Title>Novos clientes</Styled.Title>
      </Styled.BoxTitle>
      <Styled.BoxSignUp>
        <Styled.InputAreaWrapper>
          <Styled.FirstRow>
            <Grid container direction="column" padding={2} spacing={2}>
              <Styled.CustomGrid container item direction="row" spacing={2}>
                <Grid item xs>
                  <InputLabel
                    error={
                      formik.touched.companyName && formik.errors.companyName
                    }
                    htmlFor="companyName"
                    sx={inputLabel}
                  >
                    Razão Social
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  >
                    <TextField
                      height="40px"
                      width="350px"
                      type="text"
                      placeholder="Ex: Empresa XYZ"
                      name="companyName"
                      {...formik.getFieldProps('companyName')}
                      error={
                        formik.touched.companyName && formik.errors.companyName
                      }
                    />
                    <FormHelperText
                      hidden={
                        !formik.touched.companyName ||
                        !formik.errors.companyName
                      }
                      error={
                        formik.touched.companyName &&
                        !!formik.errors.companyName
                      }
                    >
                      {formik.errors.companyName}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <InputLabel
                    error={
                      formik.touched.tenantName && formik.errors.tenantName
                    }
                    htmlFor="tenantName"
                    sx={inputLabel}
                  >
                    Nome do Tenant
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.tenantName && formik.errors.tenantName
                    }
                  >
                    <TextField
                      height="40px"
                      width="350px"
                      type="text"
                      placeholder="Ex: tenant-teste"
                      name="tenantName"
                      {...formik.getFieldProps('tenantName')}
                      error={
                        formik.touched.tenantName && formik.errors.tenantName
                      }
                    />
                    <FormHelperText
                      hidden={
                        !formik.touched.tenantName || !formik.errors.tenantName
                      }
                      error={
                        formik.touched.tenantName && !!formik.errors.tenantName
                      }
                    >
                      {formik.errors.tenantName}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Styled.CustomGrid
                  container
                  item
                  direction="row"
                  spacing={2}
                  mt={2}
                >
                  <Grid item xs>
                    <InputLabel
                      error={formik.touched.cnpj && formik.errors.cnpj}
                      htmlFor="cnpj"
                      sx={inputLabel}
                    >
                      CNPJ
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={formik.touched.cnpj && formik.errors.cnpj}
                    >
                      <TextField
                        height="40px"
                        width="350px"
                        type="text"
                        placeholder="Ex: 000.000.000-00"
                        name="cnpj"
                        {...formik.getFieldProps('cnpj')}
                        error={formik.touched.cnpj && formik.errors.cnpj}
                      />
                      <FormHelperText
                        hidden={!formik.touched.cnpj || !formik.errors.cnpj}
                        error={formik.touched.cnpj && !!formik.errors.cnpj}
                      >
                        {formik.errors.cnpj}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs>
                    <InputLabel
                      error={formik.touched.dueDate && formik.errors.dueDate}
                      htmlFor="dueDate"
                      sx={inputLabel}
                    >
                      Data limite de ativação
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={formik.touched.dueDate && formik.errors.dueDate}
                    >
                      <TextField
                        height="40px"
                        width="350px"
                        type="date"
                        inputProps={{ max: '9999-01-01' }}
                        placeholder="Ex: 00/00/0000"
                        name="dueDate"
                        {...formik.getFieldProps('dueDate')}
                        error={formik.touched.dueDate && formik.errors.dueDate}
                      />
                      <FormHelperText
                        hidden={
                          !formik.touched.dueDate || !formik.errors.dueDate
                        }
                        error={
                          formik.touched.dueDate && !!formik.errors.dueDate
                        }
                      >
                        {formik.errors.dueDate}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid
                    display="flex"
                    alignContent="center"
                    justifyContent="center"
                    item
                    mt={2}
                    xs={12}
                  >
                    <Grid
                      display="flex"
                      justifyContent="center"
                      sx={{ maxWidth: '350px' }}
                      container
                    >
                      <Grid item xs={12}>
                        <InputLabel
                          error={formik.touched.logo && !!formik.errors.logo}
                          htmlFor="dueDate"
                          sx={inputLabel}
                        >
                          Insira a imagem do brasão
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Button
                            mt={1}
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              if (hasLogo) {
                                setHasLogo(false);
                              }
                              handleButtonClick();
                            }}
                            whiteButton={!hasLogo}
                            deleteButton={hasLogo}
                            text={
                              hasLogo ? 'Deletar Imagem' : ' Carregar a imagem'
                            }
                          />
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={uploadDoc}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        mt={1}
                        display="flex"
                        justifyContent="center"
                        item
                        xs={12}
                      >
                        <Typography>
                          {hasLogo ? 'Brasão selecionado' : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {updateValues?.enabled === false && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        sx={{
                          color: theme.text.darkGray,
                        }}
                        label="Ativar Usuário"
                        control={
                          <Checkbox
                            checked={formik.values.enabled}
                            onChange={(event) => {
                              formik.setFieldValue(
                                'enabled',
                                event.target.checked
                              );
                            }}
                            sx={{
                              '&.Mui-checked': {
                                color: theme.background.blue,
                              },
                            }}
                          />
                        }
                      />
                    </Grid>
                  )}
                </Styled.CustomGrid>
              </Styled.CustomGrid>
            </Grid>
          </Styled.FirstRow>
        </Styled.InputAreaWrapper>
      </Styled.BoxSignUp>
      <Styled.ButtonArea>
        <Grid container spacing={2} padding={2}>
          <Grid
            container
            item
            spacing={2}
            flex={1}
            display="flex"
            justifyContent="end"
          >
            <Grid item xs={6} sm={4} md={4} lg={3}>
              {isLoading && !updateValues.id ? (
                <Skeleton width={80} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                !updateValues.id && (
                  <Button
                    text="Cancelar"
                    type="send"
                    onClick={() => setModalStatus(false)}
                    width="150px"
                    height="45px"
                    deleteButton
                  />
                )
              )}
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3}>
              {isLoading ? (
                <Skeleton width={80} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                buttonSelect()
              )}
            </Grid>
          </Grid>
        </Grid>
      </Styled.ButtonArea>
    </Styled.Container>
  );
}

NewClients.propTypes = {
  updateValues: PropTypes.oneOfType([PropTypes.object]),
};

NewClients.defaultProps = {
  updateValues: {},
};
