import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background: #f8f8f8;
  border-bottom: 1px solid #f2f2f2;
`;

export const LeftArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 48px;
`;

export const Logo = styled.img`
  width: 150px;
  cursor: pointer;
`;

export const RightArea = styled.div`
  position: relative;
  width: 231px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  margin-right: 10px;
`;

export const UserArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const DropDownContainer = styled.div``;

export const DropDownHeader = styled.div`
  display: flex;
  cursor: pointer;
`;

export const IconDropdown = styled.img`
`;

export const DropDownListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropDownList = styled.ul`
  position: absolute;
  left: 0;
  top: 84px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 20.8px;
  font-weight: 500;
  background: #f8f8f8;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  &:first-child {
    padding-top: 12px;
  }
`;

export const ListItem = styled.li`
display: flex;
  list-style: none;
  padding-bottom: 15px;
  color: #438eb8; 
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const NameUser = styled.span`
  font-weight: 500;
  color: #438eb8;
  font-size: 16px;
`;

export const PictureUser = styled.img``;
