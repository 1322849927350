import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Modal,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material';

import PropTypes from 'prop-types';

import moment from 'moment';

import * as Styled from './styled';

import S3DownloadClient from '../../clients/s3DownloadClient';
import DeleteEmployeeModal from './DeleteEmployeeModal';
import EmployeeAdmissionDocumentClient from '../../clients/employeeAdmissionDoc';
import { useEmployContext } from './EmployContext';
import digitalDocumentClient from '../../clients/digitalDocumentClient';
import { documentTypeCategoriesToAPIType } from '../../helpers/constants';

const useStyles = makeStyles(() => ({
  modal: {
    maxHeight: '90%',
    top: '50%',
    left: '0',
    overflowY: 'auto',
    margin: 'auto',
  },
  labelModal: {
    color: '#00A4E1',
    fontSize: '24px',
    fontFamily: 'Montserrat',
  },
}));

export default function EmployeeDocModal({
  openModalDoc,
  setOpenModalDoc,
  getId,
  employeeName,
}) {
  const classes = useStyles();
  const digitalDocuments = digitalDocumentClient();

  const { employeeMsgAlert } = useEmployContext();

  const useEmployeeAdmissionDocumentClient = EmployeeAdmissionDocumentClient();

  const useS3DownloadClient = S3DownloadClient();

  const [onRefresh, setOnRefresh] = useState(false);
  // const [getIdForDelete, setGetIdForDelete] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [employeeDocument, setEmployeeDocument] = useState([]);

  const deleteEndpointClient = (id) => {
    useEmployeeAdmissionDocumentClient()
      .deleteDocumentType(id)
      .then(
        (response) => {
          employeeMsgAlert({
            response,
            message: 'Documento deletado com sucesso!',
          });
          setOnRefresh(true);
          setOpenDeleteModal(false);
        },
        (response) => {
          employeeMsgAlert({
            response,
          });
        }
      );
  };

  useEffect(() => {
    if (getId !== '') {
      setLoadingProgress(true);
      digitalDocuments()
        .searchDigitalDocuments({
          rowsPerPage: 50,
          documentTypeCategory:
            documentTypeCategoriesToAPIType.DocumentosPessoais,
          ...(getId ? { employeeId: getId } : {}),
        })
        .then(
          ({ data }) => {
            setLoadingProgress(false);
            setEmployeeDocument(data);
          },
          () => {
            setLoadingProgress(false);
          }
        );

      // setLoadingProgress(true);
      // useEmployeeAdmissionDocumentClient()
      //   .getDocumentByEmployeeId(getId)
      //   .then(
      //     (response) => {
      //       if (response) {
      //         setLoadingProgress(false);
      //         setEmployeeDocument(response);
      //       } else {
      //         setLoadingProgress(false);
      //         setEmployeeDocument([]);
      //       }
      //     },
      //     () => {
      //       setLoadingProgress(false);
      //     }
      //   );
    }

    setOnRefresh(false);
  }, [setEmployeeDocument, onRefresh, getId]);

  const handleClose = () => {
    setOpenModalDoc(false);
  };

  // const handleDelete = (data) => {
  //   setOpenDeleteModal(true);
  //   setGetIdForDelete(data);
  // };

  const handleDownloadDocuments = (data, name) => {
    useS3DownloadClient()
      .downloadDocument(data)
      .then((response) => {
        const linkSource = `data:application/pdf;base64,${response?.fileBase64Payload}`;
        const downloadLink = document.createElement('a');
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  };

  const formatDate = useCallback(
    (date) => date && moment(date).format('DD/MM/YYYY'),
    []
  );

  return (
    <>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={openModalDoc}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box className={classes.modal}>
          <Paper sx={{ padding: '20px' }}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item>
                <Typography className={classes.labelModal}>
                  Documentos de {employeeName}
                </Typography>
              </Grid>
              <Grid item>
                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
              </Grid>
            </Grid>
            <Grid mb={2} container>
              <Grid item sm={12}>
                <Styled.Container>
                  <Styled.Table>
                    <Styled.TableHead>
                      <Styled.TableRow>
                        <Styled.HeadCells>Tipo do Documento</Styled.HeadCells>
                        <Styled.HeadCells>Nº</Styled.HeadCells>
                        <Styled.HeadCells>Ano</Styled.HeadCells>
                        <Styled.HeadCells>Data do upload</Styled.HeadCells>
                        <Styled.HeadCells>Download</Styled.HeadCells>
                      </Styled.TableRow>
                    </Styled.TableHead>

                    <Styled.TableBody>
                      {employeeDocument.map((docs) => (
                        <Styled.TableRow key={docs?.id}>
                          <Styled.TableDataCells data-label="Nome">
                            {docs?.documentName}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Número">
                            {docs?.documentNumber}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Ano do Documento">
                            {docs?.documentYear}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Data do Upload">
                            {formatDate(docs?.documentDate)}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Download">
                            <Box>
                              <DownloadIcon
                                onClick={() => {
                                  handleDownloadDocuments(
                                    docs?.storageFilePath,
                                    docs?.fileName
                                  );
                                }}
                                sx={{ cursor: 'pointer', fontSize: '30px' }}
                              />
                            </Box>
                          </Styled.TableDataCells>
                          {/* <Styled.TableDataCells data-label="Excluir">
                            <Box>
                              <DeleteForeverIcon
                                onClick={() => {
                                  handleDelete(docs?.id);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '30px' }}
                              />
                            </Box>
                          </Styled.TableDataCells> */}
                        </Styled.TableRow>
                      ))}
                    </Styled.TableBody>
                  </Styled.Table>

                  {loadingProgress && (
                    <Styled.BoxFooter>
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="indeterminate" />
                      </Box>
                    </Styled.BoxFooter>
                  )}

                  {employeeDocument.length === 0 && (
                    <Grid
                      container
                      display="flex"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Typography>Nenhum Dependente Cadastrado</Typography>
                    </Grid>
                  )}
                </Styled.Container>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      {openDeleteModal && (
        <DeleteEmployeeModal
          open={openDeleteModal}
          // getId={getIdForDelete}
          setOpen={setOpenDeleteModal}
          deleteEndpoint={deleteEndpointClient}
        />
      )}
    </>
  );
}

EmployeeDocModal.propTypes = {
  employeeName: PropTypes.string,
  getId: PropTypes.string,
  openModalDoc: PropTypes.bool,
  setOpenModalDoc: PropTypes.func.isRequired,
};

EmployeeDocModal.defaultProps = {
  getId: '',
  openModalDoc: false,
  employeeName: '',
};
