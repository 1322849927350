import { useCallback } from 'react';
import { api } from '../services/api';

export default function digitalDocumentClient() {
  const baseUrl = '/DigitalDocument'

  const newDigitalDocument = useCallback(
    data => api.post(baseUrl, data).then(response => response.data),
    []
  )

  const listDigitalDocuments = useCallback(
    () => api.get(baseUrl).then(response => response.data),
    []
  )

  const getDigitalDocumentById = useCallback(
    (documentId) => api.get(`${baseUrl}/${documentId}`).then(response => response.data),
    []
  )

  const deleteDigitalDocument = useCallback(
    (documentId) => api.delete(`${baseUrl}/?id=${documentId}`).then(response => response.data),
    []
  )

  const searchDigitalDocuments = useCallback(
    (data) => api.post(`${baseUrl}/Search`, data).then(response => response.data),
    []
  )

  const updateDigitalDocument = useCallback(
    (id, data) => api.put(`${baseUrl}/${id}`, data).then(response => response.data),
    []
  )

  return useCallback(
    () => ({
      newDigitalDocument,
      listDigitalDocuments,
      getDigitalDocumentById,
      searchDigitalDocuments,
      deleteDigitalDocument,
      updateDigitalDocument
    }),
    [
      newDigitalDocument,
      listDigitalDocuments,
      getDigitalDocumentById,
      searchDigitalDocuments,
      deleteDigitalDocument,
      updateDigitalDocument
    ]
  )
}