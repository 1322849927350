import React, { useContext, useState } from 'react';

// import moment from 'moment';
import { Box, Grid, LinearProgress, Modal as ModalFilter } from '@mui/material';
import * as Styled from './styled';
import NewDigitalDocumentForm from '../NewDigitalDocument';
import DigitalDocumentsFilter from '../DigitalDocumentsFilter';
import ButtonAdd from '../Button';
import Modal from '../Modal';
import digitalDocumentClient from '../../clients/digitalDocumentClient';
import { DataTable } from '../DataTable';
import { AppContext } from '../../contexts/sidebarContext';
import EditDigitalDocument from '../EditDigitalDocument';
import S3DownloadClient from '../../clients/s3DownloadClient';

export default function NewDigitalDocument() {
  const digitalDocuments = digitalDocumentClient();
  const { msgAlert } = useContext(AppContext);
  const useS3DownloadClient = S3DownloadClient();

  const [isModalOpen, setOpenModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [isEditModalOpen, setEditModalStatus] = useState(false);
  const [currentDocumentId, setDocumentId] = useState(undefined);
  const [openDigitalFilter, setOpenDigitalFilter] = useState(false);

  const formatDigitalDocumentsToDataTable = (documentList) =>
    documentList.map(
      ({
        id,
        documentNumber,
        documentYear,
        // documentDate,
        documentType: { name: type, categoryDocumentType: category },
        storageFilePath: filePath,
        // actDocumentType: { name: act },
        fileName,
        documentName,
      }) => ({
        type,
        documentNumber,
        documentYear,
        // documentDate: moment(documentDate).format('DD/MM/yy'),
        // act,
        category,
        filePath,
        fileName,
        id,
        documentName,
      })
    );

  const loadLastInsertedItem = (documentId) => {
    if (!documentId) {
      setDocuments([]);
      return;
    }

    digitalDocuments()
      .getDigitalDocumentById(documentId)
      .then((document) => {
        setDocuments(formatDigitalDocumentsToDataTable([document]));
      });
  };

  const handleModalStatusChange = () => {
    setOpenModal(!isModalOpen);
  };

  const handleFilterDocuments = (data) => {
    setLoadingProgress(true);
    digitalDocuments()
      .searchDigitalDocuments({
        ...Object.fromEntries(
          Object.entries(data).filter((entry) => Boolean(entry[1]))
        ),
        rowsPerPage: 50,
      })
      .then(
        ({ data: documentList }) => {
          setLoadingProgress(false);
          if (!documentList.length) {
            msgAlert({
              response: {},
              status: 400,
              message:
                'Nenhum documento foi encontrador para os filtros atuais.',
              noReload: true,
            });

            return;
          }
          setDocuments(formatDigitalDocumentsToDataTable(documentList));
        },
        (error) => {
          setLoadingProgress(false);
          msgAlert({
            response: error,
            status: 400,
            noReload: true,
          });
        }
      );
  };

  /**
   * @param { { id: string; [k: string]: any } } response
   */
  const successfullyCreatedDigitalDocument = (response) => {
    handleModalStatusChange(false);
    loadLastInsertedItem(response.id);
  };

  const handleDownloadFile = ({ filePath, fileName }) => {
    useS3DownloadClient()
      .downloadDocument(filePath)
      .then((response) => {
        const linkSource = `data:application/pdf;base64,${response?.fileBase64Payload}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink.remove();
      });
  };

  const handleDeleteDocument = (documentId) => {
    digitalDocuments()
      .deleteDigitalDocument(documentId)
      .then(
        () => {
          msgAlert({
            response: {},
            status: 2001,
            message: 'O documento foi apagado com sucesso.',
            noReload: true,
          });

          setDocuments([]);
        },
        (error) => {
          msgAlert({ response: error, noReload: true });
          setLoadingProgress(false);
        }
      );
  };

  const handleEditDigitalDocument = (documentId) => {
    setDocumentId(documentId);
    setEditModalStatus(true);
  };

  const handleClose = () => {
    setOpenDigitalFilter(false);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          padding: '20px',
        }}
      >
        <Grid item xs={12}>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            container
            spacing={5}
          >
            <Grid
              display="flex"
              justifyContent="center"
              sx={{ width: '320px' }}
              item
            >
              <Styled.BoxTitle
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ButtonAdd
                  onClick={() => setOpenDigitalFilter(true)}
                  isLoginButton={false}
                  isLoginText={false}
                  text="Filtrar Documentos"
                  width="320px"
                  height="50px"
                  whiteButton
                />
              </Styled.BoxTitle>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              sx={{ width: '320px' }}
              item
            >
              <Styled.BoxTitle
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ButtonAdd
                  onClick={handleModalStatusChange}
                  isLoginButton={false}
                  isLoginText={false}
                  text="+ Adicionar"
                  width="320px"
                  height="50px"
                />
              </Styled.BoxTitle>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%' }} mt={2}>
          {/* documents-title::begin */}
          <Styled.BoxTitle
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Styled.Title>Documentos Digitais</Styled.Title>
          </Styled.BoxTitle>
          {/* documents-title::end */}

          {/* digital-documents-filter-box::begin */}
          {openDigitalFilter && (
            <ModalFilter
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              open={openDigitalFilter}
              onClose={handleClose}
              closeAfterTransition
            >
              <DigitalDocumentsFilter
                onReset={() => setDocuments([])}
                onSubmit={handleFilterDocuments}
                onClose={() => handleClose()}
              />
            </ModalFilter>
          )}
          {/* digital-documents-filter-box::end */}

          {/* loader::begin */}
          {loadingProgress && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="indeterminate" />
            </Box>
          )}
          {/* loader::end */}

          {/* datatable::begin */}
          <DataTable
            data={documents}
            rows={['Tipo de Documento', 'N°', 'Ano', 'Tipo de Categoria']}
            onOpenFile={handleDownloadFile}
            onDelete={handleDeleteDocument}
            onEdit={handleEditDigitalDocument}
            tableType="digitalDocumentsPage"
          />
          {/* datatable::end */}
        </Box>
      </Grid>

      {/* edit-digital-document-form-modal::begin */}
      {isEditModalOpen && (
        <EditDigitalDocument
          documentId={currentDocumentId}
          setDocumentId={setDocumentId}
          isOpen={isEditModalOpen}
          setOpen={setEditModalStatus}
        />
      )}
      {/* edit-digital-document-form-modal::end */}

      {/* new-digital-document-form-modal::begin */}
      {isModalOpen && (
        <Modal
          title="Novo Documento"
          setStatus={handleModalStatusChange}
          status={isModalOpen}
        >
          <Box style={{ padding: '60px 20px', width: '700px' }}>
            <NewDigitalDocumentForm
              onSuccess={successfullyCreatedDigitalDocument}
            />
          </Box>
        </Modal>
      )}
      {/* new-digital-document-form-modal::end */}
    </>
  );
}
