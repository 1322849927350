import styled from 'styled-components';

export const Button = styled.button` 
  border: ${(props) => (props.whiteButton ? '1px solid #000000' : 'none')};
  border-radius: 10px;
  max-width: ${(props) => props.width};
  width: 100%;
  height: ${(props) => props.height};
  background: ${(props) => {
    if (props.deleteButton) {
      return 'red';
    }
    if (props.whiteButton) {
      return '#fff';
    }
    return props.isLoginButton ? props.theme.background.dark : '#29e23c';
  }};
  ${(props) => (props.isLoginButton ? 'transparent' : props.theme.text.blue)};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
  
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    
    &:hover {
      opacity: .5;
    }
  }
`;

export const ButtonText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => {
    if (props.whiteButton) {
      return '#000';
    }
    return '#fff';
  }};
`;
