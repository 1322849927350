import React, { useContext, useState } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

import ModalMensage from '../ModalMensage';
import Button from '../Button';
import signUpClients from '../../clients/signUpClients';
import { AppContext } from '../../contexts/sidebarContext';

function DeleteModalConfirmation({ getId, open, setOpen }) {
  const useClient = signUpClients();

  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const handleConfirmation = () => {
    setIsLoading(true);
    useClient()
      .deleteClients(getId)
      .then(
        (response) => {
          msgAlert({
            response,
            message: 'Registro deletado com sucesso!',
          });
          setIsLoading(false);
          setOpen(false);
        },
        (response) => {
          msgAlert({
            response,
          });
          setIsLoading(false);
          setOpen(false);
        }
      );
  };

  return (
    <ModalMensage close={!isLoading} open={open} onClose={() => setOpen(false)}>
      <Box p={2} alignItems="center" justifyContent="center">
        <Box>
          <Box mt={2} mb={7}>
            <Typography align="center" variant="h4">
              Deletar?
            </Typography>
          </Box>

          <Box mb={7}>
            <Typography align="center">
              Tem certeza que deseja deletar o registro?
            </Typography>
          </Box>
          <Grid container spacing={2} align="center" justifyContent="center">
            <Grid item xs={8} sm={4} md={3} lg={2}>
              {isLoading ? (
                <Skeleton width={80} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                <Button
                  onClick={handleConfirmation}
                  text="Sim"
                  type="send"
                  width="150px"
                  height="45px"
                />
              )}
            </Grid>
            <Grid item xs={8} sm={4} md={3} lg={2}>
              {isLoading ? (
                <Skeleton width={80} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                <Button
                  onClick={() => setOpen(false)}
                  text="Não"
                  type="send"
                  width="150px"
                  height="45px"
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalMensage>
  );
}

DeleteModalConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  getId: PropTypes.string,
};

DeleteModalConfirmation.defaultProps = {
  open: false,
  getId: '',
};

export default DeleteModalConfirmation;
