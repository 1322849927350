const theme = {
  text: {
    blue: '#00A4E1',
    darkBlue: '#438EB8',
    darkGray: '#727272',
    dark: '#000000',
    white: '#FFFFFF',
  },

  background: {
    blue: '#00A4E1',
    gray: '#AEAEAE',
    dark: '#000000',
  },
};

export default theme;
