import { useCallback } from 'react'
import { api } from '../services/api'

export default function EmployeeDependentDocumentClient() {
    const getDocumentByDependentId = useCallback((id) => api.get(`/EmployeeDependentDocument/Dependent/${id}`).then((response) => response.data), [])

    const getEmployeeDependentDocument = useCallback(() => api.get('/EmployeeDependentDocument').then((response) => response.data), [])

    const newEmployeeDependentDocument = useCallback((data) => api.post('/EmployeeDependentDocument', data).then((response) => response), [])

    const deleteDocumentType = useCallback((id) => api.delete('/EmployeeDependentDocument', { params: { id } }).then((response) => response), [])

    const getByIdEmployeeDependentDocument = useCallback((id) => api.get(`/EmployeeDependentDocument/${id}`).then((response) => response.data), [])

    const editByIdEmployeeDependentDocument = useCallback((id, data) => api.put(`/EmployeeDependentDocument/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getDocumentByDependentId,
        getEmployeeDependentDocument,
        newEmployeeDependentDocument,
        deleteDocumentType,
        getByIdEmployeeDependentDocument,
        editByIdEmployeeDependentDocument
    }), [
        getDocumentByDependentId,
        getEmployeeDependentDocument,
        newEmployeeDependentDocument,
        deleteDocumentType,
        getByIdEmployeeDependentDocument,
        editByIdEmployeeDependentDocument
    ]
    )
}