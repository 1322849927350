import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormHelperText, Grid, Input, InputLabel } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Button from '../Button';
import * as Styled from './styled';

import { newPassword } from '../../services/api';
import { AppContext } from '../../contexts/sidebarContext';

export default function RecoverPassword() {
  const navigate = useNavigate();

  const location = useLocation();
  const token = encodeURIComponent(location.search.substring(7));

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const DEFAULT = {
    password: '',
    passwordConfirmation: '',
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, 'A senha deve ter no mínimo 5 caracteres')
        .required('Senha é requerida'),
      passwordConfirmation: Yup.string()
        .required('Confirmação de Senha é requerida')
        .oneOf([Yup.ref('password'), null], 'As senhas não coincidem.'),
    }),
    onSubmit: (data) => {
      newPassword(data.password, token).then(
        () => {
          msgAlert({
            status: 200,
            message: 'Senha alterada com sucesso!',
          });
          navigate('/login');
        },
        (response) => {
          msgAlert({
            response,
            noReload: true,
          });
        }
      );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <Styled.Container>
      <Styled.TextArea>
        <Styled.LoginBox>
          <Styled.InfoArea>
            <Styled.Title>Alterando a Senha</Styled.Title>
            <Styled.CheckboxArea>
              <Styled.CheckboxLabel>
                Digite a nova senha nos campos abaixos
              </Styled.CheckboxLabel>
            </Styled.CheckboxArea>

            <Styled.InputAreaWrapper>
              <Grid container spacing={4}>
                <Grid item sm={12} xs={12}>
                  <InputLabel>Crie uma nova senha</InputLabel>
                  <Input
                    fullWidth
                    inputlabelprops={{
                      shrink: true,
                    }}
                    title="Senha"
                    name="password"
                    type="password"
                    placeholder="•••••"
                    {...formik.getFieldProps('password')}
                    error={formik.touched.password && !!formik.errors.password}
                  />
                  <FormHelperText
                    hidden={!formik.touched.password || !formik.errors.password}
                    error={formik.touched.password && !!formik.errors.password}
                  >
                    {formik.errors.password}
                  </FormHelperText>
                  {!formik.touched.password && (
                    <FormHelperText>
                      A senha deve ter no mínimo 5 caracteres
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <InputLabel>Confirme sua nova senha</InputLabel>
                  <Input
                    fullWidth
                    inputlabelprops={{
                      shrink: true,
                    }}
                    title="Senha"
                    type="password"
                    name="passwordConfirmation"
                    placeholder="•••••"
                    {...formik.getFieldProps('passwordConfirmation')}
                    error={
                      formik.touched.passwordConfirmation &&
                      !!formik.errors.passwordConfirmation
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.passwordConfirmation ||
                      !formik.errors.passwordConfirmation
                    }
                    error={
                      formik.touched.passwordConfirmation &&
                      !!formik.errors.passwordConfirmation
                    }
                  >
                    {formik.errors.passwordConfirmation}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Styled.InputAreaWrapper>

            <Styled.ButtonArea>
              <Button
                text="Enviar"
                type="send"
                isLoginText
                isLoginButton
                onClick={handleSubmit}
              />
            </Styled.ButtonArea>
          </Styled.InfoArea>
        </Styled.LoginBox>
      </Styled.TextArea>
    </Styled.Container>
  );
}
