import { PropTypes } from 'prop-types';
import * as Styled from './styled';
import CloseButton from '../../assets/icons/close-button.svg';

function Modal({ status, setStatus, children, close, title }) {
  // const handleModalClick = (event) => {
  //   if (event.target.classList.contains('modal__bg')) {
  //     setStatus(false);
  //   }
  // };

  const handleClose = () => {
    setStatus(false);
  };

  return (
    <Styled.Container
      status={status}
      // onClick={handleModalClick}
      className="modal__bg"
    >
      <Styled.ModalBody>
        {title && <Styled.Title>{title}</Styled.Title>}
        <Styled.CloseButton>
          {close && (
            <Styled.CloseIcon src={CloseButton} onClick={handleClose} />
          )}
        </Styled.CloseButton>
        {children}
      </Styled.ModalBody>
    </Styled.Container>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
  close: PropTypes.bool,
  title: PropTypes.string,
};

Modal.defaultProps = {
  close: true,
  title: undefined,
};
export default Modal;
