import React, { useContext, useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import { Help } from '@mui/icons-material';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from 'styled-components';
import * as Styled from './styled';
import Button from '../Button';
import userClient from '../../clients/userClient';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Montserrat',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

export default function NewUser() {
  const classes = useStyles();
  const useClient = userClient();

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const [disabeButton, setDisabeButton] = useState(false);

  const getRoleUser = localStorage.getItem('role');

  const DEFAULT = {
    email: '',
    password: '',
    fullName: '',
    phone: '',
    administrator: true,
    enabled: true,
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Campo obrigatório')
        .email('Email deve ser válido'),
      password: Yup.string().required('Campo obrigatório'),
      fullName: Yup.string().required('Campo obrigatório'),
      phone: Yup.number().required('Campo obrigatório'),
      administrator: Yup.boolean(),
      enabled: Yup.boolean(),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        email: data.email,
        password: data.password,
        fullName: data.fullName,
        phone: data.phone.toString(),
        administrator: data.administrator,
        enabled: data.enabled,
        tenantId: useTenantId,
      };

      setDisabeButton(true);
      useClient()
        .newUser(payload)
        .then(
          (response) => {
            msgAlert({
              response,
              message: 'Usuário cadastrado com sucesso!',
            });
          },
          (resp) => {
            msgAlert({
              response: resp,
            });
          }
        );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const theme = useTheme();

  const resetForm = () => {
    formik.resetForm();
  };

  return (
    <Styled.Container>
      <Styled.BoxTitle>
        <Styled.TitleModal>Novo Usuário</Styled.TitleModal>
      </Styled.BoxTitle>

      <Styled.BoxSignUp>
        <Typography
          sx={{
            width: '51px',
            height: '20px',
            left: '504px',
            top: '341px',
            fontStyle: 'normal',
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            '@media (max-width: 1280px)': {
              marginTop: '10px',
              marginBottom: '10px',
            },
          }}
        >
          Dados
        </Typography>
        <Styled.InputAreaWrapper>
          <Styled.FirstRow>
            <Grid container direction="column" spacing={3}>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs>
                  <FormControl
                    fullWidth
                    error={formik.touched.fullName && formik.errors.fullName}
                  >
                    <TextField
                      height="40px"
                      width="350px"
                      type="text"
                      placeholder="Ex: Maria Silva"
                      name="name"
                      label="Nome Completo"
                      className={classes.textField}
                      {...formik.getFieldProps('fullName')}
                      error={formik.touched.fullName && formik.errors.fullName}
                    />
                    <FormHelperText
                      hidden={
                        !formik.touched.fullName || !formik.errors.fullName
                      }
                      error={
                        formik.touched.fullName && !!formik.errors.fullName
                      }
                    >
                      {formik.errors.fullName}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl
                    fullWidth
                    error={formik.touched.email && formik.errors.email}
                  >
                    <TextField
                      height="40px"
                      width="350px"
                      type="email"
                      label="Email"
                      className={classes.textField}
                      placeholder="Ex: maria@gmail.com"
                      name="email"
                      {...formik.getFieldProps('email')}
                      icon={
                        <Tooltip title="Usado para login" placement="right">
                          <Help fontSize="15px" />
                        </Tooltip>
                      }
                      error={formik.touched.email && formik.errors.email}
                    />
                    <FormHelperText
                      hidden={!formik.touched.email || !formik.errors.email}
                      error={formik.touched.email && !!formik.errors.email}
                    >
                      {formik.errors.email}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid container item direction="row" spacing={2}>
                  <Grid item xs>
                    <FormControl
                      fullWidth
                      error={formik.touched.phone && formik.errors.phone}
                    >
                      <TextField
                        height="40px"
                        width="350px"
                        type="number"
                        label="Telefone"
                        className={classes.textField}
                        placeholder="Ex: 999998888"
                        name="phoneNumber"
                        {...formik.getFieldProps('phone')}
                        error={formik.touched.phone && formik.errors.phone}
                        sx={{
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield',
                          },
                          '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                        }}
                      />
                      <FormHelperText
                        hidden={!formik.touched.phone || !formik.errors.phone}
                        error={formik.touched.phone && !!formik.errors.phone}
                      >
                        {formik.errors.phone}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <FormControl
                      fullWidth
                      error={formik.touched.password && formik.errors.password}
                    >
                      <TextField
                        height="40px"
                        width="350px"
                        type="password"
                        placeholder="●●●●●●"
                        name="email"
                        label="Senha"
                        className={classes.textField}
                        {...formik.getFieldProps('password')}
                        error={
                          formik.touched.password && formik.errors.password
                        }
                      />
                      <FormHelperText
                        hidden={
                          !formik.touched.password || !formik.errors.password
                        }
                        error={
                          formik.touched.password && !!formik.errors.password
                        }
                      >
                        {formik.errors.password}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      width: '100%',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '20px',
                      marginTop: '25px',
                      '@media (max-width: 1280px)': {
                        marginTop: '20px',
                      },
                    }}
                  >
                    Selecione as opções
                  </Typography>
                </Grid>

                <Grid container item direction="row" spacing={4}>
                  <Grid item>
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                      }}
                      label="Administrador"
                      control={
                        <Checkbox
                          checked={formik.values.administrator}
                          onChange={(event) => {
                            // handleCheckedAdministrator(event);
                            formik.setFieldValue(
                              'administrator',
                              event.target.checked
                            );
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: theme.background.blue,
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                  {(getRoleUser === 'AdministradorSistema' ||
                    getRoleUser === 'AdmnistradorTenant') && (
                    <Grid item>
                      <FormControlLabel
                        sx={{
                          width: '100%',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                        }}
                        label="Ativo"
                        control={
                          <Checkbox
                            checked={formik.values.enabled}
                            onChange={(event) => {
                              formik.setFieldValue(
                                'enabled',
                                event.target.checked
                              );
                            }}
                            sx={{
                              '&.Mui-checked': {
                                color: theme.background.blue,
                              },
                            }}
                          />
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Styled.FirstRow>
        </Styled.InputAreaWrapper>
      </Styled.BoxSignUp>

      <Styled.ButtonArea>
        <Grid container mt={3} mb={2} spacing={3}>
          <Grid item xs={6}>
            <Button
              text="Limpar campos"
              type="send"
              onClick={resetForm}
              width="100%"
              height="72px"
              whiteButton
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disabeButton}
              text="Cadastrar"
              type="send"
              onClick={handleSubmit}
              width="100%"
              height="72px"
            />
          </Grid>
        </Grid>
      </Styled.ButtonArea>
    </Styled.Container>
  );
}
