import {LoadingButton} from '@mui/lab';
import { makeStyles} from '@mui/styles';
import { FormHelperText, InputLabel } from '@mui/material';
import React, {createRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { UploadFileOutlined as Icon } from '@mui/icons-material';
import { getBase64File, getFileFromBase64 } from '../../helpers/file';
import S3DownloadClient from '../../clients/s3DownloadClient';

const useStyles = makeStyles(() => ({
  fileField: {
    borderColor: '#00000099 !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    height: '72px',
    borderRadius: '10px !important',
    textTransform: 'capitalize !important',
    fontSize: '16px !important',
    fontWeight: '400',
    position: 'relative',
    justifyContent: 'flex-start !important',

    '& .inputInner': {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      '& svg': {
        color: '#00000099 !important',
        alignSelf: 'flex-end'
      }
    },

    '& label': {
      position: 'absolute',
      fontSize: '16px !important',
      lineHeight: '1.5rem !important',
      left: 0,
      top: '50%',
      transform: 'translateY(-85%)',
      backgroundColor: '#fff',
      color: '#00000099 !important',
      padding: '0 0.3rem',
      margin: '0 0.5rem',
      transition: '.1s ease-out',
      transformOrigin: 'left top',
      pointerEvents: 'none',
    },

    '& label.active': {
      top: '0',
      transform: 'translateY(-50%) scale(.9)',

      '& + div p': {
        color: '#000000DE !important',
        fontSize: '16px !important',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },

    '&:hover': {
      backgroundColor: 'inherit !important',
      borderColor: '#00000099 !important',
    },

    '&.error': {
      borderColor: 'red !important',

      '& label': {
        color: 'red !important',
      },

      '& .inputInner': {
        '& svg, &p': {
          color: 'red !important'
        }
      }
    },

  },
}));

export default function FileInput({ label, accept, required, onChange, initialValue }) {
  const classes = useStyles();
  const fileInput = createRef();
  const container = createRef();
  const useS3DownloadClient = S3DownloadClient();

  const [isLoading, setLoader] = useState(false);
  const [file, setFiles] = useState(undefined);
  const [error, setError] = useState(undefined);

  async function handleChange({ target: { files = [] } }) {
    const [newFile = undefined] = files;
    setFiles(files);

    if (!newFile) {
      setError(required);
      onChange(newFile)
      return;
    }

    if (newFile === files) return;

    onChange(await getBase64File(
      newFile,
      { FileName: undefined, fileBase64Payload: '' }
    ));
  }

  const loadCurrentFile = async (filePath, fileName) => {
    setLoader(true);
    const response = await useS3DownloadClient().downloadDocument(filePath);
    const files = await getFileFromBase64(response.fileBase64Payload, fileName);
    await handleChange({target: { files } });
    setLoader(false);
  }

  useEffect(
    () => {
      if (initialValue === undefined) return;
      loadCurrentFile(initialValue.path, initialValue.name).then(() => {})
      fileInput.current.files = file;
    },
    []
  )
  return <>
    <LoadingButton
      variant="outlined"
      component="label"
      className={`${classes.fileField} ${error ? 'error' :''}`}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      title="Clique para fazer upload de um arquivo."
      loading={isLoading}
      ref={container}
    >
      <InputLabel required={required} className={`${file?.length ? 'active': ''}`}>
        { label }
      </InputLabel>

      <div className='inputInner'>
        {/* file-name::begin */}
        <p>{ file?.length && file?.[0]?.name }</p>
        {/* file-name::end */}
        <Icon />
      </div>

      <input
        ref={fileInput}
        id="file-input"
        accept={accept}
        onChange={handleChange}
        type="file"
        hidden
      />
    </LoadingButton>
    <FormHelperText
      hidden={!error}
      error={error}
    >
      Esse campo é obrigatório
    </FormHelperText>
  </>;
}

FileInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string
  })
}

FileInput.defaultProps = {
  label: 'Arquivo',
  required: false,
  initialValue: undefined
}
