import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import FilterBox from '../FilterBox';
import NumberField from '../Fields/NumberField';
import documentTypeClient from '../../clients/documentTypeClient';
import EmployeeRegisterClient from '../../clients/employeeRegisterClient';
import EmployeeDependentClient from '../../clients/employeeDependentClient';
import {
  documentTypeCategories,
  documentTypeCategoriesToAPIType,
} from '../../helpers/constants';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  muiField: {
    width: '100%;',
  },
}));

function DocumentTypeSelectOptions(options, documentCategoryType) {
  const mappedOptions = options?.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));
  return mappedOptions.length
    ? mappedOptions
    : [
        <MenuItem key="no-doc-type">
          Nenhum tipo de documento cadastrado{' '}
          {documentCategoryType && 'para essa categoria'} ainda.
        </MenuItem>,
      ];
}

export default function DigitalDocumentsFilter({ onSubmit, onReset, onClose }) {
  const classes = useStyles();
  const documentType = documentTypeClient();
  const employeeClient = EmployeeRegisterClient();
  const employeeDependent = EmployeeDependentClient();

  const context = useContext(AppContext);
  const { digitalDocumentFilter, setDigitalDocumentFilter } = context;

  const formik = useFormik({
    initialValues: {
      documentTypeId: digitalDocumentFilter.documentTypeId ?? '',
      documentTypeCategory: digitalDocumentFilter.documentTypeCategory ?? '',
      documentYear: digitalDocumentFilter.documentYear ?? '',
      dateStart: digitalDocumentFilter.dateStart ?? '',
      dateEnd: digitalDocumentFilter.dateEnd ?? '',
      employeeId: digitalDocumentFilter.employeeId ?? '',
      employeeDependantId: digitalDocumentFilter.employeeDependantId ?? '',
      documentNumber: digitalDocumentFilter.documentNumber ?? '',
      searchText: digitalDocumentFilter.searchText ?? '',
    },
    validationSchema: Yup.object({
      documentTypeId: Yup.string(),
      documentTypeCategory: Yup.string(),
      documentYear: Yup.string(),
      dateStart: Yup.date(),
      dateEnd: Yup.date(),
      employeeId: Yup.string(),
      employeeDependantId: Yup.string(),
      documentNumber: Yup.string(),
      searchText: Yup.string(),
    }),
    onSubmit: (data) => {
      onSubmit(data);
      setDigitalDocumentFilter(data);
    },
  });

  const [options = [], setDocumentTypeOptions] = useState();
  const [employees, setEmployees] = useState();
  const [employeeDependents, setEmployeeDependents] = useState();
  const [mappedDocumentTypes, setMappedOptions] = useState([]);

  function getOptionsByCategoryType(optionsList, category) {
    optionsList.sort((a, b) => (a.name > b.name ? 1 : -1)); // sorts it by name.
    return options
      .filter((opt) => opt.categoryDocumentType === category)
      .map(({ name, id }) => ({ label: name, value: id }));
  }

  useEffect(() => {
    documentType()
      .getDocumentType()
      .then((response) => {
        setDocumentTypeOptions(response);
      });

    employeeClient()
      .getEmployee()
      .then((response) => {
        setEmployees(
          response.map((user) => ({
            value: user.id,
            label: user.name,
          }))
        );
      });

    employeeDependent()
      .getEmployeeDependent()
      .then((response) => {
        setEmployeeDependents(
          response.map((dependent) => ({
            value: dependent.id,
            label: dependent.name,
          }))
        );
      });
  }, []);

  useEffect(() => {
    options.sort((a, b) => (a.name > b.name ? 1 : -1));
    setMappedOptions(
      getOptionsByCategoryType(options, formik.values.documentTypeCategory)
    );
  }, [formik.values]);

  const handleReset = () => {
    formik.resetForm();
    setDigitalDocumentFilter({});
    formik.setFieldValue('documentTypeId', '');
    formik.setFieldValue('documentTypeCategory', '');
    formik.setFieldValue('documentYear', '');
    formik.setFieldValue('dateStart', '');
    formik.setFieldValue('dateEnd', '');
    formik.setFieldValue('employeeId', '');
    formik.setFieldValue('employeeDependantId', '');
    formik.setFieldValue('documentNumber', '');
    formik.setFieldValue('searchText', '');
    formik.setFieldValue('searchText', '');
    onReset?.();
  };

  const handleSubmit = async () => {
    await formik.submitForm();
    onClose();
  };

  return (
    <FilterBox onSubmit={handleSubmit} onReset={handleReset}>
      <Box sx={{ color: '#00000099' }} mt={1} mb={2}>
        Filtrar por:
      </Box>

      {/* search::begin */}
      <Grid item xs={12} paddingRight={2} paddingBottom={2}>
        <InputLabel id="document-type">Busca</InputLabel>
        <TextField
          variant="standard"
          sx={{ width: '100%' }}
          {...formik.getFieldProps('searchText')}
          className={classes.muiField}
        />
      </Grid>
      {/* search::end */}

      {/* category-type-input::begin */}
      <Grid item xs={4} paddingRight={2} paddingBottom={2}>
        <InputLabel>Categoria do Documento</InputLabel>
        <Select
          label="Tipo do Ato"
          variant="standard"
          name="documentTypeCategory"
          className={classes.muiField}
          {...formik.getFieldProps('documentTypeCategory')}
        >
          {Object.entries(documentTypeCategories)?.map(([value, label]) => (
            <MenuItem
              key={value}
              value={documentTypeCategoriesToAPIType[value] ?? value}
            >
              {label}
            </MenuItem>
          )) ?? []}
        </Select>
      </Grid>
      {/* category-type-input::end */}

      {/* document-type-input::begin */}
      <Grid item xs={4} paddingRight={2} paddingBottom={2}>
        <InputLabel id="document-type">Tipo de documento</InputLabel>
        <Select
          id="document-type"
          label="Tipo do documento"
          variant="standard"
          name="documentTypeId"
          {...formik.getFieldProps('documentTypeId')}
          disabled={!formik.values.documentTypeCategory?.length}
          className={classes.muiField}
        >
          {DocumentTypeSelectOptions(
            mappedDocumentTypes,
            formik.values.documentTypeCategory
          )}
        </Select>
      </Grid>
      {/* document-type-input::end */}

      {/* document-code/number::begin */}
      <Grid item xs={4} paddingRight={2} paddingBottom={2}>
        <InputLabel>N° do Documento</InputLabel>
        <NumberField
          required
          maxLength={4}
          name="number"
          variant="standard"
          {...formik.getFieldProps('documentNumber')}
        />
      </Grid>
      {/* document-code/number::end */}

      {/* year::begin */}
      <Grid item xs={4} paddingBottom={2}>
        <InputLabel>Ano do Documento</InputLabel>
        <NumberField
          maxLength={4}
          placehlder="Ex: 2022"
          name="number"
          variant="standard"
          {...formik.getFieldProps('documentYear')}
        />
      </Grid>
      {/* year::end */}

      {/* start-date::begin */}
      <Grid item xs={4} paddingRight={2} paddingBottom={2}>
        <InputLabel>Data de Início</InputLabel>
        <TextField
          type="date"
          placeholder="Data"
          name="dateStart"
          variant="standard"
          className={classes.muiField}
          {...formik.getFieldProps('dateStart')}
        />
      </Grid>
      {/* start-date::end */}

      {/* end-date::begin */}
      <Grid item xs={4} paddingRight={2} paddingBottom={2}>
        <InputLabel>Data Final</InputLabel>
        <TextField
          type="date"
          placeholder="Data"
          name="dateEnd"
          variant="standard"
          className={classes.muiField}
          {...formik.getFieldProps('dateEnd')}
        />
      </Grid>
      {/* end-date::end */}

      {/* employee::begin */}
      <Grid item xs={6} paddingRight={2} paddingBottom={2}>
        <InputLabel id="document-type">Colaborador</InputLabel>
        <Select
          variant="standard"
          name="type"
          className={classes.muiField}
          {...formik.getFieldProps('employeeId')}
        >
          {employees?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )) ?? []}
        </Select>
      </Grid>
      {/* employee::end */}

      {/* employee-dependant::begin */}
      <Grid item xs={4} paddingRight={2} paddingBottom={2}>
        <InputLabel id="document-type">Dependente</InputLabel>
        <Select
          variant="standard"
          name="type"
          className={classes.muiField}
          {...formik.getFieldProps('employeeDependantId')}
        >
          {employeeDependents?.map((option) => (
            <MenuItem
              style={{ width: '100% !important;' }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )) ?? []}
        </Select>
      </Grid>
      {/* employee-dependant::end */}
    </FilterBox>
  );
}

DigitalDocumentsFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
