/* eslint-disable */
import { PropTypes } from 'prop-types';

import React, { useContext, useState } from 'react';
import { Grid, Box, LinearProgress } from '@mui/material';
import digitalDocumentClient from '../../clients/digitalDocumentClient';
import { AppContext } from '../../contexts/sidebarContext';
import DigitalDocumentForm from '../DigitalDocumentForm';

export default function NewDigitalDocumentForm({
  onSuccess,
  documentCategoryType,
  search,
  getEmployeeDependentId,
  employeeData,
}) {
  const context = useContext(AppContext);
  const { msgAlert } = context;

  const digitalDocument = digitalDocumentClient();
  const [loadingProgress, setLoadingProgress] = useState(false);

  const handleSubmit = (payload) => {
    setLoadingProgress(true);

    digitalDocument()
      .newDigitalDocument(payload)
      .then(
        (response) => {
          msgAlert({
            response,
            status: 201,
            message: 'Documento digital enviado com sucesso.',
            noReload: true,
          });

          onSuccess(response);
          setLoadingProgress(false);
        },
        (error) => {
          msgAlert({ response: error, noReload: true });
          setLoadingProgress(false);
        }
      );
  };

  return (
    <Grid container direction="column" padding={2} spacing={2}>
      {loadingProgress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="indeterminate" />
        </Box>
      )}
      <DigitalDocumentForm
        onSubmit={handleSubmit}
        documentCategoryType={documentCategoryType}
        isLoading={loadingProgress}
        searchVisible={search}
        getEmployeeDependentId={getEmployeeDependentId}
        employeeData={employeeData}
      />
    </Grid>
  );
}

NewDigitalDocumentForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  documentCategoryType: PropTypes.string,
  search: PropTypes.bool,
  getEmployeeDependentId: PropTypes.string,
  employeeData: PropTypes.bool,
};

NewDigitalDocumentForm.defaultProps = {
  documentCategoryType: undefined,
  search: true,
  getEmployeeDependentId: '',
  employeeData: false,
};
