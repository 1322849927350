import axios from 'axios';

const token = localStorage.getItem('token');
const tokenAuth = token?.substring(1, token.length - 1);

export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${tokenAuth}`,
  },
});

export const createSession = async (email, password, tenantName) =>
  api.post('/Authorization/login', { email, password, tenantName });
export const forgotPassword = async (email) =>
  api.post('/Authorization/forgotPassword', { email });
export const newPassword = async (password, passwordToken) =>
  api.post(
    `/Authorization/newPassword?passwordRecoveryToken=${passwordToken}`,
    { password }
  );
