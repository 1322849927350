import React, { useEffect, useState, useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import signUpClients from '../../clients/signUpClients';
import Button from '../Button';
import Modal from '../Modal';
import NewClients from '../NewClients';
import * as Styled from './styled';
import DeleteModalConfirmation from './DeleteModalConfirmation';
import { AppContext } from '../../contexts/sidebarContext';
import SearchInput from '../SearchInput';

function ListClients() {
  const useClient = signUpClients();

  const [clients, setClients] = useState([]);
  const [updateClientValue, setUpdateClientValue] = useState({});
  const [idClient, setIdClient] = useState('');
  const [open, setOpen] = useState(false);

  const context = useContext(AppContext);

  const { modalStatus, setModalStatus, msgAlert, filteredList, filteringList } =
    context;

  const handleModalClick = () => {
    setModalStatus(!modalStatus);
    setUpdateClientValue({});
  };

  const maskCpnj = (value) =>
    value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  const handleEdit = (objEdit) => {
    setModalStatus(true);
    setUpdateClientValue(objEdit);
  };

  const handleDelete = (id) => {
    setIdClient(id);
    setOpen(true);
  };

  useEffect(() => {
    useClient()
      .getClients()
      .then(
        (response) => {
          if (response) {
            setClients(response);
            filteringList(response);
          } else {
            setClients([]);
          }
        },
        (response) => {
          msgAlert({
            response,
          });
        }
      );
  }, []);

  const traslateFunction = (translate) => {
    if (translate) {
      return 'Sim';
    }
    return 'Não';
  };

  return (
    <Styled.Container>
      <Styled.BoxSearch>
        <SearchInput list={clients} searchFieldName={['companyName', 'cnpj']} />
      </Styled.BoxSearch>
      <Styled.BoxTitle>
        <Styled.Title>Listagem de clientes</Styled.Title>
        <Button
          onClick={handleModalClick}
          text="+ Adicionar"
          width="150px"
          height="50px"
        />
      </Styled.BoxTitle>

      <Styled.Table>
        <Styled.TableHead>
          <Styled.HeadCells>Razão Social</Styled.HeadCells>
          <Styled.HeadCells>CNPJ</Styled.HeadCells>
          <Styled.HeadCells>Data limite de ativação</Styled.HeadCells>
          <Styled.HeadCells>Nome</Styled.HeadCells>
          <Styled.HeadCells>Ativo</Styled.HeadCells>
          <Styled.HeadCells>Editar</Styled.HeadCells>
          <Styled.HeadCells>Excluir</Styled.HeadCells>
        </Styled.TableHead>

        <Styled.TableBody>
          {filteredList?.map((data) => (
            <Styled.TableRow key={data.id}>
              <Styled.TableDataCells data-label="Razão Social">
                {data.companyName}
              </Styled.TableDataCells>
              <Styled.TableDataCells data-label="CNPJ">
                {maskCpnj(data.cnpj)}
              </Styled.TableDataCells>
              <Styled.TableDataCells data-label="Data limite">
                {new Date(data.dueDate).toLocaleDateString('pt-BR')}
              </Styled.TableDataCells>
              <Styled.TableDataCells data-label="Nome do tenant">
                {data?.tenantName}
              </Styled.TableDataCells>
              <Styled.TableDataCells data-label="Nome do tenant">
                {traslateFunction(data?.enabled)}
              </Styled.TableDataCells>
              <Styled.TableDataCells data-label="Editar">
                <Box>
                  <EditIcon
                    onClick={() => {
                      handleEdit(data);
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                </Box>
              </Styled.TableDataCells>
              <Styled.TableDataCells data-label="Excluir">
                <Box>
                  <DeleteForeverIcon
                    onClick={() => handleDelete(data.id)}
                    sx={{ cursor: 'pointer' }}
                  />
                </Box>
              </Styled.TableDataCells>
            </Styled.TableRow>
          ))}
        </Styled.TableBody>
      </Styled.Table>

      {modalStatus && (
        <Modal status={modalStatus} setStatus={setModalStatus}>
          <NewClients updateValues={updateClientValue} />
        </Modal>
      )}

      <DeleteModalConfirmation getId={idClient} open={open} setOpen={setOpen} />
    </Styled.Container>
  );
}

export default ListClients;
