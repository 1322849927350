import React, { useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import {
  Grid,
  LinearProgress,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import * as Styled from './styled';
import { useEmployContext } from './EmployContext';
import S3DownloadClient from '../../clients/s3DownloadClient';
import ButtonNewAdd from '../Button';
import { AppContext } from '../../contexts/sidebarContext';
import { DataTableEmployee } from '../DataTableEmployee';
import digitalDocumentClient from '../../clients/digitalDocumentClient';
import {
  documentTypeCategories,
  documentTypeCategoriesToAPIType,
} from '../../helpers/constants';
import SearchInput from '../SearchInput';
import Modal from '../Modal';
import NewDigitalDocumentForm from '../NewDigitalDocument';
import EditDigitalDocument from '../EditDigitalDocument';
import PdfExportClient from '../../clients/pdfExportClient';

export default function EmployeeDocument({
  categoryType,
  tabId,
  getEmployeeDependentId,
  employeeData,
}) {
  const { msgAlert, filteringList, filteredList } = useContext(AppContext);
  const { employId, tabValue } = useEmployContext();

  const digitalDocuments = digitalDocumentClient();
  const useS3DownloadClient = S3DownloadClient();
  const pdfExport = PdfExportClient();
  const searchTerms = [
    'documentName',
    'documentNumber',
    'documentYear',
    'fileName',
    'type',
  ];
  const [documents, setDocuments] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [isNewDocumentModalOpen, setNewDocumentModalStatus] = useState(false);
  const [isEditModalOpen, setEditModalStatus] = useState(false);
  const [currentDocumentId, setDocumentId] = useState(undefined);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const formatDigitalDocumentsToDataTable = (documentList) => {
    if (documentList.filter((result) => result.type !== undefined).length > 0) {
      return documentList.map(
        ({
          id,
          type,
          documentNumber,
          documentYear,
          documentDate,
          filePath,
          fileName,
          documentName,
        }) => ({
          type,
          documentName,
          documentNumber,
          documentYear,
          documentDate,
          filePath,
          fileName,
          id,
        })
      );
    }
    if (employeeData || getEmployeeDependentId !== '' || tabId) {
      return documentList.map(
        ({
          id,
          documentType: { name: type } = '',
          documentNumber,
          documentYear,
          documentDate,
          storageFilePath: filePath,
          fileName,
          documentName,
        }) => ({
          type,
          documentName,
          documentNumber,
          documentYear,
          documentDate,
          filePath,
          fileName,
          id,
        })
      );
    }

    return documentList.map(
      ({
        id,
        documentNumber,
        documentYear,
        documentType: { name: type } = '',
        storageFilePath: filePath,
        fileName,
      }) => ({
        documentNumber,
        documentYear,
        type,
        filePath,
        fileName,
        id,
      })
    );
  };

  const loadDocuments = () => {
    let idToPayload;
    if (getEmployeeDependentId !== '') {
      idToPayload = getEmployeeDependentId;
    } else {
      idToPayload = employId;
    }
    const verifyIdToPayload = () => {
      if (getEmployeeDependentId !== '') {
        return idToPayload ? { employeeDependentId: idToPayload } : {};
      }
      return idToPayload ? { employeeId: idToPayload } : {};
    };
    setLoadingProgress(true);
    digitalDocuments()
      .searchDigitalDocuments({
        rowsPerPage: 50,
        documentTypeCategory: documentTypeCategoriesToAPIType[categoryType],
        ...verifyIdToPayload(),
      })
      .then(({ data }) => {
        setLoadingProgress(false);
        if (employeeData) {
          setDocuments(
            formatDigitalDocumentsToDataTable(
              data.filter((result) => result.employeeDependents.length === 0)
            )
          );
          filteringList(
            data.filter((result) => result.employeeDependents.length === 0)
          );
        } else {
          setDocuments(formatDigitalDocumentsToDataTable(data));
          filteringList(data);
        }
      });
  };
  const showNewDocument = () => {
    setOnRefresh(true);
    setNewDocumentModalStatus(true);
    // setOpenNewDocument(true);
    // setUploadName('');
    // setUploadLabel('');
    // setUploadDate(moment(new Date(Date.now())).format('YYYY-MM-DD'));
    // setUploadDocumentYear();
    // setUploadDocumentNumber('');
  };

  const handleDownloadFile = ({ filePath, fileName }) => {
    useS3DownloadClient()
      .downloadDocument(filePath)
      .then((response) => {
        const linkSource = `data:application/pdf;base64,${response?.fileBase64Payload}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink.remove();
      });
  };

  const handleDeleteDocument = (documentId) => {
    digitalDocuments()
      .deleteDigitalDocument(documentId)
      .then(
        () => {
          msgAlert({
            response: {},
            status: 2001,
            message: 'O documento foi apagado com sucesso.',
            noReload: true,
          });

          loadDocuments();
        },
        (error) => {
          msgAlert({ response: error, noReload: true });
          setLoadingProgress(false);
        }
      );
  };

  const handleEditDigitalDocument = (documentId) => {
    setDocumentId(documentId);
    setEditModalStatus(true);
  };

  const onCreateDigitalDocument = () => {
    setNewDocumentModalStatus(false);
    loadDocuments();
  };

  useEffect(() => {
    if (tabValue === tabId) {
      loadDocuments();
    }
    setOnRefresh(false);
  }, [setOnRefresh, onRefresh, employId, filteringList, tabValue]);

  const handlePdfExport = () => {
    setLoadingPdf(true);
    pdfExport()
      .getPdfExport(employId)
      .then((response) => {
        setLoadingPdf(false);
        const blob = URL.createObjectURL(response.data);
        saveAs(blob, 'historico_funcional.pdf');
      });
  };

  return (
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Grid item sm={12}>
        <Grid mb={2} container>
          <Grid item sm={12}>
            <Styled.Container>
              {/* title::begin */}
              <Styled.BoxTitleDoc>
                {documentTypeCategories[categoryType] && (
                  <Styled.Title>
                    {documentTypeCategories[categoryType]}
                  </Styled.Title>
                )}
                {tabValue === '7' && (
                  <Box display="flex" alignItems="center" gap="10px">
                    <Button
                      disabled={loadingPdf}
                      variant="contained"
                      onClick={handlePdfExport}
                    >
                      Gerar Histórico Funcional
                    </Button>
                    {loadingPdf && <CircularProgress />}
                  </Box>
                )}
                <ButtonNewAdd
                  isLoginButton={false}
                  isLoginText={false}
                  onClick={showNewDocument}
                  text="+ Adicionar"
                  width="150px"
                  height="45px"
                />
              </Styled.BoxTitleDoc>
              {/* title::end */}

              <Styled.ContainerContent>
                {/* search-input::begin */}
                <Grid
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  mt={2}
                  mb={2}
                  item
                  sm={12}
                  mx={0}
                >
                  <Styled.BoxSearch style={{ width: '100%' }}>
                    <SearchInput
                      list={documents}
                      searchFieldName={searchTerms}
                    />
                  </Styled.BoxSearch>
                </Grid>
                {/* search-input::end */}

                <Box sx={{ width: '100% !important', padding: '60px 0' }}>
                  {/* loader::begin */}
                  {loadingProgress && (
                    <Styled.BoxFooter>
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="indeterminate" />
                      </Box>
                    </Styled.BoxFooter>
                  )}
                  {/* loader::end */}

                  {/* datatable::begin */}
                  <DataTableEmployee
                    data={formatDigitalDocumentsToDataTable(filteredList)}
                    rows={[
                      'Tipo de Documento',
                      'Assunto',
                      'N°',
                      'Ano',
                      'Data do Documento',
                    ]}
                    onOpenFile={handleDownloadFile}
                    onDelete={handleDeleteDocument}
                    onEdit={handleEditDigitalDocument}
                    style={{ width: '100% !important', padding: 0 }}
                    tableType="digitalDocumentsEmployee"
                    className="datatable"
                  />
                  {/* datatable::end */}
                </Box>
              </Styled.ContainerContent>
            </Styled.Container>
          </Grid>
        </Grid>
      </Grid>

      {/* new-digital-document-form-modal::begin */}
      {isNewDocumentModalOpen && (
        <Modal
          title="Novo Documento"
          setStatus={setNewDocumentModalStatus}
          status={isNewDocumentModalOpen}
        >
          <Box style={{ padding: '60px 20px', width: '700px' }}>
            <NewDigitalDocumentForm
              search={false}
              onSuccess={onCreateDigitalDocument}
              documentCategoryType={categoryType}
              getEmployeeDependentId={getEmployeeDependentId}
              employeeData={employeeData}
            />
          </Box>
        </Modal>
      )}
      {/* new-digital-document-form-modal::end */}

      {/* edit-digital-document-form-modal::begin */}
      {isEditModalOpen && (
        <EditDigitalDocument
          documentId={currentDocumentId}
          setDocumentId={setDocumentId}
          isOpen={isEditModalOpen}
          setOpen={setEditModalStatus}
          title="Editar Documento"
          search={false}
        />
      )}
      {/* edit-digital-document-form-modal::end */}
    </Grid>
  );
}

EmployeeDocument.propTypes = {
  categoryType: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  getEmployeeDependentId: PropTypes.string,
  employeeData: PropTypes.bool,
};

EmployeeDocument.defaultProps = {
  getEmployeeDependentId: '',
  employeeData: false,
};
