import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 30px 30px 0 30px;
  min-width: 960px;
`;

export const BoxTitle = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 20px 0;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  color: #00a4e1;
  font-size: 24px;
`;

export const TitleModal = styled.h1`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`;

export const BoxSignUp = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  border: 1px solid #ccc;
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 68px;
  margin-bottom: 20px;

  @media (max-width: 425px) {
    justify-content: center;
    flex-direction: column;
  }
`;
