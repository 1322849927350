import {FormControl, Grid} from "@mui/material";
import {PropTypes} from "prop-types";
import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  muiField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      minHeight: '72px !important',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

function GridItemFormControl({ children, md, sm, xs }) {
  const classes = useStyles();

  return <Grid item md={ md } sm={sm} xs={xs} padding={1}>
    <FormControl fullWidth className={classes.muiField}>
      {children}
    </FormControl>
  </Grid>
}

GridItemFormControl.propTypes = {
  children: PropTypes.node.isRequired,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
}

GridItemFormControl.defaultProps = {
  md: 6,
  sm: 12,
  xs: 12
}

export default GridItemFormControl;