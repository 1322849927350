import { useCallback } from 'react'
import { api } from '../services/api'

export default function documentTypeClient() {
    const getDocumentType = useCallback(() => api.get('/DocumentType').then((response) => response.data), [])

    const newDocumentType = useCallback((data) => api.post('/DocumentType', data).then((response) => response), [])

    const deleteDocumentType = useCallback((id) => api.delete('/DocumentType', { params: { id } }).then((response) => response), [])

    const getByIdDocumentType = useCallback((id) => api.get(`/DocumentType/${id}`).then((response) => response.data), [])

    const editByIdDocumentType = useCallback((id, data) => api.put(`/DocumentType/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getDocumentType,
        newDocumentType,
        deleteDocumentType,
        getByIdDocumentType,
        editByIdDocumentType
    }), [
        getDocumentType,
        newDocumentType,
        deleteDocumentType,
        getByIdDocumentType,
        editByIdDocumentType
    ]
    )
}