import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { AppContext } from '../../contexts/sidebarContext';
import sectorTypeClient from '../../clients/sectorTypeClient';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
  },
  labelModal: {
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '30px',
  },
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  selectField: {
    height: '72px',
    borderRadius: '10px !important',
    '& fieldset': {
      border: '1px solid #000',
    },
  },
  placeholder: {
    color: 'rgb(118, 118, 118)',
  },
}));

const VALUES_TYPE_SECTOR = [
  {
    sectorTypeLabel: 'Orgão',
    sectorTypeValue: 'Orgao',
  },
  {
    sectorTypeLabel: 'Departamento',
    sectorTypeValue: 'Departamento',
  },
  {
    sectorTypeLabel: 'Unidade',
    sectorTypeValue: 'Unidade',
  },
  {
    sectorTypeLabel: 'Setor',
    sectorTypeValue: 'Setor',
  },
];

function EditModalUser({ openModal, setOpenModal, getId, getSectorEdit }) {
  const classes = useStyles();

  const sectorClient = sectorTypeClient();

  const [sectors, setSectors] = useState([]);
  const [typeSector, setTypeSector] = useState([]);
  const [typeSuperiorSector, setTypeSuperiorSector] = useState([]);

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const handleClose = () => setOpenModal(false);

  const handleChange = (event) => {
    setTypeSector(event.target.value);
  };

  const handleChangeSuperiorSector = (event) => {
    setTypeSuperiorSector(event.target.value);
  };

  const DEFAULT = {
    name: getSectorEdit?.name,
    description: getSectorEdit?.description,
    enabled: !getSectorEdit?.enabled,
    superiorSectorId: getSectorEdit?.superiorSectorId,
    sectorType: getSectorEdit?.sectorType,
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obrigatório'),
      description: Yup.string(),
      enabled: Yup.bool(),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        name: data.name,
        description: data.description,
        enabled: !data.enabled,
        tenantId: useTenantId,
        superiorSectorId: data?.superiorSectorId,
        sectorType: data?.sectorType,
      };

      sectorClient()
        .editSectorById(getId, payload)
        .then(
          (response) => {
            msgAlert({
              response,
              message: 'Setor atualizado com sucesso!',
            });
            handleClose();
          },
          (resp) => {
            msgAlert({
              response: resp,
            });
            handleClose();
          }
        );
    },
  });

  useEffect(() => {
    sectorClient()
      .getSector()
      .then((response) => {
        setSectors(
          response.filter((result) => result.name !== getSectorEdit.name)
        );
      });
  }, []);

  const handleSubmit = () => {
    formik.submitForm();
  };

  const theme = useTheme();

  return (
    <Modal open={openModal} onClose={handleClose} closeAfterTransition>
      <Box className={classes.modal}>
        <Paper sx={{ padding: '30px', maxWidth: '960px', minWidth: '960px' }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{
                  width: '100%',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '30px',
                  paddingTop: '20px',
                  marginBottom: '40px',
                }}
              >
                Nova Estrutura Organizacional
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="center" container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={formik.touched.name && formik.errors.name}
              >
                <TextField
                  type="text"
                  placeholder="Ex: CNH"
                  name="name"
                  label="Nome do Órgão/Unidade/Dep/Setor"
                  className={classes.textField}
                  {...formik.getFieldProps('name')}
                  error={formik.touched.name && formik.errors.name}
                />
                <FormHelperText
                  hidden={!formik.touched.name || !formik.errors.name}
                  error={formik.touched.name && !!formik.errors.name}
                >
                  {formik.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={formik.touched.description && formik.errors.description}
              >
                <TextField
                  type="text"
                  placeholder="Ex: Carteira de habilitação"
                  name="descriptionNumber"
                  label="Descrição"
                  className={classes.textField}
                  {...formik.getFieldProps('description')}
                  error={
                    formik.touched.description && formik.errors.description
                  }
                />
                <FormHelperText
                  hidden={
                    !formik.touched.description || !formik.errors.description
                  }
                  error={
                    formik.touched.description && !!formik.errors.description
                  }
                >
                  {formik.errors.description}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={
                  formik.touched.superiorSectorId &&
                  formik.errors.superiorSectorId
                }
              >
                <Select
                  fullWidth
                  variant="outlined"
                  value={typeSuperiorSector}
                  onChange={handleChangeSuperiorSector}
                  className={classes.selectField}
                  displayEmpty
                  renderValue={
                    formik.values.superiorSectorId === null
                      ? () => (
                          <div className={classes.placeholder}>
                            Superior Imediato
                          </div>
                        )
                      : undefined
                  }
                  {...formik.getFieldProps('superiorSectorId')}
                >
                  {sectors.map((sectorsData) => (
                    <MenuItem value={sectorsData?.id}>
                      {sectorsData?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  hidden={
                    !formik.touched.superiorSectorId ||
                    !formik.errors.superiorSectorId
                  }
                  error={
                    formik.touched.superiorSectorId &&
                    !!formik.errors.superiorSectorId
                  }
                >
                  {formik.errors.superiorSectorId}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Select
                fullWidth
                labelId="sectorType"
                id="sectorType"
                value={typeSector}
                displayEmpty
                renderValue={
                  formik.values.sectorType === ''
                    ? () => (
                        <div className={classes.placeholder}>
                          Estrutura administrativa
                        </div>
                      )
                    : undefined
                }
                onChange={handleChange}
                className={classes.selectField}
                {...formik.getFieldProps('sectorType')}
                error={formik.touched.sectorType && formik.errors.sectorType}
              >
                {VALUES_TYPE_SECTOR.map((type) => (
                  <MenuItem value={type.sectorTypeValue}>
                    {type.sectorTypeLabel}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.sectorType && formik.errors.sectorType && (
                <Typography
                  sx={{ color: '#d32f2f', fontSize: '12px' }}
                  color="Error"
                >
                  Selecione um valor
                </Typography>
              )}
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs>
                <FormControlLabel
                  sx={{
                    color: theme.text.darkGray,
                  }}
                  label="Desabilitar Setor"
                  control={
                    <Checkbox
                      checked={formik.values.enabled}
                      onChange={(event) => {
                        formik.setFieldValue('enabled', event.target.checked);
                      }}
                      sx={{
                        '&.Mui-checked': {
                          color: theme.background.blue,
                        },
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            flex={1}
            display="flex"
            justifyContent="center"
            my={3}
          >
            <Grid item xs={8} sm={4} md={3} lg={6}>
              <Button
                text="Cancelar"
                type="send"
                onClick={handleClose}
                width="100%"
                height="72px"
                whiteButton
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3} lg={6}>
              <Button
                text="Salvar"
                type="send"
                onClick={handleSubmit}
                width="100%"
                height="72px"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

EditModalUser.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  getId: PropTypes.string,
  getSectorEdit: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    sectorType: PropTypes.string,
    superiorSectorId: PropTypes.string,
    id: PropTypes.string,
  }),
};

EditModalUser.defaultProps = {
  openModal: false,
  getId: '',
  getSectorEdit: {},
};

export default EditModalUser;
