import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { Box, Modal, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(100% - 50px)`,
    width: `calc(100% - 10px)`,
  },
  paper: {
    width: '66%',
    maxHeight: '100%',
    position: 'relative',
    padding: '10px',
    maxWidth: '580px',
  },

  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '16px',
  },
}));

function ModalMensage({ children, open, onClose, close, ...props }) {
  const classes = useStyles();
  return (
    <Modal
      closeAfterTransition
      open={open}
      {...props}
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '-4%',
      }}
    >
      <Box className={classes.modal}>
        <Paper className={classes.paper}>
          {close && (
            <Close title="Fechar" className={classes.close} onClick={onClose} />
          )}
          {children}
        </Paper>
      </Box>
    </Modal>
  );
}

ModalMensage.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

ModalMensage.defaultProps = {
  open: false,
  close: true,
  onClose: () => {},
};

export default ModalMensage;
