/**
 * @param { Blob } file
 * @param {{ [k: string]: undefined | null | string} } template
 * @returns {Promise<string | ArrayBuffer>}
 */
export const getBase64File = (file, template) =>
  new Promise((resolve, reject) => {
    const getObjKey = index => Object.keys(template)[index];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const { result } = fileReader;
      resolve({
        [getObjKey(0)]: file.name,
        [getObjKey(1)]: result.substring(result.indexOf('base64,') + 7)
      });
    };

    fileReader.onerror = (error) => {
      reject(error);
    }
  })


export const getFileFromBase64 = (base64File, fileName, format = 'application/pdf') =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const list = new DataTransfer();
      const file = new File([reader.result], fileName);
      list.items.add(file);
      resolve(list.files);
    });

    const byteChars = atob(base64File);
    const byteNumbers = [];
    for (let i = 0; i < byteChars.length; i += 1) {
      byteNumbers[i] = byteChars.charCodeAt(i)
    }

    const file = new Blob([
      new Uint8Array(byteNumbers)
    ], { type: format });


    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
  })