/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import AlertConfirmation from '../components/ModalConfirmation';

export const AppContext = createContext();

export function SharedSidebarContext({ children }) {
  const [sharedSidebarState, setSharedSidebarState] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalClose, setModalClose] = useState(true);
  const [response, setResponse] = useState();
  const [list, setList] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseHelp, setOpenCollapseHelp] = useState(false);
  const [openCollapseDoc, setOpenCollapseDoc] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [payloadSearch, setPayloadSearch] = useState({});

  const [digitalDocumentFilter, setDigitalDocumentFilter] = useState({});

  return (
    <AppContext.Provider
      value={{
        openCollapse,
        setOpenCollapse,
        openCollapseHelp,
        setOpenCollapseHelp,
        openCollapseDoc,
        setOpenCollapseDoc,
        sharedSidebarState,
        setSharedSidebarState,
        modalStatus,
        setModalStatus,
        msgAlert: setResponse,
        response,
        setModalClose,
        modalClose,
        filteredList: list,
        filteringList: setList,
        payloadSearch,
        setPayloadSearch,
        digitalDocumentFilter,
        setDigitalDocumentFilter,
        selectedRows,
        setSelectedRows,
      }}
    >
      {response && <AlertConfirmation resp={response} />}
      {children}
    </AppContext.Provider>
  );
}

SharedSidebarContext.propTypes = {
  children: PropTypes.node.isRequired,
};
