import React, { useContext, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

import userClient from '../../clients/userClient';
import ModalMensage from '../ModalMensage';
import Button from '../Button';
import { AppContext } from '../../contexts/sidebarContext';

// const useStyles = makeStyles(() => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     height: '100%',
//     width: '100%',
//     padding: '20px',
//   },
//   labelModal: {
//     color: '#00A4E1',
//     fontSize: '24px',
//     fontFamily: 'Montserrat',
//   },
// }));

function DeleteModalConfirmation({ getId, open, setOpen, getUser }) {
  // const classes = useStyles();
  const useClient = userClient();

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setOpen(false);

  const handleConfirmation = () => {
    setIsLoading(true);
    useClient()
      .deleteUser(getId)
      .then(
        (response) => {
          msgAlert({
            response,
            message: 'Usuário deletado com sucesso!',
          });
          setIsLoading(false);
          handleClose();
        },
        (response) => {
          msgAlert({
            response,
          });
          setIsLoading(false);
          handleClose();
        }
      );
  };

  return (
    <ModalMensage close={!isLoading} open={open} onClose={() => setOpen(false)}>
      <Box p={2} alignItems="center" justifyContent="center">
        <Box>
          <Box mt={6} mb={2}>
            <Typography
              mt={3}
              mb={2}
              align="center"
              sx={{
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '30px',
              }}
            >
              Deseja deletar o usuário
            </Typography>
          </Box>

          <Box mb={7}>
            <Typography
              align="center"
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '28px',
              }}
            >
              {`"${getUser?.fullName}"`}
            </Typography>
          </Box>
          <Grid
            px={5}
            mb={4}
            container
            spacing={2}
            align="center"
            justifyContent="center"
          >
            <Grid item xs={6}>
              <Button
                onClick={() => setOpen(false)}
                text="Cancelar"
                type="send"
                width="100%"
                height="72px"
                whiteButton
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleConfirmation}
                text="Confirmar"
                type="send"
                width="100%"
                height="72px"
                deleteButton
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalMensage>
  );
}

DeleteModalConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  getId: PropTypes.string,
  getUser: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    administrator: PropTypes.bool,
    enabled: PropTypes.bool,
  }),
};

DeleteModalConfirmation.defaultProps = {
  open: false,
  getId: '',
  getUser: {},
};

export default DeleteModalConfirmation;
