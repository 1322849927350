import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  DocumentScannerOutlined,
  ExpandLess,
  ExpandMore,
  HomeOutlined,
  LiveHelpOutlined,
  NoteAltOutlined,
} from '@mui/icons-material';
import {
  Box,
  Typography,
  Paper,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import Logo from '../../assets/img/header/ePessoal.png';
import * as Styled from './styled';
import { AppContext } from '../../contexts/sidebarContext';
import ChangePasswordModalConfirmation from '../Header/ChangePasswordModalConfirmation';

function Sidebar() {
  const {
    setSharedSidebarState,
    openCollapse,
    setOpenCollapse,
    openCollapseHelp,
    setOpenCollapseHelp,
    openCollapseDoc,
    setOpenCollapseDoc,
  } = useContext(AppContext);
  const getRoleUser = localStorage.getItem('role');
  const [colorCliente, setColorCliente] = useState('#FFFFFF');
  const [colorNovo, setColorNovo] = useState('#FFFFFF');
  const [colorEstrutura, setColorEstrutura] = useState('#FFFFFF');
  const [colorTipoDocumento, setColorTipoDocumento] = useState('#FFFFFF');
  const [colorTipoAdmissao, setColorTipoAdmissao] = useState('#FFFFFF');
  const [colorCargos, setColorCargos] = useState('#FFFFFF');
  const [colorColaboradores, setColorColaboradores] = useState('#FFFFFF');
  const [colorAjuda, setColorAjuda] = useState('#FFFFFF');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSharedSidebarState(true);
  }, []);

  return (
    <>
      <Paper
        sx={{
          '@media (max-width: 1366px)': {
            width: '270px',
          },
          backgroundColor: '#107FAA',
          width: '357px',
          position: 'sticky',
          minHeight: '100vh',
          maxHeight: '100vh',
          height: '100%',
          top: 0,
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: '#107FAA',
            minHeight: '100vh',
            maxHeight: '100vh',
            height: '100%',
          }}
        >
          <Styled.Logo src={Logo} />
          <Styled.DropDownList sx={{ gap: 'none' }}>
            <Box
              sx={{
                paddingLeft: '15px',
                paddingRight: '28px',
                '@media (max-width: 1366px)': {
                  paddingLeft: '15px',
                  paddingRight: '15px',
                },
              }}
            >
              <Link to="/home" style={{ textDecoration: 'none' }}>
                <Styled.ListItem
                  onMouseOver={() => {
                    setColorCliente('#FFFFFF');
                  }}
                  onMouseOut={() => {
                    setColorCliente('#FFFFFF');
                  }}
                >
                  <ListItemIcon>
                    <HomeOutlined sx={{ color: '#fff' }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '18px',
                      fontWeight: 700,
                      marginLeft: '-28px',
                      color: '#fff',
                      '@media (max-width: 1366px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Início
                  </Typography>
                </Styled.ListItem>
              </Link>
            </Box>
          </Styled.DropDownList>

          <Styled.DropDownList>
            <ListItem onClick={() => setOpenCollapse(!openCollapse)}>
              <ListItemIcon>
                <NoteAltOutlined sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#fff',
                    '@media (max-width: 1366px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Cadastro
                </Typography>
              </ListItemText>
              {openCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  width: '100%',
                  borderTop: '1px solid #fff !important',
                  paddingTop: '20px',
                  borderLeft: '50px',
                }}
              >
                {getRoleUser === 'AdministradorSistema' && (
                  <>
                    <Link to="/new-clients" style={{ textDecoration: 'none' }}>
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorCliente('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorCliente('#FFFFFF');
                        }}
                      >
                        <PersonAddAltOutlinedIcon
                          sx={{ color: colorCliente, paddingLeft: '25px' }}
                          fontSize="small"
                        />
                        Novo cliente
                      </Styled.ListItem>
                    </Link>
                    <Link to="/new-user" style={{ textDecoration: 'none' }}>
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorNovo('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorNovo('#FFFFFF');
                        }}
                      >
                        <PersonAddAltOutlinedIcon
                          sx={{ color: colorNovo, paddingLeft: '25px' }}
                          fontSize="small"
                        />
                        Novo usuário
                      </Styled.ListItem>
                    </Link>
                  </>
                )}
                {getRoleUser === 'AdmnistradorTenant' && (
                  <Link to="/new-user" style={{ textDecoration: 'none' }}>
                    <Styled.ListItem
                      onMouseOver={() => {
                        setColorNovo('#FFFFFF');
                      }}
                      onMouseOut={() => {
                        setColorNovo('#FFFFFF');
                      }}
                    >
                      <PersonAddAltOutlinedIcon
                        sx={{ color: colorNovo, paddingLeft: '25px' }}
                        fontSize="small"
                      />
                      Novo usuário
                    </Styled.ListItem>
                  </Link>
                )}
                {(getRoleUser === 'AdmnistradorTenant' ||
                  getRoleUser === 'UsuarioSistema' ||
                  getRoleUser === 'UsuarioTenant') && (
                  <>
                    <Link
                      to="/sector-management"
                      style={{ textDecoration: 'none' }}
                    >
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorEstrutura('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorEstrutura('#FFFFFF');
                        }}
                      >
                        <ApartmentOutlinedIcon
                          sx={{ color: colorEstrutura, paddingLeft: '25px' }}
                          fontSize="small"
                        />
                        Estrutura Organizacional
                      </Styled.ListItem>
                    </Link>
                    <Link
                      to="/document-type"
                      style={{ textDecoration: 'none' }}
                    >
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorTipoDocumento('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorTipoDocumento('#FFFFFF');
                        }}
                      >
                        <AssignmentIndOutlinedIcon
                          sx={{
                            color: colorTipoDocumento,
                            paddingLeft: '25px',
                          }}
                          fontSize="small"
                        />
                        Tipos de Documento
                      </Styled.ListItem>
                    </Link>
                    <Link
                      to="/admission-type"
                      style={{ textDecoration: 'none' }}
                    >
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorTipoAdmissao('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorTipoAdmissao('#FFFFFF');
                        }}
                      >
                        <DifferenceOutlinedIcon
                          sx={{ color: colorTipoAdmissao, paddingLeft: '25px' }}
                          fontSize="small"
                        />
                        Tipos de Admissão
                      </Styled.ListItem>
                    </Link>
                    <Link to="/office-type" style={{ textDecoration: 'none' }}>
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorCargos('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorCargos('#FFFFFF');
                        }}
                      >
                        <WorkOutlineOutlinedIcon
                          sx={{ color: colorCargos, paddingLeft: '25px' }}
                          fontSize="small"
                        />
                        Cargos
                      </Styled.ListItem>
                    </Link>
                    <Link
                      to="/employees-management"
                      style={{ textDecoration: 'none' }}
                    >
                      <Styled.ListItem
                        onMouseOver={() => {
                          setColorColaboradores('#FFFFFF');
                        }}
                        onMouseOut={() => {
                          setColorColaboradores('#FFFFFF');
                        }}
                      >
                        <FolderSharedOutlinedIcon
                          sx={{
                            color: colorColaboradores,
                            paddingLeft: '25px',
                          }}
                          fontSize="small"
                        />
                        Colaboradores
                      </Styled.ListItem>
                    </Link>
                  </>
                )}
              </Box>
            </Collapse>
          </Styled.DropDownList>
          <Styled.DropDownList>
            <ListItem onClick={() => setOpenCollapseDoc(!openCollapseDoc)}>
              <ListItemIcon>
                <AssignmentIndOutlinedIcon sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#fff',
                    '@media (max-width: 1366px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Documentos Digitais
                </Typography>
              </ListItemText>
              {openCollapseDoc ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openCollapseDoc} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  width: '100%',
                  borderTop: '1px solid #fff !important',
                  paddingTop: '20px',
                  borderLeft: '50px',
                }}
              >
                <Link
                  to="/digital-documents"
                  style={{ textDecoration: 'none' }}
                >
                  <Styled.ListItem
                    onMouseOver={() => {
                      setColorAjuda('#FFFFFF');
                    }}
                    onMouseOut={() => {
                      setColorAjuda('#FFFFFF');
                    }}
                  >
                    <DocumentScannerOutlined
                      sx={{ color: colorAjuda, paddingLeft: '25px' }}
                      fontSize="small"
                    />
                    Documentos Digitais
                  </Styled.ListItem>
                </Link>
              </Box>
            </Collapse>
          </Styled.DropDownList>
          <Styled.DropDownList>
            <ListItem onClick={() => setOpenCollapseHelp(!openCollapseHelp)}>
              <ListItemIcon>
                <LiveHelpOutlined sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#fff',
                    '@media (max-width: 1366px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Ajuda
                </Typography>
              </ListItemText>
              {openCollapseHelp ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openCollapseHelp} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  width: '100%',
                  borderTop: '1px solid #fff !important',
                  paddingTop: '20px',
                  borderLeft: '50px',
                }}
              >
                <Link to="/help" style={{ textDecoration: 'none' }}>
                  <Styled.ListItem
                    onMouseOver={() => {
                      setColorAjuda('#FFFFFF');
                    }}
                    onMouseOut={() => {
                      setColorAjuda('#FFFFFF');
                    }}
                  >
                    <HelpOutlineIcon
                      sx={{ color: colorAjuda, paddingLeft: '25px' }}
                      fontSize="small"
                    />
                    Ajuda
                  </Styled.ListItem>
                </Link>
              </Box>
            </Collapse>
          </Styled.DropDownList>
        </Box>
      </Paper>

      <ChangePasswordModalConfirmation open={open} setOpen={setOpen} />
    </>
  );
}

export default Sidebar;
