import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@mui/styles';
// import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Modal,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material';

import PropTypes from 'prop-types';

import moment from 'moment';

import * as Styled from './styled';

import EmployeeDependentDocumentClient from '../../clients/employeeDependentDoc';
import S3DownloadClient from '../../clients/s3DownloadClient';
import EditGeneralDocModal from './EditGeneralDocModal';
import DeleteEmployeeModal from './DeleteEmployeeModal';
import { useEmployContext } from './EmployContext';
import digitalDocumentClient from '../../clients/digitalDocumentClient';
import { documentTypeCategoriesToAPIType } from '../../helpers/constants';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
  },
  labelModal: {
    color: '#00A4E1',
    fontSize: '24px',
    fontFamily: 'Montserrat',
  },
}));

export default function DependentDocModal({
  openModalDoc,
  setOpenModalDoc,
  getId,
  dependentName,
}) {
  const classes = useStyles();

  const useEmployeeDependentDocumentClient = EmployeeDependentDocumentClient();
  const digitalDocuments = digitalDocumentClient();

  const { employeeMsgAlert } = useEmployContext();

  const useS3DownloadClient = S3DownloadClient();

  // const [getIdForDelete, setGetIdForDelete] = useState('');
  // const [documentEdit, setDocumentEdit] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dependentDocument, setdependentDocument] = useState([]);
  const [onRefresh, setOnRefresh] = useState(false);

  const deleteEndpointClient = (id) => {
    useEmployeeDependentDocumentClient()
      .deleteDocumentType(id)
      .then(
        (response) => {
          employeeMsgAlert({
            response,
            message: 'Documento deletado com sucesso!',
          });
          setOnRefresh(true);
          setOpenDeleteModal(false);
        },
        (response) => {
          employeeMsgAlert({
            response,
          });
        }
      );
  };

  const editEndpoint = (id, payload) => {
    useEmployeeDependentDocumentClient()
      .editByIdEmployeeDependentDocument(id, payload)
      .then(
        (response) => {
          employeeMsgAlert({
            response,
            message: 'Documento editado com sucesso!',
          });
          setOnRefresh(true);
          setOpenEditModal(false);
        },
        (resp) => {
          employeeMsgAlert({
            response: resp,
          });
        }
      );
  };

  useEffect(() => {
    setLoadingProgress(true);
    digitalDocuments()
      .searchDigitalDocuments({
        rowsPerPage: 50,
        documentTypeCategory:
          documentTypeCategoriesToAPIType.DocumentosPessoais,
        ...(getId ? { employeeDependentId: getId } : {}),
      })
      .then(
        ({ data }) => {
          setLoadingProgress(false);
          setdependentDocument(data);
        },
        () => {
          setLoadingProgress(false);
        }
      );

    // setLoadingProgress(true);
    // useEmployeeDependentDocumentClient()
    //   .getDocumentByDependentId(getId)
    //   .then(
    //     (response) => {
    //       setLoadingProgress(false);
    //       setdependentDocument(response);
    //     },
    //     () => {
    //       setLoadingProgress(false);
    //     }
    //   );

    setOnRefresh(false);
  }, [setdependentDocument, onRefresh]);

  const handleClose = () => {
    setOpenModalDoc(false);
  };

  // const handleEdit = (data) => {
  //   setOpenEditModal(true);
  //   setDocumentEdit(data);
  // };

  // const handleDelete = (data) => {
  //   setOpenDeleteModal(true);
  //   setGetIdForDelete(data);
  // };

  const handleDownloadDocuments = (data, name) => {
    useS3DownloadClient()
      .downloadDocument(data)
      .then((response) => {
        const linkSource = `data:application/pdf;base64,${response?.fileBase64Payload}`;
        const downloadLink = document.createElement('a');
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  };

  const formatDate = useCallback(
    (date) => date && moment(date).format('DD/MM/YYYY'),
    []
  );

  return (
    <>
      <Modal open={openModalDoc} onClose={handleClose} closeAfterTransition>
        <Box className={classes.modal}>
          <Paper sx={{ padding: '20px' }}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item>
                <Typography className={classes.labelModal}>
                  Documentos de {dependentName}
                </Typography>
              </Grid>
              <Grid item>
                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
              </Grid>
            </Grid>
            <Grid mb={2} container>
              <Grid item sm={12}>
                <Styled.Container>
                  <Styled.Table>
                    <Styled.TableHead>
                      <Styled.TableRow>
                        <Styled.HeadCells>Tipo do Documento</Styled.HeadCells>
                        <Styled.HeadCells>Nº</Styled.HeadCells>
                        <Styled.HeadCells>Ano</Styled.HeadCells>
                        <Styled.HeadCells>Data do upload</Styled.HeadCells>
                        {/* <Styled.HeadCells>Editar</Styled.HeadCells> */}
                        <Styled.HeadCells>Download</Styled.HeadCells>
                        {/* <Styled.HeadCells>Excluir</Styled.HeadCells> */}
                      </Styled.TableRow>
                    </Styled.TableHead>

                    <Styled.TableBody>
                      {dependentDocument.map((docs) => (
                        <Styled.TableRow key={docs?.id}>
                          <Styled.TableDataCells data-label="Nome">
                            {docs?.documentName}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Número">
                            {docs?.documentNumber}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Ano do Documento">
                            {docs?.documentYear}
                          </Styled.TableDataCells>
                          <Styled.TableDataCells data-label="Data do Upload">
                            {formatDate(docs?.documentDate)}
                          </Styled.TableDataCells>
                          {/* <Styled.TableDataCells data-label="Editar">
                            <Box>
                              <EditIcon
                                onClick={() => {
                                  handleEdit(docs);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '30px' }}
                              />
                            </Box>
                          </Styled.TableDataCells> */}
                          <Styled.TableDataCells data-label="Download">
                            <Box>
                              <DownloadIcon
                                onClick={() => {
                                  handleDownloadDocuments(
                                    docs?.storageFilePath,
                                    docs?.fileName
                                  );
                                }}
                                sx={{ cursor: 'pointer', fontSize: '30px' }}
                              />
                            </Box>
                          </Styled.TableDataCells>
                          {/* <Styled.TableDataCells data-label="Excluir">
                            <Box>
                              <DeleteForeverIcon
                                onClick={() => {
                                  handleDelete(docs?.id);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '30px' }}
                              />
                            </Box>
                          </Styled.TableDataCells> */}
                        </Styled.TableRow>
                      ))}
                    </Styled.TableBody>
                  </Styled.Table>

                  {loadingProgress && (
                    <Styled.BoxFooter>
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="indeterminate" />
                      </Box>
                    </Styled.BoxFooter>
                  )}
                </Styled.Container>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      {openDeleteModal && (
        <DeleteEmployeeModal
          open={openDeleteModal}
          // getId={getIdForDelete}
          setOpen={setOpenDeleteModal}
          deleteEndpoint={deleteEndpointClient}
        />
      )}

      {openEditModal && (
        <EditGeneralDocModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          editEndpoint={editEndpoint}
          // documentEdit={documentEdit}
          generalDocumentTypes="Dependent"
        />
      )}
    </>
  );
}

DependentDocModal.propTypes = {
  dependentName: PropTypes.string,
  getId: PropTypes.string,
  openModalDoc: PropTypes.bool,
  setOpenModalDoc: PropTypes.func.isRequired,
};

DependentDocModal.defaultProps = {
  getId: '',
  openModalDoc: false,
  dependentName: '',
};
