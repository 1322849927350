import { Button, Grid } from '@mui/material';
import { CleaningServicesOutlined } from '@mui/icons-material';
import React from 'react';
import ButtonS from '../Button';
import * as Styled from './styled';

// eslint-disable-next-line react/prop-types
export default function FilterBox({ children, onSubmit, onReset }) {
  return (
    <Styled.BoxSearch>
      <Styled.Container>
        <Styled.BoxSignUp>
          <Styled.InputAreaWrapper>
            <Styled.FirstRow>
              <Grid container direction="column" padding={2} spacing={2}>
                <Grid
                  container
                  item
                  direction="row"
                  spacing={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {children}
                </Grid>
              </Grid>
            </Styled.FirstRow>

            {/* buttons::begin */}
            <Grid container spacing={2} display="flex" justifyContent="center">
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                container
                item
              >
                <Button onClick={onReset} variant="outlined">
                  <CleaningServicesOutlined sx={{ fontSize: '25px' }} />
                </Button>
              </Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                container
                item
              >
                <ButtonS
                  text="Pesquisar"
                  type="send"
                  width="100%"
                  height="72px"
                  onClick={onSubmit}
                />
              </Grid>
            </Grid>
            {/* buttons::end */}
          </Styled.InputAreaWrapper>
        </Styled.BoxSignUp>
      </Styled.Container>
    </Styled.BoxSearch>
  );
}
