import React, { useContext, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputBase-root': {
      height: '45px',
      '& fieldset': {
        border: '1px solid #107FAA',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

export default function SearchInput({ list, searchFieldName }) {
  const classes = useStyles();
  const [value, setValue] = useState();

  const context = useContext(AppContext);

  const { filteringList } = context;

  const mergeDedupe = (arr) => [...new Set([].concat(...arr))];
  useEffect(() => {
    filteringList(() => {
      if (value) {
        const filteredItems = list.filter((item) =>
          searchFieldName.some((term) =>
            String(item[term]).toLowerCase().includes(value.toLowerCase())
          )
        );
        return mergeDedupe(filteredItems);
      }
      return list;
    });
  }, [value]);

  return (
    <TextField
      fullWidth
      className={classes.textField}
      hiddenLabel
      type="text"
      placeholder="Pesquisar"
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

SearchInput.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchFieldName: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SearchInput.defaultProps = {};
