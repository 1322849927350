import { createContext, useContext } from "react"

const EmployContext = createContext()


export function useEmployContext() {
  const {
    tabValue,
    employId,
    setTabValue,
    setEmployId,
    employCurrentData,
    getEmployeeDependentId,
    setEmployCurrentData,
    showRegisterDependent,
    setGetEmployeeDependentId,
    setShowRegisterDependent,
    responseConfirmation,
    employeeMsgAlert: setResponseConfirmation,
  } = useContext(EmployContext) ?? {}

  return {
    tabValue,
    employId,
    setTabValue,
    setEmployId,
    employCurrentData,
    getEmployeeDependentId,
    setEmployCurrentData,
    showRegisterDependent,
    setGetEmployeeDependentId,
    setShowRegisterDependent,
    responseConfirmation,
    employeeMsgAlert: setResponseConfirmation,
  }
}

export default EmployContext