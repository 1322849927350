import React, { useCallback, useState, useEffect } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

import moment from 'moment';

import { useEmployContext } from './EmployContext';
import EmployeeDependentClient from '../../clients/employeeDependentClient';
import EmployeeDependentDocumentClient from '../../clients/employeeDependentDoc';
// import DependentDocs from './DependentDocs';
import EmployeeDocument from './EmployeeDocument';

const useStyles = makeStyles(() => ({
  buttonModal: {
    fontSize: '20px',
  },
  labelTab: {
    fontSize: '20px',
    fontWeight: 700,
  },
  placeholder: {
    color: 'rgb(192, 192, 192)',
    font: 'inherit',
    letterSpacing: 'inherit',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
  },
}));

const RACE_TYPE = [
  {
    label: 'Branca',
    value: 'Branca',
  },
  {
    label: 'Parda',
    value: 'Parda',
  },
  {
    label: 'Preta',
    value: 'Preta',
  },
  {
    label: 'Amarela',
    value: 'Amarela',
  },
  {
    label: 'Indígena',
    value: 'Indigena',
  },
];

const GENDER_TYPE = [
  {
    gender: 'Feminino',
  },
  {
    gender: 'Masculino',
  },
  {
    gender: 'Outro',
  },
];

const RELATIONSHIP_TYPE = [
  {
    label: 'Pai',
    value: 'Pai',
  },
  {
    label: 'Mãe',
    value: 'Mae',
  },
  {
    label: 'Filho(a)',
    value: 'Filho',
  },
  {
    label: 'Cônjuge',
    value: 'Conjuge',
  },
  {
    label: 'Avô(ó)',
    value: 'Avo',
  },
  {
    label: 'Enteado(a)',
    value: 'Enteado',
  },
  {
    label: 'Outro',
    value: 'Outro',
  },
];

const HAS_DISABILITY = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const EDUCATION_LEVEL_TYPE = [
  {
    value: 'Analfabeto',
    label: 'Analfabeto',
  },
  {
    value: 'Alfabetizado',
    label: 'Alfabetizado',
  },
  {
    value: 'EnsinoFundamentalIncompleto',
    label: 'Ensino Fundamental Incompleto',
  },
  {
    value: 'EnsinoFundamentalCompleto',
    label: 'Ensino Fundamental Completo',
  },
  {
    value: 'EnsinoMedioIncompleto',
    label: 'Ensino Médio Incompleto',
  },
  {
    value: 'EnsinoMedioCompleto',
    label: 'Ensino Médio Completo',
  },
  {
    value: 'EnsinoTecnico',
    label: 'Ensino Técnico',
  },
  {
    value: 'EnsinoSuperiorIncompleto',
    label: 'Ensino Superior Incompleto',
  },
  {
    value: 'EnsinoSuperiorCompleto',
    label: 'Ensino Superior Completo',
  },
  {
    value: 'PosGraduado',
    label: 'Pós-Graduado',
  },
  {
    value: 'Mestrado',
    label: 'Mestrado',
  },
  {
    value: 'Doutorado',
    label: 'Doutorado',
  },
];

const MARITIAL_STATUS_TYPE = [
  {
    value: 'Solteiro',
    label: 'Solteiro',
  },
  {
    value: 'Casado',
    label: 'Casado',
  },
  {
    value: 'Divorciado',
    label: 'Divorciado',
  },
  {
    value: 'Viuvo',
    label: 'Viúvo',
  },
  {
    value: 'UniaoEstavel',
    label: 'União Estável',
  },
];

export default function DependetEmployeeDataRegister({
  setOnRefresh,
  dependentCurrentValue,
}) {
  const classes = useStyles();

  const useEmployeeDependentClient = EmployeeDependentClient();
  const useEmployeeDependentDocumentClient = EmployeeDependentDocumentClient();

  const [loading, setLoading] = useState(false);
  const [validateDate, setValidateDate] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [uploadDependent, setUploadDependent] = useState('');
  const [openNewDocument, setOpenNewDocument] = useState(false);
  const [uploadNameDependent, setUploadNameDependent] = useState('');
  const [uploadDateDependent, setUploadDateDependent] = useState('');
  const [uploadLabelDependent, setUploadLabelDependent] = useState('');
  // const [newDocumentRefresh, setNewDocumentRefresh] = useState(false);
  const [errorMsg, setError] = useState(false);

  const {
    employId,
    employeeMsgAlert,
    setShowRegisterDependent,
    setGetEmployeeDependentId,
    getEmployeeDependentId,
  } = useEmployContext();

  const formatDate = useCallback(
    (date) => date && moment(date).format('YYYY-MM-DD'),
    []
  );

  const handleClose = () => setShowRegisterDependent(false);

  const handleCloseNewDocument = () => {
    setOpenNewDocument(false);
  };

  const handleUpload = (id) => {
    if (uploadDependent !== '') {
      setLoadingUpload(true);
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      let payload = {};

      if (getEmployeeDependentId !== '') {
        payload = {
          employeeDependentId: getEmployeeDependentId ?? id,
          fileName: uploadNameDependent,
          fileBase64Payload: uploadDependent,
          description: uploadLabelDependent,
          date: uploadDateDependent,
          employeeId: employId,
          tenantId: useTenantId,
        };
      } else {
        payload = {
          employeeDependentId: id,
          fileName: uploadNameDependent,
          fileBase64Payload: uploadDependent,
          description: uploadLabelDependent,
          date: uploadDateDependent,
          employeeId: employId,
          tenantId: useTenantId,
        };
      }

      useEmployeeDependentDocumentClient()
        .newEmployeeDependentDocument(payload)
        .then(
          (response) => {
            employeeMsgAlert({
              response,
              message: 'Documento cadastrado com sucesso!',
            });
            setLoadingUpload(false);
            setUploadNameDependent('');
            setUploadDependent('');
            setUploadLabelDependent('');
            setUploadDateDependent('');
            handleCloseNewDocument();
            // setNewDocumentRefresh(true);
          },
          (response) => {
            employeeMsgAlert({
              response,
            });
            setLoadingUpload(false);
            handleCloseNewDocument();
          }
        );
    } else {
      setError(true);
    }
  };

  const DEFAULT = {
    status: dependentCurrentValue?.status ?? 'Ativo',
    relationshipType: dependentCurrentValue?.relationshipType ?? '',
    name: dependentCurrentValue?.name ?? '',
    cpf: dependentCurrentValue?.cpf ?? '',
    nationalIdNumber: dependentCurrentValue?.nationalIdNumber ?? '',
    nationalIdIssuer: dependentCurrentValue?.nationalIdIssuer ?? '',
    nationalIdIssuedDate:
      formatDate(dependentCurrentValue?.nationalIdIssuedDate) ?? '',
    electorIdNumber: dependentCurrentValue?.electorIdNumber ?? null,
    electorIdSeries: dependentCurrentValue?.electorIdSeries ?? null,
    electorIdState: dependentCurrentValue?.electorIdState ?? null,
    electorIdIssuedDate:
      formatDate(dependentCurrentValue?.electorIdIssuedDate) ?? '',
    birthCertificate: dependentCurrentValue?.birthCertificate ?? '',
    gender: dependentCurrentValue?.gender ?? '',
    hasDisability: dependentCurrentValue?.hasDisability ?? '',
    birthDate: formatDate(dependentCurrentValue?.birthDate) ?? '',
    birthPlace: dependentCurrentValue?.birthPlace ?? '',
    nationality: dependentCurrentValue?.nationality ?? '',
    race: dependentCurrentValue?.race ?? '',
    educationLevel: dependentCurrentValue?.educationLevel ?? '',
    maritialStatus: dependentCurrentValue?.maritialStatus ?? '',
    fatherName: dependentCurrentValue?.fatherName ?? '',
    motherName: dependentCurrentValue?.motherName ?? '',
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      relationshipType: Yup.string().required('Campo obrigatório'),
      name: Yup.string().required('Campo obrigatório'),
      cpf: Yup.string().required('Campo obrigatório'),
      nationalIdNumber: Yup.string().required('Campo obrigatório'),
      nationalIdIssuer: Yup.string().required('Campo obrigatório'),
      nationalIdIssuedDate: Yup.date()
        .nullable()
        .required('Insira uma data válida')
        .test(
          'not-in-future',
          'A data de emissão não pode estar no futuro',
          (value) => {
            const today = moment().startOf('day');
            const dateValue = moment(value);
            return dateValue.isValid() && dateValue.isSameOrBefore(today);
          }
        ),
      electorIdNumber: Yup.string().nullable(),
      electorIdSeries: Yup.string().nullable(),
      electorIdState: Yup.string().nullable(),
      electorIdIssuedDate: Yup.date()
        .nullable()
        .test('valid-date', 'Insira uma data válida', (value) => {
          if (!value) {
            return true;
          }
          const dateValue = moment(value);
          return dateValue.isValid();
        })
        .test(
          'not-in-future',
          'A data de emissão não pode estar no futuro',
          (value) => {
            if (!value) {
              return true;
            }
            const today = moment().startOf('day');
            const dateValue = moment(value);
            return dateValue.isSameOrBefore(today);
          }
        ),
      birthCertificate: Yup.string().required('Campo obrigatório'),
      gender: Yup.string().required('Campo obrigatório'),
      hasDisability: Yup.bool().required('Campo obrigatório'),
      birthDate: Yup.date()
        .nullable()
        .required('Insira uma data válida')
        .test(
          'not-in-future',
          'A data de emissão não pode estar no futuro',
          (value) => {
            const today = moment().startOf('day');
            const dateValue = moment(value);
            return dateValue.isValid() && dateValue.isSameOrBefore(today);
          }
        ),
      birthPlace: Yup.string().required('Campo obrigatório'),
      nationality: Yup.string().required('Campo obrigatório'),
      race: Yup.string().required('Campo obrigatório'),
      educationLevel: Yup.string().required('Campo obrigatório'),
      maritialStatus: Yup.string().required('Campo obrigatório'),
      fatherName: Yup.string(),
      motherName: Yup.string().required('Campo obrigatório'),
    }),
    onSubmit: async (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        ...data,
        tenantId: useTenantId,
        employeeId: employId,
      };

      if (validateDate) {
        if (getEmployeeDependentId) {
          setLoading(true);
          useEmployeeDependentClient()
            .editByIdEmployeeDependent(getEmployeeDependentId, payload)
            .then(
              (response) => {
                employeeMsgAlert({
                  response,
                  message: 'Dependente atualizado com sucesso!',
                });
                setLoading(false);
                setOnRefresh(true);
                handleClose();
              },
              (resp) => {
                employeeMsgAlert({
                  response: resp,
                });
                setLoading(false);
              }
            );
        } else {
          setLoading(true);
          useEmployeeDependentClient()
            .newEmployeeDependent(payload)
            .then(
              (response) => {
                setGetEmployeeDependentId(response?.data?.id);
                setLoading(false);
                setOnRefresh(true);
                employeeMsgAlert({
                  response,
                  message: 'Dependente cadastrado com sucesso!',
                });
              },
              (resp) => {
                employeeMsgAlert({
                  response: resp,
                });
                setLoading(false);
              }
            );
        }
      }
    },
  });

  useEffect(() => {
    if (
      formik.values.electorIdIssuedDate === '' ||
      formik.values.electorIdNumber === '' ||
      formik.values.electorIdSeries === '' ||
      formik.values.electorIdState === ''
    ) {
      formik.setFieldValue('electorIdIssuedDate', null);
      formik.setFieldValue('electorIdNumber', null);
      formik.setFieldValue('electorIdSeries', null);
      formik.setFieldValue('electorIdState', null);
    }
  }, [
    formik.values.electorIdIssuedDate,
    formik.values.electorIdNumber,
    formik.values.electorIdSeries,
    formik.values.electorIdState,
  ]);

  // setSaveDataEmployDependent(formik.values);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setDocumentName(typeof value === 'string' ? value.split(',') : value);
  // };

  const handleChangeDisability = (event) => {
    formik.setFieldValue('hasDisability', event?.target?.value);
  };

  const handleChangeRelationship = (event) => {
    formik.setFieldValue('relationshipType', event?.target?.value);
  };

  const handleChangeGender = (event) => {
    formik.setFieldValue('gender', event?.target?.value);
  };

  const handleChangeMaritialStatus = (event) => {
    formik.setFieldValue('maritialStatus', event?.target?.value);
  };

  const handleChangeRace = (event) => {
    formik.setFieldValue('race', event?.target?.value);
  };

  const formatCpf = (value) => {
    if (value !== '' && value !== null && value !== undefined) {
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return undefined;
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    setShowRegisterDependent(false);
  };

  const handleChangeEducationLevel = (event) => {
    formik.setFieldValue('educationLevel', event?.target?.value);
  };

  const handleUpLoadDescription = (data) => {
    setUploadLabelDependent(data);
  };

  const handleUpLoadName = (data) => {
    setUploadNameDependent(data);
  };

  const handleUpLoadDateDoc = (data) => {
    setUploadDateDependent(data);
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const uploadDoc = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const base64 = await convertBase64(file);
      const base64Payload = base64.substring(base64.indexOf('base64,') + 7);
      setUploadDependent(base64Payload);
    },
    [uploadDependent]
  );

  // const openNewDocumentModal = () => {
  //   setOpenNewDocument(true);
  // };

  const validateDateNationalIdIssuedDate = () => {
    if (
      formatDate(formik?.values?.nationalIdIssuedDate) >
      moment(new Date(Date.now())).format('YYYY-MM-DD')
    ) {
      return true;
    }
    return false;
  };

  const validateDateElectorIdIssuedDate = () => {
    if (
      formatDate(formik?.values?.electorIdIssuedDate) >
      moment(new Date(Date.now())).format('YYYY-MM-DD')
    ) {
      return true;
    }
    return false;
  };

  const validateDateBirthDate = () => {
    if (
      formatDate(formik?.values?.birthDate) >
      moment(new Date(Date.now())).format('YYYY-MM-DD')
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      !validateDateNationalIdIssuedDate() &&
      !validateDateElectorIdIssuedDate() &&
      !validateDateBirthDate()
    ) {
      setValidateDate(true);
    } else {
      setValidateDate(false);
    }
  }, [
    setValidateDate,
    validateDateBirthDate,
    validateDateElectorIdIssuedDate,
    validateDateNationalIdIssuedDate,
  ]);

  return (
    <>
      <Box mb={3}>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '28px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '18px',
                },
              }}
            >
              Dados do Dependente
            </Typography>
          </Grid>
          <Grid item>
            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.name && !!formik.errors.name}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Nome Completo"
                placeholder="Nome Completo"
                name="name"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && !!formik.errors.name}
              />
              <FormHelperText
                hidden={!formik.touched.name || !formik.errors.name}
                error={formik.touched.name && !!formik.errors.name}
              >
                {formik.errors.name}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.cpf && !!formik.errors.cpf}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="CPF"
                placeholder="CPF"
                name="cpf"
                {...formik.getFieldProps('cpf')}
                onBlur={() => {
                  formik.setFieldValue('cpf', formatCpf(formik.values.cpf));
                }}
                error={formik.touched.cpf && !!formik.errors.cpf}
              />
              <FormHelperText
                hidden={!formik.touched.cpf || !formik.errors.cpf}
                error={formik.touched.cpf && !!formik.errors.cpf}
              >
                {formik.errors.cpf}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
                marginBottom: '8px',
              }}
            >
              Registro Geral
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={5}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.nationalIdNumber &&
                    !!formik.errors.nationalIdNumber
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Número"
                    placeholder="Número"
                    name="nationalIdNumber"
                    {...formik.getFieldProps('nationalIdNumber')}
                    error={
                      formik.touched.nationalIdNumber &&
                      !!formik.errors.nationalIdNumber
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.nationalIdNumber ||
                      !formik.errors.nationalIdNumber
                    }
                    error={
                      formik.touched.nationalIdNumber &&
                      !!formik.errors.nationalIdNumber
                    }
                  >
                    {formik.errors.nationalIdNumber}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.nationalIdIssuer &&
                    !!formik.errors.nationalIdIssuer
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Órgão emissor"
                    placeholder="Órgão emissor"
                    name="nationalIdIssuer"
                    {...formik.getFieldProps('nationalIdIssuer')}
                    error={
                      formik.touched.nationalIdIssuer &&
                      !!formik.errors.nationalIdIssuer
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.nationalIdIssuer ||
                      !formik.errors.nationalIdIssuer
                    }
                    error={
                      formik.touched.nationalIdIssuer &&
                      !!formik.errors.nationalIdIssuer
                    }
                  >
                    {formik.errors.nationalIdIssuer}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.nationalIdIssuedDate &&
                    !!formik.errors.nationalIdIssuedDate
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Data de expedição"
                      value={formik?.values?.nationalIdIssuedDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('nationalIdIssuedDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...formik.getFieldProps('nationalIdIssuedDate')}
                          error={
                            formik.touched.nationalIdIssuedDate &&
                            Boolean(formik.errors.nationalIdIssuedDate)
                          }
                        />
                      )}
                      mask="__/__/____"
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                  <FormHelperText
                    hidden={
                      !formik.touched.nationalIdIssuedDate ||
                      !formik.errors.nationalIdIssuedDate
                    }
                    error={
                      formik.touched.nationalIdIssuedDate &&
                      !!formik.errors.nationalIdIssuedDate
                    }
                  >
                    {formik.errors.nationalIdIssuedDate}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
                marginBottom: '8px',
              }}
            >
              Título de Eleitor
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdNumber &&
                    !!formik.errors.electorIdNumber
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Número"
                    placeholder="Número"
                    name="electorIdNumber"
                    {...formik.getFieldProps('electorIdNumber')}
                    error={
                      formik.touched.electorIdNumber &&
                      !!formik.errors.electorIdNumber
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdNumber ||
                      !formik.errors.electorIdNumber
                    }
                    error={
                      formik.touched.electorIdNumber &&
                      !!formik.errors.electorIdNumber
                    }
                  >
                    {formik.errors.electorIdNumber}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdSeries &&
                    !!formik.errors.electorIdSeries
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Série"
                    placeholder="Série"
                    name="electorIdSeries"
                    {...formik.getFieldProps('electorIdSeries')}
                    inputProps={{ maxLength: 2 }}
                    error={
                      formik.touched.electorIdSeries &&
                      !!formik.errors.electorIdSeries
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdSeries ||
                      !formik.errors.electorIdSeries
                    }
                    error={
                      formik.touched.electorIdSeries &&
                      !!formik.errors.electorIdSeries
                    }
                  >
                    {formik.errors.electorIdSeries}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdState &&
                    !!formik.errors.electorIdState
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="UF"
                    placeholder="UF"
                    name="electorIdState"
                    {...formik.getFieldProps('electorIdState')}
                    error={
                      formik.touched.electorIdState &&
                      !!formik.errors.electorIdState
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdState ||
                      !formik.errors.electorIdState
                    }
                    error={
                      formik.touched.electorIdState &&
                      !!formik.errors.electorIdState
                    }
                  >
                    {formik.errors.nationalIdIssuer}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdIssuedDate &&
                    !!formik.errors.electorIdIssuedDate
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Data de expedição"
                      value={formik?.values?.electorIdIssuedDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('electorIdIssuedDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...formik.getFieldProps('electorIdIssuedDate')}
                          error={
                            formik.touched.electorIdIssuedDate &&
                            Boolean(formik.errors.electorIdIssuedDate)
                          }
                        />
                      )}
                      mask="__/__/____"
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdIssuedDate ||
                      !formik.errors.electorIdIssuedDate
                    }
                    error={
                      formik.touched.electorIdIssuedDate &&
                      !!formik.errors.electorIdIssuedDate
                    }
                  >
                    {formik.errors.electorIdIssuedDate}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.birthCertificate &&
                !!formik.errors.birthCertificate
              }
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Certidão de nascimento"
                placeholder="Certidão de nascimento"
                name="birthCertificate"
                {...formik.getFieldProps('birthCertificate')}
                error={
                  formik.touched.birthCertificate &&
                  !!formik.errors.birthCertificate
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.birthCertificate ||
                  !formik.errors.birthCertificate
                }
                error={
                  formik.touched.birthCertificate &&
                  !!formik.errors.birthCertificate
                }
              >
                {formik.errors.birthCertificate}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.gender && !!formik.errors.gender}
            >
              <InputLabel id="select-label">Gênero</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Gênero"
                name="gender"
                displayEmpty
                renderValue={
                  formik.values.gender === ''
                    ? () => <div className={classes.placeholder}>Gênero</div>
                    : undefined
                }
                {...formik.getFieldProps('gender')}
                onChange={handleChangeGender}
                error={formik.touched.gender && !!formik.errors.gender}
              >
                {GENDER_TYPE.map((result) => (
                  <MenuItem key={result?.gender} value={result?.gender}>
                    {result?.gender}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={!formik.touched.gender || !formik.errors.gender}
                error={formik.touched.gender && !!formik.errors.gender}
              >
                {formik.errors.gender}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.hasDisability && !!formik.errors.hasDisability
              }
            >
              <InputLabel id="select-label">Deficiência</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Deficiência"
                placeholder="Ex: Sim ou Não"
                name="hasDisability"
                displayEmpty
                renderValue={
                  formik.values.hasDisability === ''
                    ? () => (
                        <div className={classes.placeholder}>Deficiência</div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('hasDisability')}
                onChange={handleChangeDisability}
                error={
                  formik.touched.hasDisability && !!formik.errors.hasDisability
                }
              >
                {HAS_DISABILITY.map((result) => (
                  <MenuItem key={result?.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.hasDisability || !formik.errors.hasDisability
                }
                error={
                  formik.touched.hasDisability && !!formik.errors.hasDisability
                }
              >
                {formik.errors.hasDisability}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={4}>
            <FormControl
              fullWidth
              error={formik.touched.birthDate && !!formik.errors.birthDate}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data de nascimento"
                  value={formik?.values?.birthDate}
                  onChange={(newValue) => {
                    formik.setFieldValue('birthDate', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...formik.getFieldProps('birthDate')}
                      error={
                        formik.touched.birthDate &&
                        Boolean(formik.errors.birthDate)
                      }
                    />
                  )}
                  mask="__/__/____"
                  inputFormat="DD/MM/YYYY"
                />
              </LocalizationProvider>
              <FormHelperText
                hidden={!formik.touched.birthDate || !formik.errors.birthDate}
                error={formik.touched.birthDate && !!formik.errors.birthDate}
              >
                {formik.errors.birthDate}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.birthPlace && !!formik.errors.birthPlace}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Naturalidade"
                placeholder="Naturalidade"
                name="birthPlace"
                {...formik.getFieldProps('birthPlace')}
                error={formik.touched.birthPlace && !!formik.errors.birthPlace}
              />
              <FormHelperText
                hidden={!formik.touched.birthPlace || !formik.errors.birthPlace}
                error={formik.touched.birthPlace && !!formik.errors.birthPlace}
              >
                {formik.errors.birthPlace}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.nationality && !!formik.errors.nationality}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Nacionalidade"
                placeholder="Nacionalidade"
                name="nationality"
                {...formik.getFieldProps('nationality')}
                error={
                  formik.touched.nationality && !!formik.errors.nationality
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.nationality || !formik.errors.nationality
                }
                error={
                  formik.touched.nationality && !!formik.errors.nationality
                }
              >
                {formik.errors.nationality}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.race && !!formik.errors.race}
            >
              <InputLabel id="select-label">Raça/cor</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Raça/cor"
                placeholder="Raça/cor"
                name="race"
                displayEmpty
                renderValue={
                  formik.values.race === ''
                    ? () => <div className={classes.placeholder}>Raça/cor</div>
                    : undefined
                }
                {...formik.getFieldProps('race')}
                onChange={handleChangeRace}
                error={formik.touched.race && !!formik.errors.race}
              >
                {RACE_TYPE.map((result) => (
                  <MenuItem key={result.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={!formik.touched.race || !formik.errors.race}
                error={formik.touched.race && !!formik.errors.race}
              >
                {formik.errors.race}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.educationLevel && !!formik.errors.educationLevel
              }
            >
              <InputLabel id="select-label">Grau de instrução</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Grau de instrução"
                placeholder="Grau de instrução"
                name="educationLevel"
                displayEmpty
                renderValue={
                  formik.values.educationLevel === ''
                    ? () => (
                        <div className={classes.placeholder}>
                          Grau de instrução
                        </div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('educationLevel')}
                onChange={handleChangeEducationLevel}
                error={
                  formik.touched.educationLevel &&
                  !!formik.errors.educationLevel
                }
              >
                {EDUCATION_LEVEL_TYPE.map((result) => (
                  <MenuItem key={result.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.educationLevel ||
                  !formik.errors.educationLevel
                }
                error={
                  formik.touched.educationLevel &&
                  !!formik.errors.educationLevel
                }
              >
                {formik.errors.educationLevel}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.relationshipType &&
                !!formik.errors.relationshipType
              }
            >
              <InputLabel id="select-label">Grau de parentesco</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Grau de parentesco"
                placeholder="Grau de parentesco"
                name="relationshipType"
                displayEmpty
                renderValue={
                  formik.values.relationshipType === ''
                    ? () => (
                        <div className={classes.placeholder}>
                          Grau de parentesco
                        </div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('relationshipType')}
                onChange={handleChangeRelationship}
                error={
                  formik.touched.relationshipType &&
                  !!formik.errors.relationshipType
                }
              >
                {RELATIONSHIP_TYPE.map((result) => (
                  <MenuItem key={result?.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.relationshipType ||
                  !formik.errors.relationshipType
                }
                error={
                  formik.touched.relationshipType &&
                  !!formik.errors.relationshipType
                }
              >
                {formik.errors.relationshipType}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.maritialStatus && !!formik.errors.maritialStatus
              }
            >
              <InputLabel id="select-label">Estado Civil</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Estado Civil"
                placeholder="Estado Civil"
                name="maritialStatus"
                displayEmpty
                renderValue={
                  formik.values.maritialStatus === ''
                    ? () => (
                        <div className={classes.placeholder}>Estado Civil</div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('maritialStatus')}
                onChange={handleChangeMaritialStatus}
                error={
                  formik.touched.maritialStatus &&
                  !!formik.errors.maritialStatus
                }
              >
                {MARITIAL_STATUS_TYPE.map((result) => (
                  <MenuItem key={result.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.maritialStatus ||
                  !formik.errors.maritialStatus
                }
                error={
                  formik.touched.maritialStatus &&
                  !!formik.errors.maritialStatus
                }
              >
                {formik.errors.maritialStatus}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
                marginBottom: '8px',
              }}
            >
              Filiação
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.motherName && !!formik.errors.motherName
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Nome da Mãe"
                    placeholder="Nome da Mãe"
                    name="motherName"
                    {...formik.getFieldProps('motherName')}
                    error={
                      formik.touched.motherName && !!formik.errors.motherName
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.motherName || !formik.errors.motherName
                    }
                    error={
                      formik.touched.motherName && !!formik.errors.motherName
                    }
                  >
                    {formik.errors.motherName}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.fatherName && !!formik.errors.fatherName
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Nome do Pai"
                    placeholder="Nome do Pai"
                    name="fatherName"
                    {...formik.getFieldProps('fatherName')}
                    error={
                      formik.touched.fatherName && !!formik.errors.fatherName
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.fatherName || !formik.errors.fatherName
                    }
                    error={
                      formik.touched.fatherName && !!formik.errors.fatherName
                    }
                  >
                    {formik.errors.fatherName}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          closeAfterTransition
          open={openNewDocument ?? false}
        >
          <Box>
            <Paper
              sx={{
                background: '#FFF',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
                borderRadius: '4px',
                padding: '16px',
              }}
            >
              <Grid
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                container
                item
              >
                <Grid item>
                  <Typography mt={3} mb={1} variant="h6">
                    Realize o upload do(s) arquivo(s) abaixo
                  </Typography>
                </Grid>
                <Grid item>
                  <CloseIcon
                    onClick={handleCloseNewDocument}
                    sx={{ cursor: 'pointer' }}
                  />
                </Grid>
              </Grid>
              <Grid mt={3} container display="flex" justifyContent="center">
                <Grid item sm={12} xs={12}>
                  <Grid
                    mb={2}
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    spacing={1}
                    container
                  >
                    <Grid item>
                      <InputLabel>Nome do Documento</InputLabel>
                      <FormControl>
                        <TextField
                          value={uploadNameDependent}
                          height="40px"
                          width="350px"
                          type="text"
                          placeholder="Ex: RG"
                          name="electorIdSeries"
                          onChange={(e) => {
                            handleUpLoadName(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <InputLabel>Descrição</InputLabel>
                      <FormControl>
                        <TextField
                          value={uploadLabelDependent}
                          height="40px"
                          width="350px"
                          type="text"
                          placeholder="Ex: Registro Geral"
                          name="electorIdSeries"
                          onChange={(e) => {
                            handleUpLoadDescription(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <InputLabel>Data do Documento</InputLabel>
                      <FormControl>
                        <TextField
                          value={uploadDateDependent}
                          height="40px"
                          width="350px"
                          type="date"
                          placeholder="Data"
                          name="electorIdSeries"
                          onChange={(e) => {
                            handleUpLoadDateDoc(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel>Arquivo</InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          height="40px"
                          width="350px"
                          type="file"
                          inputProps={{ accept: 'application/pdf' }}
                          onChange={(e) => {
                            uploadDoc(e);
                          }}
                        />
                      </FormControl>
                      <FormHelperText hidden={!errorMsg} error={errorMsg}>
                        Selecione um documento pdf
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2.5}
                container
                spacing={2}
              >
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  item
                >
                  {loadingUpload && (
                    <CircularProgress sx={{ marginLeft: '8px' }} />
                  )}
                  <Button
                    onClick={handleUpload}
                    variant="contained"
                    color="primary"
                  >
                    Enviar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleCloseNewDocument}
                    variant="contained"
                    color="error"
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Modal>
        <Box mt={4} display="flex" justifyContent="center">
          <Grid display="flex" justifyContent="center" container spacing={4}>
            <Grid display="flex" item>
              {loading && <CircularProgress sx={{ marginRight: '8px' }} />}
              <Button
                onClick={handleNext}
                className={classes.buttonModal}
                variant="contained"
                color="primary"
              >
                {getEmployeeDependentId ? 'Atualizar' : 'Salvar'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleCancel}
                className={classes.buttonModal}
                variant="contained"
                color="error"
              >
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {getEmployeeDependentId && (
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={12}>
              <EmployeeDocument
                getEmployeeDependentId={getEmployeeDependentId}
                categoryType="DocumentosPessoais"
                tabId="2"
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

DependetEmployeeDataRegister.propTypes = {
  setOnRefresh: PropTypes.func.isRequired,
  dependentCurrentValue: PropTypes.oneOfType([PropTypes.object]),
};

DependetEmployeeDataRegister.defaultProps = {
  dependentCurrentValue: {},
};
