import { useState, useContext } from 'react';
import { Avatar, Grid } from '@mui/material';
import { ExpandLess, ExpandMore, Image } from '@mui/icons-material';
import * as Styled from './styled';
import { AuthContext } from '../../contexts/auth';
import ChangePasswordModalConfirmation from './ChangePasswordModalConfirmation';
import useSecurity from '../../useSecurity/useSecurity';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const getItens = useSecurity();

  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    logout();
  };

  const handlePassword = () => {
    setOpen(true);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const recoveredUser = JSON.parse(localStorage.getItem('userName'));

  const useGetSecurityItens = {
    getUser: getItens().getUser(),
    getRole: getItens().getRole(),
  };

  return (
    <Styled.Container>
      <Styled.LeftArea />

      <Styled.RightArea onClick={toggleDropdown}>
        <Styled.UserArea>
          <Styled.DropDownContainer>
            <Avatar>
              <Image />
            </Avatar>
            {isOpen && (
              <Styled.DropDownListContainer>
                <Styled.DropDownList>
                  <Styled.ListItem onClick={handleLogout}>Sair</Styled.ListItem>
                  <Styled.ListItem onClick={handlePassword}>
                    Alterar Senha
                  </Styled.ListItem>
                </Styled.DropDownList>
              </Styled.DropDownListContainer>
            )}
          </Styled.DropDownContainer>

          <Styled.NameUser>
            <Grid>{recoveredUser?.split(' ')[0]}</Grid>
            <Grid>
              {(useGetSecurityItens.getRole === 'AdmnistradorTenant' ||
                useGetSecurityItens.getRole === 'AdministradorSistema') && (
                <Grid container item>
                  Administrador
                </Grid>
              )}
            </Grid>
          </Styled.NameUser>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </Styled.UserArea>
      </Styled.RightArea>

      <ChangePasswordModalConfirmation open={open} setOpen={setOpen} />
    </Styled.Container>
  );
}

export default Header;
