import React, { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

import EditIcon from '@mui/icons-material/Edit';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import EmployContext from './EmployContext';

import NewEmployeesModal from './NewEmployeesModal';

import * as Styled from './styled';

import ButtonS from '../Button';

import EmployeeRegisterClient from '../../clients/employeeRegisterClient';
import DeleteEmployeeModal from './DeleteEmployeeModal';
import { AppContext } from '../../contexts/sidebarContext';

import EmployeeDocModal from './EmployeeDocModal';
import EmployeeSearch from '../EmployeesSearch';
import EmployeeModalConfirmation from './EmployeeModalConfirmation';

import { ReactComponent as CircleActivity } from '../../assets/icons/circle-activity.svg';
import { ReactComponent as CircleDisabled } from '../../assets/icons/circle-disabled.svg';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1510px)': {
      fontSize: '12px !important',
    },
  },

  TableBody: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px !important',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1510px)': {
      fontSize: '12px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '10px 0 10px 0',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1510px)': {
      height: '100%',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

export default function EmployeesManagement() {
  const classes = useStyles();
  const useEmployeeRegisterClient = EmployeeRegisterClient();

  const getRoleUser = localStorage.getItem('role');

  const context = useContext(AppContext);
  const { msgAlert, filteredList, filteringList } = context;

  const [getId, setGetId] = useState('');
  const [employId, setEmployId] = useState();
  const [responseConfirmation, setResponseConfirmation] = useState();
  const [tabValue, setTabValue] = useState('1');
  const [onRefresh, setOnRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [employeesData, setemployeesData] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [employCurrentData, setEmployCurrentData] = useState({});
  const [getEmployeeDependentId, setGetEmployeeDependentId] = useState('');
  const [showRegisterDependent, setShowRegisterDependent] = useState(false);
  const [showInactiveItens, setShowInactiveItens] = useState(false);

  const deleteEndpointClient = (id) => {
    useEmployeeRegisterClient()
      .deleteDocumentType(id)
      .then(
        (response) => {
          msgAlert({
            response,
            message: 'Colaborador deletado com sucesso!',
          });
          setOnRefresh(true);
        },
        (response) => {
          msgAlert({
            response,
          });
          setOnRefresh(true);
        }
      );
  };

  const handleOpen = () => {
    setOpenModal(true);
    setEmployCurrentData();
    setEmployId();
    setShowRegisterDependent();
    setTabValue('1');
  };

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  useEffect(() => {
    setLoadingProgress(true);
    useEmployeeRegisterClient()
      .getEmployee()
      .then((response) => {
        setLoadingProgress(false);
        setemployeesData(response);
        filteringList(response);
      });
    setOnRefresh(false);
  }, [setemployeesData, onRefresh]);

  const handleEdit = (data) => {
    setEmployCurrentData(data);
    setTabValue('1');
    setOpenModal(true);
  };

  const handleDelete = (id) => {
    setGetId(id);
    setOpenEditModal(true);
  };

  const handleShowDocuments = (id, name) => {
    setGetId(id);
    setEmployeeName(name);
    setShowDocuments(true);
  };

  const { Provider } = EmployContext;

  const handleText = (text) => {
    if (String(text)?.length > 0) {
      if (text?.length > 25) {
        const docTypeEnd = text.slice(0, 25);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  const traslateFunction = (translate) => {
    if (translate === 'Ativo') {
      return 'Sim';
    }
    return 'Não';
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const showInactiveItensValueFunction = (status) => {
    if (showInactiveItens) {
      return true;
    }
    if (status === 'Ativo') {
      return true;
    }
    return false;
  };

  return (
    <Provider
      value={{
        tabValue,
        employId,
        setTabValue,
        setEmployId,
        employCurrentData,
        responseConfirmation,
        setEmployCurrentData,
        showRegisterDependent,
        getEmployeeDependentId,
        setShowRegisterDependent,
        setGetEmployeeDependentId,
        employeeMsgAlert: setResponseConfirmation,
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              sx={{ width: '300px' }}
              display="flex"
              justifyContent="center"
              item
            >
              <ButtonS
                text="Filtrar Colaboradores"
                onClick={handleOpenSearchModal}
                whiteButton
                width="250px"
                height="50px"
              />
            </Grid>
            <Grid
              sx={{ width: '300px' }}
              display="flex"
              justifyContent="center"
              item
            >
              <ButtonS
                isLoginButton={false}
                isLoginText={false}
                onClick={handleOpen}
                text="+ Adicionar"
                width="250px"
                height="50px"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box
        mt={2}
        sx={{ padding: '20px' }}
        display="flex"
        justifyContent="flex-start"
      >
        <Styled.Title>Colaboradores</Styled.Title>
      </Box>
      <Styled.Container>
        <Grid
          display="flex"
          justifyContent="center"
          container
          mb={3}
          columns={40}
          className={classes.TableHead}
        >
          <Grid className={classes.HeadCell} item xl={15} xs={13}>
            Informações
          </Grid>
          <Grid className={classes.HeadCell} item xl={8} xs={10}>
            Tipo de Admissão
          </Grid>
          {(getRoleUser === 'AdministradorSistema' ||
            getRoleUser === 'AdmnistradorTenant') && (
            <Grid className={classes.HeadCellCenter} item xl={7} xs={6}>
              Ativo
            </Grid>
          )}
          <Grid className={classes.HeadCellCenter} item xl={4} xs={6}>
            Opções
          </Grid>

          <Box
            sx={{
              width: '100%',
            }}
          >
            {alphabetOrder(filteredList, 'name').map((employeeData) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {showInactiveItensValueFunction(employeeData.status) && (
                  <Paper className={classes.Paper} key={employeeData.id}>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      container
                      columns={40}
                      className={classes.TableBody}
                    >
                      <Grid
                        className={classes.BodyCellLeft}
                        item
                        xl={15}
                        xs={13}
                      >
                        <Grid container>
                          <Grid item>
                            <Grid container my={2} item>
                              <b>Nome:</b>
                            </Grid>
                            <Grid container my={2} item>
                              <b>CPF:</b>
                            </Grid>
                            <Grid container my={2} item>
                              <b>Cargo:</b>
                            </Grid>
                          </Grid>
                          <Grid ml={1} item>
                            <Grid container my={2} item>
                              {handleText(employeeData.name)}
                            </Grid>
                            <Grid container my={2} item>
                              {employeeData?.cpf}
                            </Grid>
                            <Grid container my={2} item>
                              {handleText(employeeData?.jobTitleName)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        className={classes.BodyCellLeft}
                        item
                        xl={8}
                        xs={10}
                      >
                        {handleText(employeeData?.admissionTypeName)}
                      </Grid>
                      <Grid
                        className={classes.BodyCellCenter}
                        item
                        xl={7}
                        xs={6}
                      >
                        <Grid
                          container
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          {employeeData.status === 'Ativo' ? (
                            <Grid
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                            >
                              <CircleActivity />
                            </Grid>
                          ) : (
                            <Grid
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                            >
                              <CircleDisabled />
                            </Grid>
                          )}
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                          >
                            {traslateFunction(employeeData.status)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        className={classes.BodyCellCenter}
                        item
                        xl={4}
                        xs={6}
                      >
                        <Grid
                          container
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                          >
                            <PlagiarismIcon
                              onClick={() => {
                                handleShowDocuments(
                                  employeeData?.id,
                                  employeeData?.name
                                );
                              }}
                              sx={{ cursor: 'pointer', fontSize: '30px' }}
                            />
                          </Grid>
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                          >
                            <EditIcon
                              onClick={() => {
                                handleEdit(employeeData);
                              }}
                              sx={{ cursor: 'pointer', color: '#107FAA' }}
                            />
                          </Grid>
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                          >
                            <DeleteForeverIcon
                              onClick={() => {
                                handleDelete(employeeData.id);
                              }}
                              sx={{ cursor: 'pointer', color: 'red' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </>
            ))}
            {loadingProgress && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="indeterminate" />
              </Box>
            )}
          </Box>
        </Grid>
      </Styled.Container>

      {responseConfirmation && (
        <EmployeeModalConfirmation resp={responseConfirmation} />
      )}

      {openModal && (
        <NewEmployeesModal openModal={openModal} setOpenModal={setOpenModal} />
      )}

      {showDocuments && (
        <EmployeeDocModal
          getId={getId}
          openModalDoc={showDocuments}
          setOpenModalDoc={setShowDocuments}
          employeeName={employeeName}
        />
      )}

      {openEditModal && (
        <DeleteEmployeeModal
          open={openEditModal}
          getId={getId}
          setOpen={setOpenEditModal}
          deleteEndpoint={deleteEndpointClient}
        />
      )}

      {openSearchModal && (
        <EmployeeSearch
          list={employeesData}
          openModal={openSearchModal}
          setOpenModal={setOpenSearchModal}
          showInactiveItens={showInactiveItens}
          setShowInactiveItens={setShowInactiveItens}
        />
      )}
    </Provider>
  );
}
