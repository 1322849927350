import { useCallback } from 'react';
import { api } from '../services/api';

export default function EmployeeGeneralDocumentClient() {
  const getDataReport = useCallback(
    () => api.get(`/Reports/Employees`).then((response) => response.data),
    []
  );

  return useCallback(
    () => ({
      getDataReport,
    }),
    [getDataReport]
  );
}
