import styled from 'styled-components';

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  label{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.text.dark};
  }

  input{
    max-width: ${props => props.width};
    height: ${props => props.height};
    border: 1px solid #000000;
    border-radius: 5px;
    padding-left: 15px;
  }
`;