import PropTypes from 'prop-types';

import { Box, Grid, Paper } from '@mui/material';
import {
  FileDownload as FileOpenIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import * as Styled from '../ListDocumentsType/styled';
import DeleteItemModal from '../Employees/DeleteEmployeeModal';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '11px !important',
    },
  },

  TableBody: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '11px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    justifyContent: 'left',
    alignContent: 'center',
    paddingLeft: '10px !important',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1366px)': {
      height: '60px',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

export function DataTableEmployee({ data, onOpenFile, onDelete, onEdit }) {
  const classes = useStyles();
  const [isDeleteModalOpen, setDeleteModalStatus] = useState(false);
  const [currentSelectedItem, setCurrentSelectedItem] = useState(undefined);

  const formatDate = useCallback(
    (date) => date && moment(date).format('DD/MM/YYYY'),
    []
  );

  const dateOrder = (array, prop) =>
    array
      ?.sort((a, b) => {
        const dateA = new Date(a[prop]);
        const dateB = new Date(b[prop]);

        if (!Number.isNaN(dateA) && !Number.isNaN(dateB)) {
          return dateB - dateA;
        }
        if (Number.isNaN(dateA) && !Number.isNaN(dateB)) {
          return -1;
        }
        if (!Number.isNaN(dateA) && Number.isNaN(dateB)) {
          return 1;
        }
        return 0;
      })
      .map((result) => ({
        ...result,
        documentDate: formatDate(result?.documentDate),
      }));

  const handleOpenModal = (item) => {
    setCurrentSelectedItem(item.id);
    setDeleteModalStatus(true);
  };

  const handleDelete = (itemId) => {
    setDeleteModalStatus(false);
    onDelete(itemId);
    setCurrentSelectedItem(undefined);
  };

  const handleText = (text) => {
    if (String(text)?.length > 0) {
      if (text?.length > 25) {
        const docTypeEnd = text.slice(0, 25);
        return `${docTypeEnd} ...`;
      }
      return text;
    }
    return '-';
  };

  return (
    <Styled.Container style={{ minWidth: '100% !important' }}>
      <Grid
        display="flex"
        justifyContent="center"
        container
        pl={3}
        mb={3}
        columns={42}
        className={classes.TableHead}
      >
        <Grid className={classes.HeadCellLeft} item xs={13}>
          Tipo de Documento
        </Grid>
        <Grid className={classes.HeadCellLeft} item xs={7}>
          N°
        </Grid>
        <Grid className={classes.HeadCellLeft} item xs={7}>
          Ano
        </Grid>
        <Grid className={classes.HeadCellLeft} item xs={7}>
          Data do Documento
        </Grid>
        <Grid className={classes.HeadCellCenter} item xs={7}>
          Opções
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '100%',
        }}
      >
        {dateOrder(data, 'documentDate').map((item) => (
          <Paper className={classes.Paper} key={item.id}>
            <Grid
              display="flex"
              justifyContent="center"
              container
              pl={3}
              columns={42}
              className={classes.TableBody}
            >
              <Grid display="flex" justifyContent="left" item xs={13}>
                {handleText(item?.type)}
              </Grid>
              <Grid display="flex" justifyContent="left" item xs={7}>
                {item?.documentNumber}
              </Grid>
              <Grid display="flex" justifyContent="left" item xs={7}>
                {item?.documentYear}
              </Grid>
              <Grid display="flex" justifyContent="left" item xs={7}>
                {item?.documentDate}
              </Grid>
              <Grid className={classes.BodyCellCenter} item xs={7}>
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {/* see-file-button::begin */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <FileOpenIcon
                      sx={{ cursor: 'pointer', color: '#107FAA' }}
                      onClick={() => onOpenFile(item)}
                      title="Baixar arquivo"
                    />
                  </Grid>
                  {/* see-file-button::end */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <EditIcon
                      sx={{ cursor: 'pointer', color: '#107FAA' }}
                      onClick={() => onEdit(item.id)}
                      title="Editar"
                    />
                  </Grid>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <DeleteForeverIcon
                      sx={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        handleOpenModal(item);
                      }}
                      title="Deletar"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}

        {data.length === 0 && (
          <p
            style={{ marginTop: '40px', textAlign: 'center', color: '#000000' }}
          >
            Pesquise ou adicione um documento.
          </p>
        )}
      </Box>

      {/* delete-modal::begin */}
      <DeleteItemModal
        setOpen={setDeleteModalStatus}
        open={isDeleteModalOpen}
        deleteEndpoint={handleDelete}
        getId={currentSelectedItem}
      />
      {/* delete-modal::end */}
    </Styled.Container>
  );
}

DataTableEmployee.propTypes = {
  // rows: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    .isRequired,
  onOpenFile: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  // tableType: PropTypes.string.isRequired,
};
