import React, { useContext, useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import { Help } from '@mui/icons-material';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as Styled from './styled';
import Button from '../Button';
import sectorTypeClient from '../../clients/sectorTypeClient';
import { AppContext } from '../../contexts/sidebarContext';

const VALUES_TYPE_SECTOR = [
  {
    sectorTypeLabel: 'Órgão',
    sectorTypeValue: 'Orgao',
  },
  {
    sectorTypeLabel: 'Departamento',
    sectorTypeValue: 'Departamento',
  },
  {
    sectorTypeLabel: 'Unidade',
    sectorTypeValue: 'Unidade',
  },
  {
    sectorTypeLabel: 'Setor',
    sectorTypeValue: 'Setor',
  },
];

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  selectField: {
    height: '72px',
    borderRadius: '10px !important',
    '& fieldset': {
      border: '1px solid #000',
    },
  },
  placeholder: {
    color: 'rgb(118, 118, 118)',
  },
}));

export default function NewSectors() {
  const classes = useStyles();
  const useSectorClient = sectorTypeClient();

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const [typeSector, setTypeSector] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [typeSuperiorSector, setTypeSuperiorSector] = useState([]);
  const [disabeButton, setDisabeButton] = useState(false);

  const DEFAULT = {
    name: '',
    description: '',
    sectorType: '',
    superiorSectorId: null,
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obrigatório'),
      description: Yup.string(),
      sectorType: Yup.string().required('Campo obrigatório'),
      superiorSectorId: Yup.string().nullable(true),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        name: data.name,
        description: data.description,
        sectorType: data.sectorType,
        superiorSectorId: data.superiorSectorId ?? null,
        tenantId: useTenantId,
      };

      setDisabeButton(true);

      useSectorClient()
        .newSector(payload)
        .then(
          (response) => {
            msgAlert({
              response,
              message: 'Setor cadastrado com sucesso!',
            });
          },
          (resp) => {
            msgAlert({
              response: resp,
            });
          }
        );
    },
  });

  useEffect(() => {
    useSectorClient()
      .getSector()
      .then((response) => {
        setSectors(response);
      });
  }, []);

  const handleSubmit = () => {
    formik.submitForm();
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const handleChange = (event) => {
    setTypeSector(event.target.value);
  };

  const handleChangeSuperiorSector = (event) => {
    setTypeSuperiorSector(event.target.value);
  };

  return (
    <Styled.Container>
      <Styled.BoxTitle>
        <Styled.TitleModal>Nova Estrutura Organizacional</Styled.TitleModal>
      </Styled.BoxTitle>

      <Styled.InputAreaWrapper>
        <Styled.FirstRow>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container item spacing={2}>
                <Grid item xs>
                  <FormControl
                    fullWidth
                    error={formik.touched.name && formik.errors.name}
                  >
                    <TextField
                      type="text"
                      placeholder="Ex: Secretaria de Esportes"
                      name="name"
                      label="Nome do Órgão/Unidade/Dep/Setor"
                      className={classes.textField}
                      {...formik.getFieldProps('name')}
                      error={formik.touched.name && formik.errors.name}
                    />
                    <FormHelperText
                      hidden={!formik.touched.name || !formik.errors.name}
                      error={formik.touched.name && !!formik.errors.name}
                    >
                      {formik.errors.name}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.description && formik.errors.description
                    }
                  >
                    <TextField
                      type="description"
                      placeholder="Ex: Esporte Pesca Lazer"
                      name="description"
                      label="Descrição"
                      className={classes.textField}
                      {...formik.getFieldProps('description')}
                      icon={
                        <Tooltip title="Usado para login" placement="right">
                          <Help fontSize="15px" />
                        </Tooltip>
                      }
                      error={
                        formik.touched.description && formik.errors.description
                      }
                    />
                    <FormHelperText
                      hidden={
                        !formik.touched.description ||
                        !formik.errors.description
                      }
                      error={
                        formik.touched.description &&
                        !!formik.errors.description
                      }
                    >
                      {formik.errors.description}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={
                  formik.touched.superiorSectorId &&
                  formik.errors.superiorSectorId
                }
              >
                <Select
                  fullWidth
                  variant="outlined"
                  value={typeSuperiorSector}
                  onChange={handleChangeSuperiorSector}
                  className={classes.selectField}
                  displayEmpty
                  renderValue={
                    formik.values.superiorSectorId === null
                      ? () => (
                          <div className={classes.placeholder}>
                            Superior Imediato
                          </div>
                        )
                      : undefined
                  }
                  {...formik.getFieldProps('superiorSectorId')}
                >
                  {sectors.map((sectorsData) => (
                    <MenuItem value={sectorsData?.id}>
                      {sectorsData?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  hidden={
                    !formik.touched.superiorSectorId ||
                    !formik.errors.superiorSectorId
                  }
                  error={
                    formik.touched.superiorSectorId &&
                    !!formik.errors.superiorSectorId
                  }
                >
                  {formik.errors.superiorSectorId}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Select
                fullWidth
                labelId="sectorType"
                id="sectorType"
                value={typeSector}
                displayEmpty
                renderValue={
                  formik.values.sectorType === ''
                    ? () => (
                        <div className={classes.placeholder}>
                          Estrutura administrativa
                        </div>
                      )
                    : undefined
                }
                onChange={handleChange}
                className={classes.selectField}
                {...formik.getFieldProps('sectorType')}
                error={formik.touched.sectorType && formik.errors.sectorType}
              >
                {VALUES_TYPE_SECTOR.map((type) => (
                  <MenuItem value={type.sectorTypeValue}>
                    {type.sectorTypeLabel}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.sectorType && formik.errors.sectorType && (
                <Typography
                  sx={{ color: '#d32f2f', fontSize: '12px' }}
                  color="Error"
                >
                  Selecione um valor
                </Typography>
              )}
            </Grid>
          </Grid>
        </Styled.FirstRow>
      </Styled.InputAreaWrapper>

      <Styled.ButtonArea>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={6}>
            <Button
              text="Limpar campos"
              type="send"
              onClick={resetForm}
              width="100%"
              height="72px"
              whiteButton
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disabeButton}
              text="Cadastrar"
              type="send"
              onClick={handleSubmit}
              width="100%"
              height="72px"
            />
          </Grid>
        </Grid>
      </Styled.ButtonArea>
    </Styled.Container>
  );
}
