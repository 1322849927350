import { useCallback } from 'react';

const useSecurity = () => {
  const getUser = () => JSON.parse(localStorage.getItem('userName'));
  const getToken = () => localStorage.getItem('token');
  const getRole = () => localStorage.getItem('role');

  return useCallback(
    () => ({
      getUser,
      getToken,
      getRole,
    }),
    [getUser, getToken, getRole]
  );
};

export default useSecurity;
