import React, { useContext, useState } from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

import ModalMensage from '../ModalMensage';
import Button from '../Button';
import { AppContext } from '../../contexts/sidebarContext';
import { forgotPassword } from '../../services/api';

function ChangePasswordModalConfirmation({ open, setOpen }) {
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const getUserEmail = localStorage.getItem('user');
  const useEmail = getUserEmail.startsWith(null)
    ? null
    : getUserEmail.substring(1, getUserEmail.length - 1);

  const handleConfirmation = () => {
    setIsLoading(true);
    forgotPassword(useEmail).then(
      () => {
        msgAlert({
          status: 200,
          message:
            'E-mail enviado com sucesso! Por favor, acesse o link no email',
        });
        setIsLoading(false);
        setOpen(false);
      },
      (response) => {
        msgAlert({
          response,
        });
        setIsLoading(false);
        setOpen(false);
      }
    );
  };

  return (
    <ModalMensage close={!isLoading} open={open} onClose={() => setOpen(false)}>
      <Box p={2} alignItems="center" justifyContent="center">
        <Box>
          <Box mt={2} mb={7}>
            <Typography align="center" variant="h4">
              Deseja mudar sua senha?
            </Typography>
          </Box>

          <Box mb={7}>
            <Typography align="center">
              Você receberá em seu e-mail um link para troca de senha!
            </Typography>
          </Box>
          <Grid container spacing={2} align="center" justifyContent="center">
            <Grid item xs={8} sm={4}>
              {isLoading ? (
                <Skeleton width={130} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                <Button
                  onClick={handleConfirmation}
                  text="Sim"
                  type="send"
                  width="150px"
                  height="45px"
                />
              )}
            </Grid>
            <Grid item xs={8} sm={4}>
              {isLoading ? (
                <Skeleton width={130} height={75} sx={{ marginTop: '-16px' }} />
              ) : (
                <Button
                  onClick={() => setOpen(false)}
                  text="Não"
                  type="send"
                  width="150px"
                  height="45px"
                  whiteButton
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalMensage>
  );
}

ChangePasswordModalConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

ChangePasswordModalConfirmation.defaultProps = {
  open: false,
};

export default ChangePasswordModalConfirmation;
