import React, { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useTheme } from 'styled-components';
import * as Styled from './styled';
import userClient from '../../clients/userClient';
import EditModalUser from './EditModalUser';
import DeleteModalConfirmation from './DeleteModalConfirmation';
import Button from '../Button';
import NewUser from '../NewUser';
import Modal from '../Modal';
import { AppContext } from '../../contexts/sidebarContext';
import SearchInput from '../SearchInput';
import { ReactComponent as CircleActivity } from '../../assets/icons/circle-activity.svg';
import { ReactComponent as CircleDisabled } from '../../assets/icons/circle-disabled.svg';

const useStyles = makeStyles(() => ({
  TableHead: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  TableBody: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#107FAA',
    '@media (max-width: 1366px)': {
      fontSize: '12px !important',
    },
  },

  HeadCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  BodyCellLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
  },

  HeadCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  BodyCellCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  Paper: {
    backgroundColor: '#f7f7f7 !important',
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    margin: '8px 0',
    marginBottom: '15px',
    '@media (max-width: 1366px)': {
      height: '60px',
    },
  },
  LinearProgress: {
    width: '100%',
  },
}));

function ListUsers() {
  const theme = useTheme();
  const classes = useStyles();

  const getRoleUser = localStorage.getItem('role');
  const useClient = userClient();
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [getId, setGetId] = useState('');
  const [getUser, setGetUser] = useState({});
  const [modalStatus, setModalStatus] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [showInactiveItens, setShowInactiveItens] = useState(false);

  const context = useContext(AppContext);

  const { filteredList, filteringList, msgAlert } = context;

  useEffect(() => {
    setLoadingProgress(true);
    useClient()
      .getUsers()
      .then((response) => {
        if (response) {
          setUsers(response);
          filteringList(response);
          setLoadingProgress(false);
        } else {
          setUsers([]);
          setLoadingProgress(false);
        }
      });
  }, []);

  const deleteUser = (data, userId) => {
    setGetUser(data);
    setGetId(userId);
    setOpenModalConfirmation(true);
  };

  const enableOrDisableUser = (data) => {
    const updateUser = () => {
      const payload = [data].map((userData) => ({
        administrator: userData?.administrator,
        enabled: !userData?.enabled,
        fullName: userData?.fullName,
        phone: userData?.phone,
        tenantId: userData?.tenantId,
      }));

      return payload[0];
    };

    const msgResponse = () => {
      if (data?.enabled) {
        return 'Usuário Desativado';
      }
      return 'Usuário Ativado';
    };

    useClient()
      .editUserById(data?.id, updateUser())
      .then(
        (response) => {
          msgAlert({
            response,
            message: msgResponse(),
          });
        },
        (resp) => {
          msgAlert({
            response: resp,
          });
        }
      );
  };

  const handleEdit = (data, userId) => {
    setGetId(userId);
    setGetUser(data);
    setOpenModal(true);
  };

  const handleModalClick = () => {
    setModalStatus(!modalStatus);
  };

  const traslateFunction = (translate) => {
    if (translate) {
      return 'Sim';
    }
    return 'Não';
  };

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const showInactiveItensValueFunction = (enabled) => {
    if (showInactiveItens) {
      return true;
    }
    return enabled;
  };

  const colorPowerFunction = (enabled) => {
    if (enabled) {
      return '#18FF59';
    }
    return 'red';
  };

  return (
    <>
      <Styled.Container>
        <Grid spacing={4} container>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={8}
          >
            <SearchInput list={users} searchFieldName={['fullName', 'email']} />
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={4}
          >
            <Button
              onClick={handleModalClick}
              text="+ Adicionar"
              width="300px"
              height="45px"
            />
          </Grid>
        </Grid>
      </Styled.Container>

      <Box display="flex" justifyContent="start" ml={5}>
        <FormControlLabel
          sx={{
            '& .MuiTypography-root': {
              display: 'flex',
              fontSize: '15px !important',
              fontStyle: 'normal !important',
              alignItems: 'center !important',
              fontFamily: 'Montserrat !important',
              fontWeight: 600,
              color: '#107faa !important',
              '@media (max-width: 1366px)': {
                fontSize: '12px !important',
              },
            },
          }}
          label="Mostrar inativos"
          control={
            <Checkbox
              checked={showInactiveItens}
              onChange={() => {
                setShowInactiveItens(!showInactiveItens);
              }}
              sx={{
                '&.Mui-checked': {
                  color: theme.background.blue,
                },
              }}
            />
          }
        />
      </Box>

      <Box
        mt={2}
        sx={{ padding: '20px' }}
        display="flex"
        justifyContent="flex-start"
      >
        <Styled.Title>Listagem de usuários</Styled.Title>
      </Box>
      <Styled.Container>
        <Grid
          display="flex"
          justifyContent="center"
          container
          mb={3}
          columns={40}
          className={classes.TableHead}
        >
          <Grid className={classes.HeadCell} item xl={8} xs={6}>
            Nome
          </Grid>
          <Grid className={classes.HeadCell} item xl={10} xs={11}>
            Email
          </Grid>
          <Grid className={classes.HeadCell} item xl={5} xs={5}>
            Telefone
          </Grid>
          <Grid className={classes.HeadCellCenter} item xl={6} xs={6}>
            Administrador
          </Grid>
          {(getRoleUser === 'AdministradorSistema' ||
            getRoleUser === 'AdmnistradorTenant') && (
            <Grid className={classes.HeadCellCenter} item xl={4} xs={4}>
              Ativo
            </Grid>
          )}
          <Grid className={classes.HeadCellCenter} item xl={5} xs={6}>
            Opções
          </Grid>
        </Grid>

        <Box
          sx={{
            width: '100%',
          }}
        >
          {alphabetOrder(filteredList, 'fullName').map((data) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {showInactiveItensValueFunction(data.enabled) && (
                <Paper className={classes.Paper} key={data.id}>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    container
                    columns={40}
                    className={classes.TableBody}
                  >
                    <Grid className={classes.BodyCellLeft} item xl={8} xs={6}>
                      {data.fullName?.split(' ')[0]}
                    </Grid>
                    <Grid className={classes.BodyCellLeft} item xl={10} xs={11}>
                      {data.email}
                    </Grid>
                    <Grid className={classes.BodyCellLeft} item xl={5} xs={5}>
                      {data.phone}
                    </Grid>
                    <Grid className={classes.BodyCellCenter} item xl={6} xs={6}>
                      {traslateFunction(data.administrator)}
                    </Grid>
                    {(getRoleUser === 'AdministradorSistema' ||
                      getRoleUser === 'AdmnistradorTenant') && (
                      <Grid
                        className={classes.BodyCellCenter}
                        item
                        xl={4}
                        xs={4}
                      >
                        <Grid
                          container
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          {data.enabled ? (
                            <Grid
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                            >
                              <CircleActivity />
                            </Grid>
                          ) : (
                            <Grid
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              item
                            >
                              <CircleDisabled />
                            </Grid>
                          )}
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                          >
                            {traslateFunction(data.enabled)}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid className={classes.BodyCellCenter} item xl={5} xs={6}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          item
                        >
                          <EditIcon
                            onClick={() => {
                              handleEdit(data, data.id);
                            }}
                            sx={{ cursor: 'pointer', color: '#107FAA' }}
                          />
                        </Grid>
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          item
                        >
                          <DeleteForeverIcon
                            onClick={() => {
                              deleteUser(data, data.id);
                            }}
                            sx={{ cursor: 'pointer', color: 'red' }}
                          />
                        </Grid>
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          title="Ativar/desativar usuário"
                          item
                        >
                          <PowerSettingsNewIcon
                            onClick={() => {
                              enableOrDisableUser(data);
                            }}
                            sx={{
                              cursor: 'pointer',
                              color: colorPowerFunction(data.enabled),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </>
          ))}
          {loadingProgress && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                className={classes.LinearProgress}
                variant="indeterminate"
              />
            </Box>
          )}
        </Box>
      </Styled.Container>

      <Modal status={modalStatus} setStatus={setModalStatus}>
        <NewUser />
      </Modal>

      {openModal && (
        <EditModalUser
          getId={getId}
          getUser={getUser}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}

      {openModalConfirmation && (
        <DeleteModalConfirmation
          getId={getId}
          getUser={getUser}
          open={openModalConfirmation}
          setOpen={setOpenModalConfirmation}
        />
      )}
    </>
  );
}

export default ListUsers;
