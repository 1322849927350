import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const BoxTitle = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 20px 0;
  display: flex;
`;

export const Title = styled.h1`
  color: #00a4e1;
  font-size: 24px;
`;

export const BoxSignUp = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  border: 1px solid #ccc;
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 30px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 845px;
  gap: 10px;

  @media (max-width: 425px) {
    justify-content: center;
    flex-direction: column;
  }
`;
