import { useCallback } from 'react';
import { api } from '../services/api';

export default function admissionTypeClient() {
  const getAdmissionType = useCallback(
    () => api.get('/AdmissionType').then((response) => response),
    []
  );

  const newAdmissionType = useCallback(
    (data) => api.post('/AdmissionType', data).then((response) => response),
    []
  );

  const deleteAdmissionType = useCallback(
    (id) =>
      api
        .delete(`/AdmissionType`, { params: { id } })
        .then((response) => response),
    []
  );

  const getByIdAdmissionType = useCallback(
    (id) => api.get(`/AdmissionType/${id}`).then((response) => response),
    []
  );

  const editByIdAdmissionType = useCallback(
    (id, data) =>
      api.put(`/AdmissionType/${id}`, data).then((response) => response),
    []
  );

  return useCallback(
    () => ({
      getAdmissionType,
      newAdmissionType,
      deleteAdmissionType,
      getByIdAdmissionType,
      editByIdAdmissionType,
    }),
    [
      getAdmissionType,
      newAdmissionType,
      deleteAdmissionType,
      getByIdAdmissionType,
      editByIdAdmissionType,
    ]
  );
}
