import styled from 'styled-components';
import image from '../../assets/img/login/backgroundLogin.png';

export const Container = styled.div` 
  display: flex;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(52, 52, 52, 0),
      rgba(15, 15, 15, 1)
    ),
    url(${image});

  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 725px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 200px;

  @media (max-width: 725px) {
    padding: 0px;
  }
`;

export const FirstArea = styled.div`
  flex: 1;
  padding: 150px 0;

  @media (max-width: 725px) {
    padding-left: 150px;
    padding-top: 55px;
  }
`;

export const SecondArea = styled.div`
  flex: 1;
  padding: 55px 10px;

  @media (max-width: 725px) {
    flex: 1;
    padding-left: 60px;
    margin-top: -115px;
  }
`;

export const Logo = styled.img`
  display: flex;
  width: 250px;
  height: 250px;
  margin: -100px;

  @media (max-width: 568px) {
    margin: -100px;
    margin-left: 10px;
    margin-top: 1px;
  }
`;

export const TextAreaBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: -100px;

  padding: 92px 148px 0 0;

  @media (max-width: 725px) {
    padding: 60px 10px 0 0;
    margin-right: 20px;
    margin-top: -10px;
  }

  @media (max-width: 568px) {
    display: none;
  }
`;

export const InfoText = styled.h1`
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
`;

export const ParagraphyText = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export const Banner = styled.img`
  width: 100%;
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 10px 255px 10px;

  @media (max-width: 425px) {
    padding: 0;
  }
`;
