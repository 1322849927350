import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Modal,
} from '@mui/material';
import { CleaningServicesRounded } from '@mui/icons-material';
import { useFormik } from 'formik';

import { useTheme } from 'styled-components';
import Button from '../Button';

import * as Styled from './styled';
import { AppContext } from '../../contexts/sidebarContext';

export default function EmployeeSearch({
  list,
  openModal,
  setOpenModal,
  showInactiveItens,
  setShowInactiveItens,
}) {
  const theme = useTheme();
  const context = useContext(AppContext);
  const { filteringList, setPayloadSearch, payloadSearch } = context;

  const filter = (payload, listdata) => {
    filteringList(list);
    const mergeDedupe = (arr) => [...new Set([].concat(...arr))];

    const listArray = [];

    if (payload.name) {
      listArray[0] = listdata.filter((item) =>
        item.name?.toLowerCase().includes(payload.name.toLowerCase())
      );
    }
    if (payload.cpf) {
      const listArraySec = [];
      listArraySec[0] = (
        listArray.length !== 0 ? listArray[0] : listdata
      ).filter((item) =>
        item.cpf?.toLowerCase().includes(payload.cpf.toLowerCase())
      );
      // eslint-disable-next-line prefer-destructuring
      listArray[0] = listArraySec[0];
    }
    if (payload.job) {
      const lisArraySec = [];
      lisArraySec[0] = (
        listArray.length !== 0 ? listArray[0] : listdata
      ).filter((item) =>
        item.jobTitleName?.toLowerCase().includes(payload.job.toLowerCase())
      );
      // eslint-disable-next-line prefer-destructuring
      listArray[0] = lisArraySec[0];
    }
    if (payload.admissionType) {
      const lisArraySec = [];
      lisArraySec[0] = (
        listArray.length !== 0 ? listArray[0] : listdata
      ).filter((item) =>
        item.admissionTypeName
          ?.toLowerCase()
          .includes(payload.admissionType.toLowerCase())
      );
      // eslint-disable-next-line prefer-destructuring
      listArray[0] = lisArraySec[0];
    }
    if (payload.sector) {
      const lisArraySec = [];
      lisArraySec[0] = (
        listArray.length !== 0 ? listArray[0] : listdata
      ).filter((item) =>
        item.sectorName?.toLowerCase().includes(payload.sector.toLowerCase())
      );
      // eslint-disable-next-line prefer-destructuring
      listArray[0] = lisArraySec[0];
    }

    if (payload.doc) {
      const lisArraySec = [];
      lisArraySec[0] = (
        listArray.length !== 0 ? listArray[0] : listdata
      ).filter((empl) =>
        empl.documentTypes.some((item) =>
          item.name?.toLowerCase().includes(payload.doc.toLowerCase())
        )
      );
      // eslint-disable-next-line prefer-destructuring
      listArray[0] = lisArraySec[0];
    }

    filteringList(listArray.length === 0 ? list : mergeDedupe(listArray));
  };

  const DEFAULT_VALUES = {
    name: payloadSearch.name ?? '',
    cpf: payloadSearch.cpf ?? '',
    job: payloadSearch.job ?? '',
    admissionType: payloadSearch.admissionType ?? '',
    sector: payloadSearch.sector ?? '',
    doc: payloadSearch.doc ?? '',
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT_VALUES },

    onSubmit: (data) => {
      const payload = {
        name: data.name,
        cpf: data.cpf,
        job: data.job,
        admissionType: data.admissionType,
        sector: data.sector,
        doc: data.doc,
      };
      filter(payload, list);
      setPayloadSearch(payload);
    },
  });

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSubmit = () => {
    formik.submitForm();
    handleClose();
  };

  const resetForm = () => {
    formik.resetForm();
    filteringList(list);
    setPayloadSearch({});
    formik.setFieldValue('cpf', '');
    formik.setFieldValue('name', '');
    formik.setFieldValue('job', '');
    formik.setFieldValue('admissionType', '');
    formik.setFieldValue('sector', '');
    formik.setFieldValue('doc', '');
  };

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
    >
      <Styled.Container>
        <Styled.BoxSignUp>
          <Box sx={{ color: '#00000099' }} ml={4} mt={3} mb={2}>
            Filtrar por:
          </Box>
          <Styled.InputAreaWrapper>
            <Styled.FirstRow>
              <Grid container direction="column" padding={2} spacing={2}>
                <Grid container item direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel>Nome</InputLabel>
                    <Input
                      fullWidth
                      title="nome"
                      name="name"
                      type="text"
                      {...formik.getFieldProps('name')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>CPF</InputLabel>
                    <Input
                      fullWidth
                      title="cpf"
                      name="cpf"
                      type="text"
                      {...formik.getFieldProps('cpf')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Cargos</InputLabel>
                    <Input
                      fullWidth
                      title="Cargos"
                      name="job"
                      type="text"
                      {...formik.getFieldProps('job')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Tipos de Admissão</InputLabel>
                    <Input
                      fullWidth
                      title="Tipos de Admissão"
                      name="admissionType"
                      type="text"
                      {...formik.getFieldProps('admissionType')}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel>Setor</InputLabel>
                    <Input
                      fullWidth
                      title="Setor"
                      name="sector"
                      type="text"
                      {...formik.getFieldProps('sector')}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel>Documentos</InputLabel>
                    <Input
                      fullWidth
                      title="Documentos"
                      name="doc"
                      type="text"
                      {...formik.getFieldProps('doc')}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex" justifyContent="center">
                    <Button
                      text={
                        <Grid container>
                          <Grid
                            Item
                            xs={8}
                            display="flex"
                            justifyContent="center"
                            marginTop="4px"
                          >
                            Limpar
                          </Grid>
                          <Grid item xs={4} display="flex">
                            <CleaningServicesRounded />
                          </Grid>
                        </Grid>
                      }
                      type="send"
                      width="150px"
                      height="45px"
                      onClick={resetForm}
                    />
                  </Grid>
                  <Grid mt={4} item xs={12}>
                    <FormControlLabel
                      sx={{
                        '& .MuiTypography-root': {
                          display: 'flex',
                          fontSize: '15px !important',
                          fontStyle: 'normal !important',
                          alignItems: 'center !important',
                          fontFamily: 'Montserrat !important',
                          fontWeight: 600,
                          color: '#107faa !important',
                          '@media (max-width: 1366px)': {
                            fontSize: '12px !important',
                          },
                        },
                      }}
                      label="Mostrar inativos"
                      control={
                        <Checkbox
                          checked={showInactiveItens}
                          onChange={() => {
                            setShowInactiveItens(!showInactiveItens);
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: theme.background.blue,
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid mt={4} item xs={12}>
                    <Button
                      text="Pesquisar"
                      type="send"
                      width="100%"
                      height="72px"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Styled.FirstRow>
          </Styled.InputAreaWrapper>
        </Styled.BoxSignUp>
      </Styled.Container>
    </Modal>
  );
}

EmployeeSearch.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object]).isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  showInactiveItens: PropTypes.bool,
  setShowInactiveItens: PropTypes.func.isRequired,
};

EmployeeSearch.defaultProps = {
  openModal: false,
  showInactiveItens: false,
};
