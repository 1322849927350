import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  CancelOutlined,
  CheckCircleOutline,
  WarningAmberOutlined,
  Close,
} from '@mui/icons-material';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paper: {
    maxHeight: '100%',
    position: 'relative',
    padding: '10px',
    width: '480px',
    maxWidth: '580px',
  },

  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '16px',
  },
}));

export default function EmployeeModalConfirmation({ resp }) {
  const classes = useStyles();

  const [responseError, setResponseError] = useState();
  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(true);
    let responsedata = resp.status
      ? { status: resp.status, message: resp.message }
      : { status: 0, message: 'Erro!' };

    const status = resp?.response?.response?.status;
    const messageBack = resp?.response?.response?.data;

    if (
      resp?.response?.status === 200 ||
      resp?.response?.status === 201 ||
      resp?.response?.status === 204
    ) {
      responsedata = {
        status: resp?.response?.status,
        message: resp.message,
      };
    }

    if (status === 404) {
      responsedata = {
        status,
        message: Array.isArray(messageBack)
          ? messageBack.map((msg) => msg.message)[0]
          : 'Serviço Indisponível ou não encontrado!',
      };
    }

    if (status === 401) {
      responsedata = {
        status,
        message: Array.isArray(messageBack)
          ? messageBack.map((msg) => msg.message)[0]
          : 'Usuário não autorizado!',
      };
    }

    if (status === 403) {
      responsedata = {
        status,
        message: Array.isArray(messageBack)
          ? messageBack.map((msg) => msg.message)[0]
          : 'Usuário sem permissão!',
      };
    }

    if (status === 500 || status === 400) {
      responsedata = {
        status,
        message: Array.isArray(messageBack)
          ? messageBack.map((msg) => msg.message)[0]
          : `Erro Interno - ${status}. Contate o administrador do sistema!`,
      };
    }

    setResponseError(responsedata);
  }, [resp]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      close
      onClose={() => {
        setOpen(false);
        window.location.reload();
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Paper className={classes.paper}>
          <Close
            title="Fechar"
            className={classes.close}
            onClick={handleClose}
          />
          <Box mt={2} mb={4}>
            <Grid display="flex" alignItems="center" justifyContent="center">
              {(responseError?.status === 200 ||
                responseError?.status === 201 ||
                responseError?.status === 204) && (
                <CheckCircleOutline color="success" sx={{ fontSize: 70 }} />
              )}
              {(responseError?.status === 400 ||
                responseError?.status === 404 ||
                responseError?.status === 500 ||
                responseError?.status === 0) && (
                <CancelOutlined color="error" sx={{ fontSize: 70 }} />
              )}
              {(responseError?.status === 401 ||
                responseError?.status === 403) && (
                <WarningAmberOutlined color="warning" sx={{ fontSize: 70 }} />
              )}
            </Grid>
          </Box>

          <Box mb={3}>
            <Typography align="center" variant="h6">
              {responseError ? responseError?.message : 'Erro'}
            </Typography>
          </Box>
          <Box m={2} display="flex" alignItems="center" justifyContent="center">
            <Button
              sx={{ width: '150px', height: '40px', fontSize: '18px' }}
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
EmployeeModalConfirmation.propTypes = {
  resp: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
