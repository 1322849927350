import React, { useContext } from 'react';
import {
  Box,
  Grid,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from 'styled-components';
import Button from '../Button';
import documentTypeClient from '../../clients/documentTypeClient';
import { AppContext } from '../../contexts/sidebarContext';
import {
  documentTypeCategories,
  documentTypeCategoriesToAPIType,
} from '../../helpers/constants';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
  },
  labelModal: {
    color: '#00A4E1',
    fontSize: '24px',
    fontFamily: 'Montserrat',
  },
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

function EditModalUser({ openModal, setOpenModal, getId, getDocument }) {
  const classes = useStyles();

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const handleClose = () => setOpenModal(false);

  const userDocument = documentTypeClient();

  const DEFAULT = {
    name: getDocument?.name,
    description: getDocument?.description,
    enabled: !getDocument?.enabled,
    categoryDocumentType: getDocument?.categoryDocumentType,
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obrigatório'),
      description: Yup.string(),
      enabled: Yup.bool(),
      categoryDocumentType: Yup.string().required('Campo obrigatório'),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        name: data.name,
        description: data.description,
        enabled: !data.enabled,
        tenantId: useTenantId,
        categoryDocumentType: data.categoryDocumentType,
      };

      userDocument()
        .editByIdDocumentType(getId, payload)
        .then(
          (response) => {
            msgAlert({
              response,
              message: 'Documento atualizado com sucesso!',
            });
            handleClose();
          },
          (resp) => {
            msgAlert({
              response: resp,
            });
            handleClose();
          }
        );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const theme = useTheme();

  const alphabetOrder = (array, prop) =>
    array?.sort((a, b) => {
      if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return (
    <Modal open={openModal} onClose={handleClose} closeAfterTransition>
      <Box className={classes.modal}>
        <Paper sx={{ padding: '30px', minWidth: '960px' }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{
                  width: '100%',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '30px',
                  paddingTop: '20px',
                  marginBottom: '40px',
                }}
              >
                Editar Documentos
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.name && formik.errors.name}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: CNH"
                    name="name"
                    label="Nome do documento"
                    className={classes.textField}
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && formik.errors.name}
                  />
                  <FormHelperText
                    hidden={!formik.touched.name || !formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                  >
                    {formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.description && formik.errors.description
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: Carteira de habilitação"
                    name="descriptionNumber"
                    label="Descrição do documento"
                    className={classes.textField}
                    {...formik.getFieldProps('description')}
                    error={
                      formik.touched.description && formik.errors.description
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.description || !formik.errors.description
                    }
                    error={
                      formik.touched.description && !!formik.errors.description
                    }
                  >
                    {formik.errors.description}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.textField}
                  error={
                    formik.touched.categoryDocumentType &&
                    formik.errors.categoryDocumentType
                  }
                >
                  <InputLabel id="document-category-type" required>
                    Categoria do Documento
                  </InputLabel>
                  <Select
                    required
                    labelId="document-category-type"
                    label="Categoria do documento"
                    name="documentTypeId"
                    {...formik.getFieldProps('categoryDocumentType')}
                  >
                    {alphabetOrder(Object.keys(documentTypeCategories), 0)?.map(
                      (key) => (
                        <MenuItem
                          key={key}
                          value={documentTypeCategoriesToAPIType[key]}
                        >
                          {documentTypeCategories[key]}
                        </MenuItem>
                      )
                    ) ?? []}
                  </Select>

                  <FormHelperText
                    hidden={
                      !formik.touched.categoryDocumentType ||
                      !formik.errors.categoryDocumentType
                    }
                    error={
                      formik.touched.categoryDocumentType &&
                      !!formik.errors.categoryDocumentType
                    }
                  >
                    {formik.errors.categoryDocumentType}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs>
                  <FormControlLabel
                    sx={{
                      color: theme.text.darkGray,
                    }}
                    label="Desabilitar Documento"
                    control={
                      <Checkbox
                        checked={formik.values.enabled}
                        onChange={(event) => {
                          formik.setFieldValue('enabled', event.target.checked);
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: theme.background.blue,
                          },
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={3}
            flex={1}
            my={3}
            display="flex"
            justifyContent="end"
          >
            <Grid item xs={8} sm={4} md={3} lg={6}>
              <Button
                text="Cancelar"
                type="send"
                onClick={handleClose}
                width="100%"
                height="72px"
                whiteButton
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3} lg={6}>
              <Button
                text="Salvar"
                type="send"
                onClick={handleSubmit}
                width="100%"
                height="72px"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

EditModalUser.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  getId: PropTypes.string,
  getDocument: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    categoryDocumentType: PropTypes.string,
  }),
};

EditModalUser.defaultProps = {
  openModal: false,
  getId: '',
  getDocument: {},
};

export default EditModalUser;
