import React from 'react';
import { Box, Modal, Paper, Typography, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EmployData from './EmployData';
// import CertificateUpload from './CertificateUpload';
// import DeclarationsUpload from './DeclarationsUpload';
// import PublicActs from './PublicActs';
// import FunctionalLife from './FunctionalLife';
import { useEmployContext } from './EmployContext';
import DependentArea from './DependentArea';
import EmployeeDocument from './EmployeeDocument';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
  },
  modalBox: {
    width: '100%',
    maxWidth: '85vw',
    maxHeight: '90%',
    top: '50%',
    left: '0',
    overflowY: 'auto',
    margin: 'auto',
    borderRadius: '10px',
  },
  PaperModal: {
    background: '#FFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
  },
  labelModal: {
    fontSize: '32px',
    fontWeight: 700,
  },
  navBarModal: {
    minHeight: '100vh',
    boxSizing: 'border-box',
    width: '314px',
    height: '100%',
    maxHeight: '843px',
    position: 'sticky',
    top: 0,
    padding: '50px',
    color: '#fff',
    backgroundColor: '#107FAA',
    '@media (max-width: 1366px)': {
      width: '218px',
      padding: '30px',
      height: '100%',
      maxHeight: '843px',
    },
  },
}));

function NewEmployeesModal({ openModal, setOpenModal }) {
  const classes = useStyles();

  const { tabValue, setTabValue } = useEmployContext();

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload();
  };

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={openModal}
      closeAfterTransition
    >
      <Box className={classes.modalBox}>
        <Paper className={classes.PaperModal}>
          <Grid display="flex" container>
            <Grid item xs={3}>
              <Box className={classes.navBarModal}>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '28px',
                    lineHeight: '35px',
                    marginBottom: '120px',
                    '@media (max-width: 1366px)': {
                      fontSize: '18px',
                      marginBottom: '50px',
                    },
                  }}
                  mb={4}
                >
                  Cadastro do Colaborador
                </Typography>
                <Grid
                  sx={{ '@media (max-width: 1366px)': { fontSize: '12px' } }}
                  container
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: 'pointer', height: '45px' }}
                      onClick={() => setTabValue('1')}
                    >
                      Dados do Colaborador
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: 'pointer', height: '45px' }}
                      onClick={() => setTabValue('2')}
                    >
                      Dependente
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: 'pointer', height: '45px' }}
                      onClick={() => setTabValue('3')}
                    >
                      Exames
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: 'pointer', height: '45px' }}
                      onClick={() => setTabValue('4')}
                    >
                      Certidões
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: 'pointer', height: '45px' }}
                      onClick={() => setTabValue('5')}
                    >
                      Declarações
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: 'pointer', height: '45px' }}
                      onClick={() => setTabValue('7')}
                    >
                      Histórico Funcional
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box p={1}>
                <Grid container display="flex" justifyContent="end">
                  <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </Grid>
              </Box>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <Grid hidden={tabValue !== '1'}>
                  <EmployData />
                </Grid>
                <Grid hidden={tabValue !== '2'}>
                  <DependentArea />
                </Grid>
                <Grid hidden={tabValue !== '3'}>
                  <EmployeeDocument categoryType="Exames" tabId="3" />
                </Grid>
                <Grid hidden={tabValue !== '4'}>
                  <EmployeeDocument categoryType="Certidoes" tabId="4" />
                </Grid>
                <Grid hidden={tabValue !== '5'}>
                  <EmployeeDocument categoryType="Declaracoes" tabId="5" />
                </Grid>
                <Grid hidden={tabValue !== '7'}>
                  <EmployeeDocument
                    categoryType="HistoricoFuncional"
                    tabId="7"
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

NewEmployeesModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
};

NewEmployeesModal.defaultProps = {
  openModal: false,
};

export default NewEmployeesModal;
