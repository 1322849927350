import * as Styled from './styled';
import LogoAgia from '../../assets/img/login/logo.png';
import Login from '../../components/Login';

function NewLoginPage() {
  return (
    <Styled.Container>
      <Styled.LeftSide>
        <Styled.FirstArea>
          <Styled.Logo src={LogoAgia} alt="Logo FOCCUS" />

          <Styled.TextAreaBox>
            <Styled.InfoText>Seja bem-vindo ao e-Pessoal!</Styled.InfoText>
            <Styled.ParagraphyText>
              Digitalize o departamento pessoal de forma simples e eficiente.
              Torne a rotina do seu RH mais digital e produtiva. Interface
              intuitiva e moderna. Implementação ágil.{' '}
            </Styled.ParagraphyText>
          </Styled.TextAreaBox>
        </Styled.FirstArea>
      </Styled.LeftSide>
      <Styled.SecondArea>
        <Login />
      </Styled.SecondArea>
    </Styled.Container>
  );
}

export default NewLoginPage;
