export const documentTypeCategories = {
  DocumentosPessoais: 'Documentos Pessoais',
  Exames: 'Exames',
  Certidoes: 'Certidões',
  Declaracoes: 'Declarações',
  Requerimentos: 'Requerimentos',
  ConcursoPublico: 'Concurso Público',
  ProcessoSeletivoSimplificado: 'Processo Seletivo Simplificado',
  AtosAdministrativos: 'Atos Administrativos',
  Contratos: 'Contratos',
  Atestados: 'Atestados',
  EstagioProbatorio: 'Estágio Probatório',
  Processos: 'Processos',
  DocumentosGovernamentais: 'Documentos Governamentais',
  Legislacao: 'Legislação',
  AvaliacaoDeProdutividade: 'Avaliação De Produtividade',
  Capacitacoes: 'Capacitacões',
  Diversos: 'Diversos',
};

export const documentTypeCategoriesToAPIType = {
  DocumentosPessoais: 'DocumentosPessoais',
  Exames: 'Exames',
  Certidoes: 'Certidões',
  Declaracoes: 'Declarações',
  Requerimentos: 'Requerimentos',
  ConcursoPublico: 'ConcursoPublico',
  ProcessoSeletivoSimplificado: 'ProcessoSeletivoSimplificado',
  AtosAdministrativos: 'AtosAdministrativos',
  Contratos: 'Contratos',
  Atestados: 'Atestados',
  EstagioProbatorio: 'EstagioProbatorio',
  Processos: 'Processos',
  DocumentosGovernamentais: 'DocumentosGovernamentais',
  Legislacao: 'Legislacao',
  AvaliacaoDeProdutividade: 'AvaliacaoDeProdutividade',
  Capacitacoes: 'Capacitacoes',
  Diversos: 'Diversos',
};
