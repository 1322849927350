import { useCallback } from 'react'
import { api } from '../services/api'

export default function EmployeeDependentClient() {
    const getDependentByEmployeeId = useCallback((id) => api.get(`/EmployeeDependent/Employee/${id}`).then((response) => response.data), [])

    const getEmployeeDependent = useCallback(() => api.get('/EmployeeDependent').then((response) => response.data), [])

    const newEmployeeDependent = useCallback((data) => api.post('/EmployeeDependent', data).then((response) => response), [])

    const deleteDocumentType = useCallback((id) => api.delete('/EmployeeDependent', { params: { id } }).then((response) => response), [])

    const getByIdEmployeeDependent = useCallback((id) => api.get(`/EmployeeDependent/${id}`).then((response) => response.data), [])

    const editByIdEmployeeDependent = useCallback((id, data) => api.put(`/EmployeeDependent/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getDependentByEmployeeId,
        getEmployeeDependent,
        newEmployeeDependent,
        deleteDocumentType,
        getByIdEmployeeDependent,
        editByIdEmployeeDependent
    }), [
        getDependentByEmployeeId,
        getEmployeeDependent,
        newEmployeeDependent,
        deleteDocumentType,
        getByIdEmployeeDependent,
        editByIdEmployeeDependent
    ]
    )
}