import React, { useState, useEffect, useCallback } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import dayjs from 'dayjs';
// import 'dayjs/locale/pt-br';

import moment from 'moment';
import 'moment/locale/pt-br';

import { DateTime } from 'luxon';

import { useEmployContext } from './EmployContext';

import officeClient from '../../clients/officeClient';
import sectorTypeClient from '../../clients/sectorTypeClient';
import admissionTypeClient from '../../clients/admissionTypeClient';
import EmployeeRegisterClient from '../../clients/employeeRegisterClient';

// import EmployeeDocs from './EmployDocs';
import { Enviroment } from '../../environments';
import EmployeeDocument from './EmployeeDocument';

// dayjs.locale('pt-br');
moment.locale('pt-br');

const useStyles = makeStyles(() => ({
  boxData: {
    borderRadius: '4px',
    padding: '24px',
  },
  buttonModal: {
    fontSize: '20px',
  },
  labelTab: {
    fontSize: '20px',
    fontWeight: 700,
  },
  placeholder: {
    color: 'rgb(192, 192, 192)',
    font: 'inherit',
    letterSpacing: 'inherit',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
  },
}));

const RACE_TYPE = [
  { race: 'Branca' },
  { race: 'Parda' },
  { race: 'Preta' },
  { race: 'Amarela' },
  { race: 'Indigena' },
];

const STATUS_TYPE = ['Ativo', 'Inativo'];

const EDUCATION_LEVEL_TYPE = [
  {
    value: 'Analfabeto',
    label: 'Analfabeto',
  },
  {
    value: 'Alfabetizado',
    label: 'Alfabetizado',
  },
  {
    value: 'EnsinoFundamentalIncompleto',
    label: 'Ensino Fundamental Incompleto',
  },
  {
    value: 'EnsinoFundamentalCompleto',
    label: 'Ensino Fundamental Completo',
  },
  {
    value: 'EnsinoMedioIncompleto',
    label: 'Ensino Médio Incompleto',
  },
  {
    value: 'EnsinoMedioCompleto',
    label: 'Ensino Médio Completo',
  },
  {
    value: 'EnsinoTecnico',
    label: 'Ensino Técnico',
  },
  {
    value: 'EnsinoSuperiorIncompleto',
    label: 'Ensino Superior Incompleto',
  },
  {
    value: 'EnsinoSuperiorCompleto',
    label: 'Ensino Superior Completo',
  },
  {
    value: 'PosGraduado',
    label: 'Pós-Graduado',
  },
  {
    value: 'Mestrado',
    label: 'Mestrado',
  },
  {
    value: 'Doutorado',
    label: 'Doutorado',
  },
];

const MARITIAL_STATUS_TYPE = [
  {
    value: 'Solteiro',
    label: 'Solteiro',
  },
  {
    value: 'Casado',
    label: 'Casado',
  },
  {
    value: 'Divorciado',
    label: 'Divorciado',
  },
  {
    value: 'Viuvo',
    label: 'Viúvo',
  },
  {
    value: 'UniaoEstavel',
    label: 'União Estável',
  },
];

const GENDER_TYPE = [
  {
    gender: 'Feminino',
  },
  {
    gender: 'Masculino',
  },
  {
    gender: 'Outro',
  },
];

const HAS_DISABILITY = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const HAS_DEPENDENT = {
  YES: true,
  NO: false,
};

export default function EmployData() {
  const classes = useStyles();

  const EMPLOYEE_DATA = true;

  const useEmployClient = EmployeeRegisterClient();

  const {
    tabValue,
    setTabValue,
    setEmployId,
    employId,
    employCurrentData,
    employeeMsgAlert,
  } = useEmployContext();

  const useOffice = officeClient();
  const useSector = sectorTypeClient();
  const useAdmissionClient = admissionTypeClient();

  const [jobType, setJobType] = useState([]);
  const [sectorType, setSectorType] = useState([]);
  const [nextButtons, setNextButtons] = useState(false);
  const [validateDate, setValidateDate] = useState(true);
  const [admissionType, setAdmissionType] = useState([]);
  const [admissionUploadDoc, setAdmissionUploadDoc] = useState();
  const [positionTextValue, setPositionTextValue] = useState('');
  const [positionValue, setPositionValue] = useState({ value: 0, label: '' });
  const [admissionTextValue, setAdmissionTextValue] = useState('');
  const [admissionValue, setAdmissionValue] = useState({ value: 0, label: '' });
  const [sectorTextValue, setSectorTextValue] = useState('');
  const [sectorValue, setSectorValue] = useState({ value: 0, label: '' });

  const [checkedFirst, setCheckedFirst] = useState(false);
  const [checkedSecond, setCheckedSecond] = useState(false);

  const formatDate = (date) =>
    date && DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd');

  useEffect(() => {
    if (employCurrentData?.id) {
      setEmployId(employCurrentData?.id);
      setNextButtons(true);
    }

    if (employCurrentData?.jobTitleId) {
      useOffice()
        .getByIdOffice(employCurrentData?.jobTitleId)
        .then((response) => {
          setPositionValue({
            value: response?.data?.id,
            label: response?.data?.name,
          });
        });
    }

    if (employCurrentData?.sectorId) {
      useSector()
        .getSectorById(employCurrentData?.sectorId)
        .then((response) => {
          setSectorValue({
            value: response?.id,
            label: response?.name,
          });
        });
    }

    if (employCurrentData?.admissionTypeId) {
      useAdmissionClient()
        .getByIdAdmissionType(employCurrentData?.admissionTypeId)
        .then(
          (response) => {
            setAdmissionValue({
              value: response?.data?.id,
              label: response?.data?.name,
            });
          },
          () => {
            setAdmissionValue({});
          }
        );
    }
  }, [setPositionValue, setSectorValue, setAdmissionValue]);

  useEffect(() => {
    useOffice()
      .getOffice()
      .then((response) => {
        if (response) {
          setJobType(
            response.data.map((result) => ({
              label: result?.name,
              value: result?.id,
            }))
          );
        } else {
          setJobType([]);
        }
      });

    useSector()
      .getSector()
      .then((response) => {
        if (response) {
          setSectorType(
            response.map((result) => ({
              label: result?.name,
              value: result?.id,
            }))
          );
        } else {
          setSectorType([]);
        }
      });

    useAdmissionClient()
      .getAdmissionType()
      .then((response) => {
        if (response) {
          setAdmissionType(
            response.data.map((result) => ({
              label: result?.name,
              value: result?.id,
            }))
          );
        } else {
          setAdmissionType([]);
        }
      });
  }, [setJobType, setSectorType, setAdmissionType, admissionUploadDoc]);

  const DEFAULT = {
    status: employCurrentData?.status ?? 'Ativo',
    name: employCurrentData?.name ?? '',
    cpf: employCurrentData?.cpf ?? '',
    jobTitleId: employCurrentData?.jobTitleId ?? '',
    sectorId: employCurrentData?.sectorId ?? '',
    admissionTypeId: employCurrentData?.admissionTypeId ?? '',
    registryNumber: employCurrentData?.registryNumber ?? '',
    nationalIdNumber: employCurrentData?.nationalIdNumber ?? '',
    nationalIdIssuer: employCurrentData?.nationalIdIssuer ?? '',
    nationalIdIssuedDate:
      formatDate(employCurrentData?.nationalIdIssuedDate) ?? '',
    electorIdNumber: employCurrentData?.electorIdNumber ?? '',
    electorIdSeries: employCurrentData?.electorIdSeries ?? '',
    electorIdState: employCurrentData?.electorIdState ?? '',
    electorIdIssuedDate:
      formatDate(employCurrentData?.electorIdIssuedDate) ?? '',
    workerIdNumber: employCurrentData?.workerIdNumber ?? '',
    workerIdSeries: employCurrentData?.workerIdSeries ?? '',
    pispasep: employCurrentData?.pispasep ?? '',
    veteranIdNumber: employCurrentData?.veteranIdNumber ?? '',
    gender: employCurrentData?.gender ?? '',
    hasDisability: employCurrentData?.hasDisability ?? '',
    birthDate: formatDate(employCurrentData?.birthDate) ?? '',
    birthPlace: employCurrentData?.birthPlace ?? '',
    nationality: employCurrentData?.nationality ?? '',
    race: employCurrentData?.race ?? '',
    educationLevel: employCurrentData?.educationLevel ?? '',
    maritialStatus: employCurrentData?.maritialStatus ?? '',
    fatherName: employCurrentData?.fatherName ?? '',
    motherName: employCurrentData?.motherName ?? '',
    address: employCurrentData?.address ?? '',
    district: employCurrentData?.district ?? '',
    city: employCurrentData?.city ?? '',
    state: employCurrentData?.state ?? '',
    hasDependents: employCurrentData?.hasDependents ?? '',
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      status: Yup.string().required('Campo obrigatório'),
      name: Yup.string().required('Campo obrigatório'),
      cpf: Yup.string().required('Campo obrigatório'),
      jobTitleId: Yup.string().required('Campo obrigatório'),
      sectorId: Yup.string().required('Campo obrigatório'),
      admissionTypeId: Yup.string().required('Campo obrigatório'),
      registryNumber: Yup.string().required('Campo obrigatório'),
      nationalIdNumber: Yup.string().required('Campo obrigatório'),
      nationalIdIssuer: Yup.string().required('Campo obrigatório'),
      nationalIdIssuedDate: Yup.date()
        .nullable()
        .required('Insira uma data válida')
        .test(
          'not-in-future',
          'A data de emissão não pode estar no futuro',
          (value) => {
            const today = moment().startOf('day');
            const dateValue = moment(value);
            return dateValue.isValid() && dateValue.isSameOrBefore(today);
          }
        ),
      electorIdNumber: Yup.string().required('Campo obrigatório'),
      electorIdSeries: Yup.string().required('Campo obrigatório'),
      electorIdState: Yup.string().required('Campo obrigatório'),
      electorIdIssuedDate: Yup.date()
        .nullable()
        .required('Insira uma data válida')
        .test(
          'not-in-future',
          'A data de emissão não pode estar no futuro',
          (value) => {
            const today = moment().startOf('day');
            const dateValue = moment(value);
            return dateValue.isValid() && dateValue.isSameOrBefore(today);
          }
        ),
      workerIdNumber: Yup.string().required('Campo obrigatório'),
      workerIdSeries: Yup.string().required('Campo obrigatório'),
      pispasep: Yup.string().required('Campo obrigatório'),
      veteranIdNumber: Yup.string(),
      gender: Yup.string().required('Campo obrigatório'),
      hasDisability: Yup.bool(),
      birthDate: Yup.date()
        .nullable()
        .required('Insira uma data válida')
        .test(
          'not-in-future',
          'A data de emissão não pode estar no futuro',
          (value) => {
            const today = moment().startOf('day');
            const dateValue = moment(value);
            return dateValue.isValid() && dateValue.isSameOrBefore(today);
          }
        ),
      birthPlace: Yup.string().required('Campo obrigatório'),
      nationality: Yup.string().required('Campo obrigatório'),
      race: Yup.string().required('Campo obrigatório'),
      educationLevel: Yup.string().required('Campo obrigatório'),
      maritialStatus: Yup.string().required('Campo obrigatório'),
      fatherName: Yup.string(),
      motherName: Yup.string().required('Campo obrigatório'),
      address: Yup.string().required('Campo obrigatório'),
      district: Yup.string().required('Campo obrigatório'),
      city: Yup.string().required('Campo obrigatório'),
      state: Yup.string().required('Campo obrigatório'),
      hasDependents: Yup.bool().required('Campo obrigatório'),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        ...data,
        tenantId: useTenantId,
      };

      if (validateDate) {
        if (employId) {
          useEmployClient()
            .editByIdEmployee(employId, payload)
            .then(
              (response) => {
                employeeMsgAlert({
                  response,
                  message: 'Colaborador atualizado com sucesso!',
                });
              },
              (resp) => {
                employeeMsgAlert({
                  response: resp,
                });
              }
            );
        } else {
          useEmployClient()
            .newEmployee(payload)
            .then(
              (response) => {
                employeeMsgAlert({
                  response,
                  message: 'Colaborador cadastrado com sucesso!',
                });
                setEmployId(response.data.id);
                setNextButtons(true);
              },
              (resp) => {
                employeeMsgAlert({
                  response: resp,
                });
              }
            );
        }
      }
    },
  });

  const handleChangeDisability = (event) => {
    formik.setFieldValue('hasDisability', event?.target?.value);
  };

  const handleChangeGender = (event) => {
    formik.setFieldValue('gender', event?.target?.value);
  };

  const handleChangeRace = (event) => {
    formik.setFieldValue('race', event?.target?.value);
  };

  const handleChangeStatus = (event) => {
    formik.setFieldValue('status', event?.target?.value);
  };

  const handleChangeEducationLevel = (event) => {
    formik.setFieldValue('educationLevel', event?.target?.value);
  };

  const handleChangeMaritialStatus = (event) => {
    formik.setFieldValue('maritialStatus', event?.target?.value);
  };

  const handleChangeDependents = (event) => {
    if (event?.target?.value === 'false') {
      formik.setFieldValue('hasDependents', false);
    } else {
      formik.setFieldValue('hasDependents', true);
    }
  };

  const formatCpf = (value) => {
    if (value !== '' && value !== null && value !== undefined) {
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return undefined;
  };

  const formatPisPasep = (value) => {
    if (value !== '' && value !== null && value !== undefined) {
      return value.replace(/^(\d{3})(\d{5})(\d{2})(\d{1})/, '$1.$2.$3-$4');
    }
    formik.setFieldTouched('pispasep', true, true);
    return undefined;
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const handleNextStage = () => {
    setTabValue((parseInt(tabValue, 10) + 1).toString());
  };

  const setFieldUploadsDocs = () => {
    if (formik.values.admissionTypeId) {
      useAdmissionClient()
        .getByIdAdmissionType(formik.values.admissionTypeId)
        .then((response) => {
          setAdmissionUploadDoc(response.data);
        });
    } else {
      setAdmissionUploadDoc([]);
    }
  };

  const textValueHandlerPosition = useCallback((_, value) => {
    setPositionTextValue(value);
  }, []);

  const changePositionValueHandler = useCallback((ev, newVal) => {
    setPositionValue(newVal);
  }, []);

  const textValueHandlerAdmission = useCallback((_, value) => {
    setAdmissionTextValue(value);
  }, []);

  const changeAdmissionValueHandler = useCallback((ev, newVal) => {
    setAdmissionValue(newVal);
  }, []);

  const textValueHandlerSector = useCallback((_, value) => {
    setSectorTextValue(value);
  }, []);

  const changeSectorValueHandler = useCallback((ev, newVal) => {
    setSectorValue(newVal);
  }, []);

  const validateDateElectorIdIssuedDate = () => {
    if (
      formatDate(formik?.values?.electorIdIssuedDate) >
      moment(new Date(Date.now())).format('YYYY-MM-DD')
    ) {
      return true;
    }
    return false;
  };

  const validateDateBirthDate = () => {
    const yearValidate = moment().subtract(16, 'years').format('YYYY-MM-DD');
    if (formatDate(formik?.values?.birthDate) > yearValidate) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      // !validateDateNationalIdIssuedDate() &&
      !validateDateElectorIdIssuedDate() &&
      !validateDateBirthDate()
    ) {
      setValidateDate(true);
    } else {
      setValidateDate(false);
    }
  }, [
    setValidateDate,
    validateDateBirthDate,
    validateDateElectorIdIssuedDate,
    // validateDateNationalIdIssuedDate,
  ]);

  const validatorBirthDate = () => {
    if (!validateDateBirthDate()) {
      return true;
    }
    if (!formik.touched.birthDate || !formik.errors.birthDate) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid mt={2} p={3} container display="flex">
        <Grid item xs={9}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '28px',
              lineHeight: '35px',
              '@media (max-width: 1366px)': {
                fontSize: '18px',
              },
            }}
          >
            Dados do Colaborador
          </Typography>
        </Grid>
        <Grid sx={{ width: '100%' }} item xs={3}>
          <InputLabel
            error={formik.touched.status && !!formik.errors.status}
            sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '22px',
              lineHeight: '35px',
              marginBottom: '8px',
              '@media (max-width: 1366px)': {
                fontSize: '14px',
              },
              color: '#000',
            }}
          >
            Status
          </InputLabel>
          <FormControl
            fullWidth
            error={formik.touched.status && !!formik.errors.status}
          >
            <Select
              name="status"
              {...formik.getFieldProps('status')}
              onChange={handleChangeStatus}
              error={formik.touched.status && !!formik.errors.status}
            >
              {STATUS_TYPE.map((result) => (
                <MenuItem key={result} value={result}>
                  {result}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              hidden={!formik.touched.status || !formik.errors.status}
              error={formik.touched.status && !!formik.errors.status}
            >
              {formik.errors.status}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.boxData}>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '22px',
            lineHeight: '35px',
            marginBottom: '8px',
            '@media (max-width: 1366px)': {
              fontSize: '14px',
            },
          }}
        >
          Dados
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={4} xs={12}>
            <Autocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option !== value}
              id="jobTitle"
              options={jobType}
              fullWidth
              noOptionsText="Sem opções"
              value={positionValue}
              inputValue={positionTextValue}
              onInputChange={textValueHandlerPosition}
              onChange={(_, newName) => {
                formik.setFieldValue('jobTitleId', newName?.value);
                changePositionValueHandler(_, newName);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={
                    positionValue?.label?.length === 0 || positionValue === null
                      ? 'Cargo'
                      : undefined
                  }
                  {...params}
                  {...formik.getFieldProps('jobTitleId')}
                  error={
                    formik.touched.jobTitleId && !!formik.errors.jobTitleId
                  }
                  label="Cargo"
                />
              )}
            />
            <FormHelperText
              hidden={!formik.touched.jobTitleId || !formik.errors.jobTitleId}
              error={formik.touched.jobTitleId && !!formik.errors.jobTitleId}
            >
              {formik.errors.jobTitleId}
            </FormHelperText>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Autocomplete
              disablePortal
              id="admissionType"
              options={admissionType}
              fullWidth
              noOptionsText="Sem opções"
              value={admissionValue}
              inputValue={admissionTextValue}
              onInputChange={textValueHandlerAdmission}
              isOptionEqualToValue={(option, value) => option !== value}
              onChange={(_, newName) => {
                formik.setFieldValue('admissionTypeId', newName?.value);
                changeAdmissionValueHandler(_, newName);
              }}
              onBlur={setFieldUploadsDocs}
              renderInput={(params) => (
                <TextField
                  placeholder={
                    admissionValue?.label?.length === 0 ||
                    admissionValue === null
                      ? 'Tipo Admissão'
                      : undefined
                  }
                  {...params}
                  {...formik.getFieldProps('admissionTypeId')}
                  error={
                    formik.touched.admissionTypeId &&
                    !!formik.errors.admissionTypeId
                  }
                  label="Tipo Admissão"
                />
              )}
            />
            <FormHelperText
              hidden={
                !formik.touched.admissionTypeId ||
                !formik.errors.admissionTypeId
              }
              error={
                formik.touched.admissionTypeId &&
                !!formik.errors.admissionTypeId
              }
            >
              {formik.errors.admissionTypeId}
            </FormHelperText>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Autocomplete
              disablePortal
              id="admissionType"
              options={sectorType}
              fullWidth
              noOptionsText="Sem opções"
              value={sectorValue}
              inputValue={sectorTextValue}
              onInputChange={textValueHandlerSector}
              isOptionEqualToValue={(option, value) => option !== value}
              onChange={(_, newName) => {
                formik.setFieldValue('sectorId', newName?.value);
                changeSectorValueHandler(_, newName);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={
                    sectorValue?.label?.length === 0 || sectorValue === null
                      ? 'Setor'
                      : undefined
                  }
                  {...params}
                  {...formik.getFieldProps('sectorId')}
                  error={formik.touched.sectorId && !!formik.errors.sectorId}
                  label="Setor"
                />
              )}
            />
            <FormHelperText
              hidden={!formik.touched.sectorId || !formik.errors.sectorId}
              error={formik.touched.sectorId && !!formik.errors.sectorId}
            >
              {formik.errors.sectorId}
            </FormHelperText>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.name && !!formik.errors.name}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Nome Completo"
                placeholder="Nome Completo"
                name="name"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && !!formik.errors.name}
              />
              <FormHelperText
                hidden={!formik.touched.name || !formik.errors.name}
                error={formik.touched.name && !!formik.errors.name}
              >
                {formik.errors.name}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.cpf && !!formik.errors.cpf}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                placeholder="CPF"
                label="CPF"
                name="cpf"
                {...formik.getFieldProps('cpf')}
                onBlur={() => {
                  formik.setFieldValue('cpf', formatCpf(formik.values.cpf));
                }}
                error={formik.touched.cpf && !!formik.errors.cpf}
              />
              <FormHelperText
                hidden={!formik.touched.cpf || !formik.errors.cpf}
                error={formik.touched.cpf && !!formik.errors.cpf}
              >
                {formik.errors.cpf}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.registryNumber && !!formik.errors.registryNumber
              }
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                placeholder="Número de Matrícula"
                label="Número de Matrícula"
                name="cpf"
                {...formik.getFieldProps('registryNumber')}
                error={
                  formik.touched.registryNumber &&
                  !!formik.errors.registryNumber
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.registryNumber ||
                  !formik.errors.registryNumber
                }
                error={
                  formik.touched.registryNumber &&
                  !!formik.errors.registryNumber
                }
              >
                {formik.errors.registryNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
                marginBottom: '8px',
              }}
            >
              Registro Geral
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={5}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.nationalIdNumber &&
                    !!formik.errors.nationalIdNumber
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Número"
                    placeholder="Número"
                    name="nationalIdNumber"
                    {...formik.getFieldProps('nationalIdNumber')}
                    error={
                      formik.touched.nationalIdNumber &&
                      !!formik.errors.nationalIdNumber
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.nationalIdNumber ||
                      !formik.errors.nationalIdNumber
                    }
                    error={
                      formik.touched.nationalIdNumber &&
                      !!formik.errors.nationalIdNumber
                    }
                  >
                    {formik.errors.nationalIdNumber}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.nationalIdIssuer &&
                    !!formik.errors.nationalIdIssuer
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Órgão emissor"
                    placeholder="Órgão emissor"
                    name="nationalIdIssuer"
                    {...formik.getFieldProps('nationalIdIssuer')}
                    error={
                      formik.touched.nationalIdIssuer &&
                      !!formik.errors.nationalIdIssuer
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.nationalIdIssuer ||
                      !formik.errors.nationalIdIssuer
                    }
                    error={
                      formik.touched.nationalIdIssuer &&
                      !!formik.errors.nationalIdIssuer
                    }
                  >
                    {formik.errors.nationalIdIssuer}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.nationalIdIssuedDate &&
                    !!formik.errors.nationalIdIssuedDate
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Data de emissão"
                      value={formik?.values?.nationalIdIssuedDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('nationalIdIssuedDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...formik.getFieldProps('nationalIdIssuedDate')}
                          error={
                            formik.touched.nationalIdIssuedDate &&
                            Boolean(formik.errors.nationalIdIssuedDate)
                          }
                        />
                      )}
                      mask="__/__/____"
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                  <FormHelperText
                    hidden={
                      !formik.touched.nationalIdIssuedDate ||
                      !formik.errors.nationalIdIssuedDate
                    }
                    error={
                      formik.touched.nationalIdIssuedDate &&
                      !!formik.errors.nationalIdIssuedDate
                    }
                  >
                    {formik.errors.nationalIdIssuedDate}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
                marginBottom: '8px',
              }}
            >
              Título de Eleitor
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdNumber &&
                    !!formik.errors.electorIdNumber
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Número"
                    label="Número"
                    name="electorIdNumber"
                    {...formik.getFieldProps('electorIdNumber')}
                    error={
                      formik.touched.electorIdNumber &&
                      !!formik.errors.electorIdNumber
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdNumber ||
                      !formik.errors.electorIdNumber
                    }
                    error={
                      formik.touched.electorIdNumber &&
                      !!formik.errors.electorIdNumber
                    }
                  >
                    {formik.errors.electorIdNumber}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdSeries &&
                    !!formik.errors.electorIdSeries
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Série"
                    placeholder="Série"
                    name="electorIdSeries"
                    {...formik.getFieldProps('electorIdSeries')}
                    inputProps={{ maxLength: 2 }}
                    error={
                      formik.touched.electorIdSeries &&
                      !!formik.errors.electorIdSeries
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdSeries ||
                      !formik.errors.electorIdSeries
                    }
                    error={
                      formik.touched.electorIdSeries &&
                      !!formik.errors.electorIdSeries
                    }
                  >
                    {formik.errors.electorIdSeries}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdState &&
                    !!formik.errors.electorIdState
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="UF"
                    placeholder="UF"
                    name="electorIdState"
                    {...formik.getFieldProps('electorIdState')}
                    error={
                      formik.touched.electorIdState &&
                      !!formik.errors.electorIdState
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdState ||
                      !formik.errors.electorIdState
                    }
                    error={
                      formik.touched.electorIdState &&
                      !!formik.errors.electorIdState
                    }
                  >
                    {formik.errors.nationalIdIssuer}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.electorIdIssuedDate &&
                    !!formik.errors.electorIdIssuedDate
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Data de expedição"
                      value={formik?.values?.electorIdIssuedDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('electorIdIssuedDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...formik.getFieldProps('electorIdIssuedDate')}
                          error={
                            formik.touched.electorIdIssuedDate &&
                            Boolean(formik.errors.electorIdIssuedDate)
                          }
                        />
                      )}
                      mask="__/__/____"
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                  <FormHelperText
                    hidden={
                      !formik.touched.electorIdIssuedDate ||
                      !formik.errors.electorIdIssuedDate
                    }
                    error={
                      formik.touched.electorIdIssuedDate &&
                      !!formik.errors.electorIdIssuedDate
                    }
                  >
                    {formik.errors.electorIdIssuedDate}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
                marginBottom: '8px',
              }}
            >
              Carteira de Trabalho
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={5}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.workerIdNumber &&
                    !!formik.errors.workerIdNumber
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Número"
                    label="Número"
                    name="workerIdNumber"
                    {...formik.getFieldProps('workerIdNumber')}
                    error={
                      formik.touched.workerIdNumber &&
                      !!formik.errors.workerIdNumber
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.workerIdNumber ||
                      !formik.errors.workerIdNumber
                    }
                    error={
                      formik.touched.workerIdNumber &&
                      !!formik.errors.workerIdNumber
                    }
                  >
                    {formik.errors.workerIdNumber}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.workerIdSeries &&
                    !!formik.errors.workerIdSeries
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Série"
                    placeholder="Série"
                    name="workerIdSeries"
                    {...formik.getFieldProps('workerIdSeries')}
                    inputProps={{ maxLength: 4 }}
                    error={
                      formik.touched.workerIdSeries &&
                      !!formik.errors.workerIdSeries
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.workerIdSeries ||
                      !formik.errors.workerIdSeries
                    }
                    error={
                      formik.touched.workerIdSeries &&
                      !!formik.errors.workerIdSeries
                    }
                  >
                    {formik.errors.workerIdSeries}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl
                  fullWidth
                  error={formik.touched.pispasep && !!formik.errors.pispasep}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Pis/Pasep"
                    placeholder="Pis/Pasep"
                    name="pispasep"
                    {...formik.getFieldProps('pispasep')}
                    onBlur={() => {
                      formik.setFieldValue(
                        'pispasep',
                        formatPisPasep(formik.values.pispasep)
                      );
                    }}
                    error={formik.touched.pispasep && !!formik.errors.pispasep}
                  />
                  <FormHelperText
                    hidden={!formik.touched.pispasep || !formik.errors.pispasep}
                    error={formik.touched.pispasep && !!formik.errors.pispasep}
                  >
                    {formik.errors.pispasep}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.veteranIdNumber &&
                !!formik.errors.veteranIdNumber
              }
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Carteira de Reservista"
                placeholder="Carteira de Reservista"
                name="veteranIdNumber"
                {...formik.getFieldProps('veteranIdNumber')}
                error={
                  formik.touched.veteranIdNumber &&
                  !!formik.errors.veteranIdNumber
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.veteranIdNumber ||
                  !formik.errors.veteranIdNumber
                }
                error={
                  formik.touched.veteranIdNumber &&
                  !!formik.errors.veteranIdNumber
                }
              >
                {formik.errors.veteranIdNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.gender && !!formik.errors.gender}
            >
              <InputLabel id="select-label">Gênero</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                name="gender"
                label="Gênero"
                displayEmpty
                renderValue={
                  formik.values.gender === ''
                    ? () => <div className={classes.placeholder}>Gênero</div>
                    : undefined
                }
                {...formik.getFieldProps('gender')}
                onChange={handleChangeGender}
                error={formik.touched.gender && !!formik.errors.gender}
              >
                {GENDER_TYPE.map((result) => (
                  <MenuItem key={result.gender} value={result?.gender}>
                    {result?.gender}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={!formik.touched.gender || !formik.errors.gender}
                error={formik.touched.gender && !!formik.errors.gender}
              >
                {formik.errors.gender}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.hasDisability && !!formik.errors.hasDisability
              }
            >
              <InputLabel id="select-label">Deficiência</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Deficiência"
                placeholder="Ex: Sim ou Não"
                name="hasDisability"
                displayEmpty
                renderValue={
                  formik.values.hasDisability === ''
                    ? () => (
                        <div className={classes.placeholder}>Deficiência</div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('hasDisability')}
                onChange={handleChangeDisability}
                error={
                  formik.touched.hasDisability && !!formik.errors.hasDisability
                }
              >
                {HAS_DISABILITY.map((result) => (
                  <MenuItem key={result?.label} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.hasDisability || !formik.errors.hasDisability
                }
                error={
                  formik.touched.hasDisability && !!formik.errors.hasDisability
                }
              >
                {formik.errors.hasDisability}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={4}>
            <FormControl
              fullWidth
              error={formik.touched.birthDate && !!formik.errors.birthDate}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data de nascimento"
                  value={formik?.values?.birthDate}
                  onChange={(newValue) => {
                    formik.setFieldValue('birthDate', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...formik.getFieldProps('birthDate')}
                      error={
                        formik.touched.birthDate &&
                        Boolean(formik.errors.birthDate)
                      }
                    />
                  )}
                  mask="__/__/____"
                  inputFormat="DD/MM/YYYY"
                />
              </LocalizationProvider>
              <FormHelperText
                hidden={validatorBirthDate()}
                error={formik.touched.birthDate && !!formik.errors.birthDate}
              >
                {validateDateBirthDate() ? (
                  <Typography color="error">
                    Data de nascimento inferior a 16 anos
                  </Typography>
                ) : (
                  <Typography color="error">
                    {formik?.errors?.birthDate}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.birthPlace && !!formik.errors.birthPlace}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Naturalidade"
                placeholder="Naturalidade"
                name="birthPlace"
                {...formik.getFieldProps('birthPlace')}
                error={formik.touched.birthPlace && !!formik.errors.birthPlace}
              />
              <FormHelperText
                hidden={!formik.touched.birthPlace || !formik.errors.birthPlace}
                error={formik.touched.birthPlace && !!formik.errors.birthPlace}
              >
                {formik.errors.birthPlace}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.nationality && !!formik.errors.nationality}
            >
              <TextField
                height="40px"
                width="350px"
                type="text"
                label="Nacionalidade"
                placeholder="Nacionalidade"
                name="nationality"
                {...formik.getFieldProps('nationality')}
                error={
                  formik.touched.nationality && !!formik.errors.nationality
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.nationality || !formik.errors.nationality
                }
                error={
                  formik.touched.nationality && !!formik.errors.nationality
                }
              >
                {formik.errors.nationality}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.race && !!formik.errors.race}
            >
              <InputLabel id="select-label">Raça/cor</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Raça/cor"
                placeholder="Raça/cor"
                name="race"
                displayEmpty
                renderValue={
                  formik.values.race === ''
                    ? () => <div className={classes.placeholder}>Raça/cor</div>
                    : undefined
                }
                {...formik.getFieldProps('race')}
                onChange={handleChangeRace}
                error={formik.touched.race && !!formik.errors.race}
              >
                {RACE_TYPE.map((result) => (
                  <MenuItem key={result.race} value={result?.race}>
                    {result?.race}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={!formik.touched.race || !formik.errors.race}
                error={formik.touched.race && !!formik.errors.race}
              >
                {formik.errors.race}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.educationLevel && !!formik.errors.educationLevel
              }
            >
              <InputLabel id="select-label">Grau de instrução</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Grau de instrução"
                placeholder="Grau de instrução"
                name="educationLevel"
                displayEmpty
                renderValue={
                  formik.values.educationLevel === ''
                    ? () => (
                        <div className={classes.placeholder}>
                          Grau de instrução
                        </div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('educationLevel')}
                onChange={handleChangeEducationLevel}
                error={
                  formik.touched.educationLevel &&
                  !!formik.errors.educationLevel
                }
              >
                {EDUCATION_LEVEL_TYPE.map((result) => (
                  <MenuItem key={result.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.educationLevel ||
                  !formik.errors.educationLevel
                }
                error={
                  formik.touched.educationLevel &&
                  !!formik.errors.educationLevel
                }
              >
                {formik.errors.educationLevel}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl
              fullWidth
              error={
                formik.touched.maritialStatus && !!formik.errors.maritialStatus
              }
            >
              <InputLabel id="select-label">Estado Civil</InputLabel>
              <Select
                height="40px"
                width="350px"
                type="text"
                label="Estado Civil"
                placeholder="Estado Civil"
                name="maritialStatus"
                displayEmpty
                renderValue={
                  formik.values.maritialStatus === ''
                    ? () => (
                        <div className={classes.placeholder}>Estado Civil</div>
                      )
                    : undefined
                }
                {...formik.getFieldProps('maritialStatus')}
                onChange={handleChangeMaritialStatus}
                error={
                  formik.touched.maritialStatus &&
                  !!formik.errors.maritialStatus
                }
              >
                {MARITIAL_STATUS_TYPE.map((result) => (
                  <MenuItem key={result.value} value={result?.value}>
                    {result?.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={
                  !formik.touched.maritialStatus ||
                  !formik.errors.maritialStatus
                }
                error={
                  formik.touched.maritialStatus &&
                  !!formik.errors.maritialStatus
                }
              >
                {formik.errors.maritialStatus}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                marginBottom: '8px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
              }}
            >
              Filiação
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.motherName && !!formik.errors.motherName
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Nome da Mãe"
                    placeholder="Nome da Mãe"
                    name="motherName"
                    {...formik.getFieldProps('motherName')}
                    error={
                      formik.touched.motherName && !!formik.errors.motherName
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.motherName || !formik.errors.motherName
                    }
                    error={
                      formik.touched.motherName && !!formik.errors.motherName
                    }
                  >
                    {formik.errors.motherName}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.fatherName && !!formik.errors.fatherName
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Nome do Pai"
                    placeholder="Nome do Pai"
                    name="fatherName"
                    {...formik.getFieldProps('fatherName')}
                    error={
                      formik.touched.fatherName && !!formik.errors.fatherName
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.fatherName || !formik.errors.fatherName
                    }
                    error={
                      formik.touched.fatherName && !!formik.errors.fatherName
                    }
                  >
                    {formik.errors.fatherName}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                marginBottom: '8px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
              }}
            >
              Endereço
            </Typography>
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <FormControl
                  fullWidth
                  error={formik.touched.address && !!formik.errors.address}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Logradouro"
                    placeholder="Logradouro"
                    name="address"
                    {...formik.getFieldProps('address')}
                    error={formik.touched.address && !!formik.errors.address}
                  />
                  <FormHelperText
                    hidden={!formik.touched.address || !formik.errors.address}
                    error={formik.touched.address && !!formik.errors.address}
                  >
                    {formik.errors.address}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  error={formik.touched.district && !!formik.errors.district}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Bairro"
                    placeholder="Bairro"
                    name="district"
                    {...formik.getFieldProps('district')}
                    error={formik.touched.district && !!formik.errors.district}
                  />
                  <FormHelperText
                    hidden={!formik.touched.district || !formik.errors.district}
                    error={formik.touched.district && !!formik.errors.district}
                  >
                    {formik.errors.district}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  error={formik.touched.city && !!formik.errors.city}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Cidade"
                    placeholder="Cidade"
                    name="city"
                    {...formik.getFieldProps('city')}
                    error={formik.touched.city && !!formik.errors.city}
                  />
                  <FormHelperText
                    hidden={!formik.touched.city || !formik.errors.city}
                    error={formik.touched.city && !!formik.errors.city}
                  >
                    {formik.errors.city}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <FormControl
                  fullWidth
                  error={formik.touched.state && !!formik.errors.state}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Estado"
                    placeholder="Estado"
                    name="state"
                    {...formik.getFieldProps('state')}
                    error={formik.touched.state && !!formik.errors.state}
                  />
                  <FormHelperText
                    hidden={!formik.touched.state || !formik.errors.state}
                    error={formik.touched.state && !!formik.errors.state}
                  >
                    {formik.errors.state}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '35px',
                marginBottom: '8px',
                '@media (max-width: 1366px)': {
                  fontSize: '14px',
                },
              }}
            >
              Possui dependentes?
            </Typography>
            <FormControl
              fullWidth
              error={
                formik.touched.hasDependents && !!formik.errors.hasDependents
              }
            >
              <RadioGroup
                name="controlled-radio"
                defaultValue={formik?.values?.hasDependents.toString()}
                onChange={handleChangeDependents}
                error={
                  formik.touched.hasDependents && !!formik.errors.hasDependents
                }
              >
                <Box display="flex">
                  <FormControlLabel
                    value={HAS_DEPENDENT.YES}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={HAS_DEPENDENT.NO}
                    control={<Radio />}
                    label="Não"
                  />
                </Box>
              </RadioGroup>
              <FormHelperText
                hidden={
                  !formik.touched.hasDependents || !formik.errors.hasDependents
                }
                error={
                  formik.touched.hasDependents && !!formik.errors.hasDependents
                }
              >
                {formik.errors.hasDependents}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormGroup>
              <FormControlLabel
                sx={{ marginBottom: 3 }}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(event) => setCheckedFirst(event.target.checked)}
                    checked={checkedFirst}
                  />
                }
                label={Enviroment.LAW_TEXT_FIRST}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={(event) => setCheckedSecond(event.target.checked)}
                    checked={checkedSecond}
                  />
                }
                label={Enviroment.LAW_TEXT_SECOND}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12}>
            <Box my={2} display="flex" justifyContent="center">
              <Box onClick={handleNext}>
                <Button
                  className={classes.buttonModal}
                  variant="contained"
                  color="primary"
                  sx={{ fontSize: '15px' }}
                  disabled={!(checkedFirst && checkedSecond)}
                >
                  {employId
                    ? 'Atualizar Informações do Colaborador'
                    : 'Cadastrar Colaborador'}
                </Button>
              </Box>
            </Box>
          </Grid>
          {employId && (
            <Grid mt={2} item sm={12}>
              <EmployeeDocument
                employeeData={EMPLOYEE_DATA}
                categoryType="DocumentosPessoais"
                tabId="1"
              />
            </Grid>
          )}
        </Grid>
        {nextButtons && (
          <Box my={2} display="flex" justifyContent="center">
            <Box onClick={handleNextStage}>
              <Button
                className={classes.buttonModal}
                variant="contained"
                color="primary"
              >
                Próximo
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
