import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as Styled from './styled';
import Button from '../Button';

import admissionTypeClient from '../../clients/admissionTypeClient';
import { AppContext } from '../../contexts/sidebarContext';
import documentTypeClient from '../../clients/documentTypeClient';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  textFieldAutocomplete: {
    maxHeight: '132px',
    overflowY: 'auto',
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '100%',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
  selectField: {
    height: '72px !important',
    borderRadius: '10px !important',
    '& fieldset': {
      border: '1px solid #000',
    },
  },
  placeholder: {
    color: 'rgb(118, 118, 118)',
  },
}));

export default function NewAdmissionType({ updateValues }) {
  const classes = useStyles();
  const useAdmissionType = admissionTypeClient();
  const userDocument = documentTypeClient();

  const context = useContext(AppContext);
  const { setModalStatus, msgAlert, setModalClose, filteredList } = context;

  const [documentsName, setDocumentsName] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [name, setName] = useState([]);
  const [disabeButton, setDisabeButton] = useState(false);

  useEffect(() => {
    userDocument()
      .getDocumentType()
      .then((response) => {
        if (response) {
          setDocuments(response);
          setDocumentsName(response.map((resp) => resp.name));
        } else {
          setDocumentsName([]);
        }
      });
  }, []);

  const documentTypeIdsSelect = (documentNameSelect) =>
    documentNameSelect
      .map((docSel) => documents.find((doc) => doc.name === docSel))
      .map((doc) => doc.id);

  const DEFAULT_VALUES = {
    admissionType: updateValues.name ? updateValues.name : '',
    admissionDescription: updateValues.description
      ? updateValues.description
      : '',
    document: updateValues.documentTypes ? updateValues.documentTypes : '',
  };

  const handleStatus = () => {
    setModalStatus(false);
    setModalClose(true);
  };

  const compareAdmissionType = (list, newAdm) => {
    const matchingObject = list.find(
      (element) =>
        element.description === newAdm.description &&
        element.name === newAdm.name
    );
    if (matchingObject) {
      msgAlert({
        message: 'Encontramos um item com a mesma descrição e tipo!',
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...DEFAULT_VALUES },
    validationSchema: Yup.object({
      admissionType: Yup.string().required('Por favor, preencha este campo.'),
      admissionDescription: Yup.string(),
      document: Yup.array()
        .of(Yup.string())
        .min(1, 'Seleciona uma opção')
        .required('Por favor, preencha este campo.'),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        name: data.admissionType,
        description: data.admissionDescription,
        tenantId: useTenantId,
        documentTypeIds: documentTypeIdsSelect(name),
      };

      if (updateValues.id) {
        setModalClose(false);
        useAdmissionType()
          .editByIdAdmissionType(updateValues.id, payload)
          .then(
            (response) => {
              msgAlert({
                response,
                message: 'Registro atualizado com sucesso!',
              });
              handleStatus();
            },
            (resp) => {
              msgAlert({
                response: resp,
              });
              handleStatus();
            }
          );
      } else {
        setDisabeButton(true);
        setModalClose(false);
        compareAdmissionType(filteredList, payload);
        useAdmissionType()
          .newAdmissionType(payload)
          .then(
            (response) => {
              msgAlert({
                response,
                message: 'Novo Tipo de Admissão criada com sucesso!',
              });
              handleStatus();
            },
            (resp) => {
              msgAlert({
                response: resp,
              });
              handleStatus();
            }
          );
      }
    },
  });

  useEffect(() => {
    const docNamesType = updateValues.documentTypes?.map((resp) => resp.name);

    if (docNamesType) {
      formik.setFieldValue('document', docNamesType);
      setName(docNamesType);
    } else {
      formik.setFieldValue('document', '');
      setName([]);
    }
  }, [updateValues]);

  const handleSubmit = () => {
    formik.submitForm();
  };

  const resetForm = () => {
    formik.resetForm();
    setName([]);
  };

  const buttonSelect = () =>
    !updateValues.id ? (
      <Button
        disabled={disabeButton}
        text="Cadastrar"
        type="send"
        onClick={handleSubmit}
        width="100%"
        height="72px"
      />
    ) : (
      <Button
        text="Salvar"
        type="send"
        onClick={handleSubmit}
        width="100%"
        height="72px"
      />
    );

  return (
    <Styled.Container>
      <Styled.BoxTitle>
        <Styled.TitleModal>Tipos de Admissão</Styled.TitleModal>
      </Styled.BoxTitle>

      <Styled.InputAreaWrapper>
        <Styled.FirstRow>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.admissionType && formik.errors.admissionType
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: Concurso público"
                    name="admissionType"
                    label="Tipo de Admissão"
                    className={classes.textField}
                    {...formik.getFieldProps('admissionType')}
                    error={
                      formik.touched.admissionType &&
                      formik.errors.admissionType
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.admissionType ||
                      !formik.errors.admissionType
                    }
                    error={
                      formik.touched.admissionType &&
                      !!formik.errors.admissionType
                    }
                  >
                    {formik.errors.admissionType}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.admissionDescription &&
                    formik.errors.admissionDescription
                  }
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    placeholder="Ex: Concurso público"
                    name="admissionDescription"
                    label="Descrição"
                    className={classes.textField}
                    {...formik.getFieldProps('admissionDescription')}
                    error={
                      formik.touched.admissionDescription &&
                      formik.errors.admissionDescription
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.admissionDescription ||
                      !formik.errors.admissionDescription
                    }
                    error={
                      formik.touched.admissionDescription &&
                      !!formik.errors.admissionDescription
                    }
                  >
                    {formik.errors.admissionDescription}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={4}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.document && formik.errors.document}
                >
                  <Autocomplete
                    value={name || []}
                    onChange={(_, newName) => {
                      setName(newName);
                      formik.values.document = newName;
                    }}
                    disablePortal
                    multiple
                    limitTags={6}
                    options={documentsName}
                    name="document"
                    noOptionsText="Sem opções"
                    className={classes.selectField}
                    renderInput={(params) => (
                      <TextField
                        placeholder={
                          name.length === 0 ? 'Nome dos Documentos' : undefined
                        }
                        className={classes.textFieldAutocomplete}
                        {...params}
                        error={
                          formik.touched.document && formik.errors.document
                        }
                      />
                    )}
                  />
                  <FormHelperText
                    hidden={!formik.touched.document || !formik.errors.document}
                    error={formik.touched.document && !!formik.errors.document}
                  >
                    {formik.errors.document}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Styled.FirstRow>
      </Styled.InputAreaWrapper>
      <Styled.ButtonArea>
        <Grid container mb={3} spacing={3}>
          <Grid
            container
            item
            spacing={2}
            flex={1}
            display="flex"
            justifyContent="center"
          >
            <Grid item xs={6} sm={4} md={4} lg={6}>
              {!updateValues.id ? (
                <Button
                  text="Limpar campos"
                  type="send"
                  onClick={resetForm}
                  width="100%"
                  height="72px"
                  whiteButton
                />
              ) : (
                <Button
                  text="Cancelar"
                  type="send"
                  onClick={handleStatus}
                  width="100%"
                  height="72px"
                  whiteButton
                />
              )}
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={6}>
              {buttonSelect()}
            </Grid>
          </Grid>
        </Grid>
      </Styled.ButtonArea>
    </Styled.Container>
  );
}

NewAdmissionType.propTypes = {
  updateValues: PropTypes.oneOfType([PropTypes.object]),
};

NewAdmissionType.defaultProps = {
  updateValues: {},
};
