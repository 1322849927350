import { useCallback } from 'react'
import { api } from '../services/api'

export default function EmployeeRegisterClient() {
    const getEmployee = useCallback(() => api.get('/Employee').then((response) => response.data), [])

    const newEmployee = useCallback((data) => api.post('/Employee', data).then((response) => response), [])

    const deleteDocumentType = useCallback((id) => api.delete('/Employee', { params: { id } }).then((response) => response), [])

    const getByIdEmployee = useCallback((id) => api.get(`/Employee/${id}`).then((response) => response.data), [])

    const editByIdEmployee = useCallback((id, data) => api.put(`/Employee/${id}`, data).then((response) => response), [])

    return useCallback(() => ({
        getEmployee,
        newEmployee,
        deleteDocumentType,
        getByIdEmployee,
        editByIdEmployee
    }), [
        getEmployee,
        newEmployee,
        deleteDocumentType,
        getByIdEmployee,
        editByIdEmployee
    ]
    )
}