import styled from 'styled-components';
import Box from '@mui/material/Box';

export const Container = styled.div`
  padding: '20px 8px',
  height: '100vh',
  backgroundColor: '#ffffff',
  width: '357px',
  display: 'flex',
  flexDirection: 'column'
  z-index: 1;

  @media (max-width: 425px) {
    max-width: ${(props) => (props.isOpen ? '320px' : '10px')};
  }

  @keyframes showSidebar {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ButtonSidebar = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #aeaeae;
  position: absolute;
  right: -15px;
  top: 10px;
  cursor: pointer;
`;

export const SidebarToggleIcon = styled.img`
  transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};
`;

export const Content = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  span {
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
  }
`;

export const DropDownContainer = styled.div``;

export const DropDownHeader = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: ${(props) => (props.isOpen ? '15px 35px 15px 0' : '0 35px 0 0')};
  background: ${(props) =>
    props.isOpen ? 'rgba(251, 251, 251, 0.8)' : 'transparent'};
  border-bottom: 1px solid
    ${(props) => (props.isOpen ? '#00A4E1' : 'transparent')};
  gap: 10px;
`;

export const IconDropdown = styled.img``;

export const ImgIcon = styled.img`
  &:hover {
    background: #438eb8;
    color: #fff;
  }
`;

export const Logo = styled.img`
  @media (max-width: 1366px) {
    margin-top: 45px;
    margin-bottom: 20px;
    width: 140px;
    margin-left: 25px;
  }
  width: 195px;
  margin-left: 36px;
  margin-bottom: 50px;
  margin-top: 69px;
`;

export const DropDownListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DropDownList = styled.ul`
  @media (max-width: 1366px) {
    padding: 0 15px;
    margin-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &:hover {
    background: #438eb8;
    color: #fff;
    font-weight: 700;
    cursor: pointer;

    img {
      filter: brightness(0) invert(1);
    }
  }

  left: 0;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
  display: 'flex';
  flex-direction: column;
  background: ${(props) => (props.openCollapse ? '#0c7299' : 'none')};
  margin-top: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 8px 0;
  color: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  &:hover {
    background: #438eb8;
    color: #fff;
    font-weight: 700;

    img {
      filter: brightness(0) invert(1);
    }
  }

  @media (max-width: 1366px) {
    font-size: 15px;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    font-size: 12.5px;
    line-height: 22px;
    padding: 4px 0;
  }
`;

export const BoxSidebar = styled(Box)`
  @media (max-width: 1366px) {
    margin-top: 70px;
    margin-bottom: 20px;
    margin-left: 25px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 140px;
  margin-bottom: 20px;
  margin-left: 25px;
`;

export const DropDownListCollapse = styled.ul`
  position: absolute;
  bottom: 65px;
  left: 260px;
  width: 100%;
  padding: 0 16px;
  background: #438eb8;
  box-sizing: border-box;
  font-size: 20.8px;
  font-weight: 500;
  &:first-child {
    padding-top: 12px;
  }
`;

export const ListItemCollapse = styled.li`
  list-style: none;
  padding-bottom: 15px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
