import React, { useContext } from 'react';
import {
  Box,
  Grid,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTheme } from 'styled-components';
import Button from '../Button';
import userClient from '../../clients/userClient';
import { AppContext } from '../../contexts/sidebarContext';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
  },
  textField: {
    '& .MuiInputLabel-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      marginTop: '6px',
    },
    '& .MuiInputBase-root': {
      height: '72px',
      '& fieldset': {
        border: '1px solid #000',
        borderRadius: '10px',
        fontFamily: 'Readex Pro',
        input: {
          '&::placeholder': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#107FAA',
          },
        },
      },
    },
  },
}));

function EditModalUser({ openModal, setOpenModal, getUser, getId }) {
  const classes = useStyles();

  const getRoleUser = localStorage.getItem('role');

  const context = useContext(AppContext);
  const { msgAlert } = context;

  const handleClose = () => {
    setOpenModal(false);
  };

  const useClient = userClient();

  const DEFAULT = {
    fullName: getUser?.fullName,
    phone: getUser?.phone,
    administrator: getUser?.administrator,
    enabled: getUser?.enabled,
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Campo obrigatório'),
      phone: Yup.number().required('Campo obrigatório'),
      administrator: Yup.boolean(),
      enabled: Yup.boolean(),
    }),
    onSubmit: (data) => {
      const getTenantId = localStorage.getItem('tenantId');
      const useTenantId = getTenantId.startsWith(null)
        ? null
        : getTenantId.substring(1, getTenantId.length - 1);

      const payload = {
        fullName: data.fullName,
        phone: data.phone.toString(),
        administrator: data.administrator,
        enabled: data.enabled,
        tenantId: useTenantId,
      };

      useClient()
        .editUserById(getId, payload)
        .then(
          (response) => {
            msgAlert({
              response,
              message: 'Usuário editado com sucesso!',
            });
            handleClose();
          },
          (resp) => {
            msgAlert({
              response: resp,
            });
            handleClose();
          }
        );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const theme = useTheme();

  return (
    <Modal open={openModal} onClose={handleClose} closeAfterTransition>
      <Box className={classes.modal}>
        <Paper sx={{ padding: '30px' }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '30px',
                  paddingTop: '20px',
                  '@media (max-width: 1280px)': {
                    paddingTop: '10px',
                  },
                }}
              >
                Editar Usuário
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
          <Grid container direction="column" mt={4} spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.fullName && formik.errors.fullName}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="text"
                    label="Nome Completo"
                    placeholder="Ex: Maria Silva"
                    name="name"
                    className={classes.textField}
                    {...formik.getFieldProps('fullName')}
                    error={formik.touched.fullName && formik.errors.fullName}
                  />
                  <FormHelperText
                    hidden={!formik.touched.fullName || !formik.errors.fullName}
                    error={formik.touched.fullName && !!formik.errors.fullName}
                  >
                    {formik.errors.fullName}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  error={formik.touched.phone && formik.errors.phone}
                >
                  <TextField
                    height="40px"
                    width="350px"
                    type="number"
                    placeholder="Ex: 999998888"
                    name="phoneNumber"
                    label="Telefone"
                    className={classes.textField}
                    {...formik.getFieldProps('phone')}
                    error={formik.touched.phone && formik.errors.phone}
                    sx={{
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield',
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    }}
                  />
                  <FormHelperText
                    hidden={!formik.touched.phone || !formik.errors.phone}
                    error={formik.touched.phone && !!formik.errors.phone}
                  >
                    {formik.errors.phone}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    width: '100%',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '20px',
                    marginTop: '25px',
                    '@media (max-width: 1280px)': {
                      marginTop: '20px',
                    },
                  }}
                >
                  Selecione as opções
                </Typography>
              </Grid>

              <Grid container item direction="row" spacing={4}>
                <Grid item>
                  <FormControlLabel
                    sx={{
                      color: theme.text.darkGray,
                    }}
                    label="Administrador"
                    control={
                      <Checkbox
                        checked={formik.values.administrator}
                        onChange={(event) => {
                          // handleCheckedAdministrator(event);
                          formik.setFieldValue(
                            'administrator',
                            event.target.checked
                          );
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: theme.background.blue,
                          },
                        }}
                      />
                    }
                  />
                </Grid>
                {(getRoleUser === 'AdministradorSistema' ||
                  getRoleUser === 'AdmnistradorTenant') && (
                  <Grid item>
                    <FormControlLabel
                      sx={{
                        color: theme.text.darkGray,
                      }}
                      label="Ativo"
                      control={
                        <Checkbox
                          checked={formik.values.enabled}
                          onChange={(event) => {
                            formik.setFieldValue(
                              'enabled',
                              event.target.checked
                            );
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: theme.background.blue,
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={4} spacing={2}>
            <Grid
              container
              item
              spacing={2}
              flex={1}
              display="flex"
              justifyContent="end"
            >
              <Grid item xs={8} sm={4} md={3} lg={3}>
                <Button
                  text="Cancelar"
                  type="send"
                  onClick={handleClose}
                  width="100%"
                  height="72px"
                  whiteButton
                />
              </Grid>
              <Grid item xs={8} sm={4} md={3} lg={3}>
                <Button
                  text="Salvar"
                  type="send"
                  onClick={handleSubmit}
                  width="100%"
                  height="72px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

EditModalUser.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  getUser: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    administrator: PropTypes.bool,
    enabled: PropTypes.bool,
  }),
  getId: PropTypes.string,
};

EditModalUser.defaultProps = {
  openModal: false,
  getId: '',
  getUser: {},
};

export default EditModalUser;
